import { useRef, useState } from "react";
import { toast } from "react-toastify";

import "./commentPanel.scss";
import { RequestItem } from "dto/requestItem";
import FloatingSidePanel from "components/shared/floatingSidePanel";
import SIDE_PANEL_SIDE from "enums/sidePanelSide";
import { useAppDispatch, useAppSelector } from "hooks";
import { selectAssignmentSelected } from "slices/assignment/assignmentSelectedSlice";
import { createComment } from "components/api/commentData";
import { decodeIdentityToken, selectIdentity } from "slices/user/identitySlice";
import { Comment } from "dto/comment";
import CommentComponent from "./comment/comment";
import Badge from "components/shared/badge/badge";
import { RequestItemsDetailsState } from "slices/assignment/requestItemsDetailsSlice";
import { MatomoActions, MatomoEventCategories, trackEvent } from "matomo";

export default function CommentPanel({
  commentsDetail = [],
  requestItem = {} as RequestItem,
  closePanel,
}: {
  commentsDetail?: Comment[];
  requestItem?: RequestItem;
  closePanel: () => void;
}) {
  const identity = useAppSelector(selectIdentity);
  const dispatch = useAppDispatch();
  const assignmentData = useAppSelector(selectAssignmentSelected);
  const { email } = decodeIdentityToken(identity);

  const [comment, setComment] = useState("");
  const [isAddCommentButtonDisabled, setIsAddCommentButtonDisabled] =
    useState(false);
  const { requestItemTypeDescription, commentIds, id } = requestItem;
  const { propertyName, jobAssignmentPropertyId } = assignmentData;

  const sortedComments = commentIds
    .map(
      (commentId) => commentsDetail.find((comment) => comment.id === commentId)!
    )
    .sort(
      (c1, c2) =>
        // To display newer dates on top
        new Date(c2.createdOn).getTime() - new Date(c1.createdOn).getTime()
    );
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  return (
    <FloatingSidePanel closePanel={closePanel} side={SIDE_PANEL_SIDE.RIGHT}>
      <div>
        <span>
          <span className="nmrk-sidepanel-small-black">{"RESPOND > "}</span>
          <span className="nmrk-sidepanel-small-grey">{"COMMENT"}</span>
        </span>
        <button className="nmrk-sidepanel-close" onClick={closePanel}>
          X
        </button>

        <h3 className="nmrk-sidepanel-header">Add Comments</h3>
        <div className="nmrk-sidepanel-subtext nmrk-sidepanel-bottomspace">
          {propertyName}
        </div>
        <Badge color="black">{requestItemTypeDescription}</Badge>
        <hr className="nmrk-sidepanel-horizontal-line-title" />
        <h4 className="nmrk-sidepanel-header">
          Please share any details about the documents you uploaded that will
          help us
        </h4>
        <textarea
          ref={textareaRef}
          rows={1}
          className="comment-textarea"
          value={comment}
          onChange={(e) => {
            textareaRef.current!.style.height = "auto";
            textareaRef.current!.style.height = `${
              textareaRef.current!.scrollHeight
            }px`;

            setComment(e.target.value);
          }}
        />
        <button
          className="nmrk-comment-button"
          disabled={comment.length === 0 || isAddCommentButtonDisabled}
          onClick={async () => {
            setIsAddCommentButtonDisabled(true);
            let commentId;
            try {
              commentId = await createComment(identity, {
                jobAssignmentPropertyId,
                id,
                content: comment,
              });
            } catch (e) {
              toast.error("Failed to create comment");
              setIsAddCommentButtonDisabled(false);
              return;
            }

            dispatch({
              type: "requestItemsDetails/addNewComment",
              payload: {
                [jobAssignmentPropertyId]: {
                  commentsDetail: [
                    {
                      id: commentId,
                      content: comment,
                      createdOn: new Date().toUTCString(),
                      createdBy: email,
                    },
                  ],
                  requestedItems: [{ id, commentIds: [commentId] }],
                },
              } as RequestItemsDetailsState,
            });

            trackEvent(
              MatomoEventCategories.RequestItem,
              MatomoActions.AddComment,
              `${requestItem.id}`
            );
            toast.success("Comment created");
            setIsAddCommentButtonDisabled(false);
            setComment("");
            closePanel();
          }}
        >
          Add Comment
        </button>
        <hr className="comment-horizontal-line-previous-comments" />
        <h4 className="nmrk-comment-header nmrk-sidepanel-header">
          {sortedComments.length > 1 &&
            `${sortedComments.length} comments added`}
          {sortedComments.length === 1 && "1 comment added"}
        </h4>
        {sortedComments.map((comment, i) => {
          return <CommentComponent key={i} comment={comment} />;
        })}
      </div>
    </FloatingSidePanel>
  );
}
