import React from 'react';
import { useEffect, useRef, useState } from "react";
import { Row, Col, UncontrolledTooltip } from "reactstrap";
import { Layout } from "../pageLayout/authLayout";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "hooks";

import {
	selectIsLoggedIn,
	selectAuthorizationCodeUrl,
} from "slices/user/identitySlice";

import AzureADAuthHandler from "auth/azureADAuthHandler";
import { isEmailValid } from "../../components/utils/inputValidation/email";
import { isNewmarkAzureAdEmail } from "components/utils/domain";
import { sendOneTimePasswordRequest } from "components/api/identityProviderWrapper";
import AcceptanceTestHydrator from "auth/acceptanceTestHydrator";
import { toast, Bounce } from "react-toastify";

// Optional Legal Text;
import { appInfo } from "constants/termsLinks";
const NM_OPTIONAL_LEGAL = appInfo.NM_OPTIONAL_LEGAL;

export default function Login() {
	const navigate = useNavigate();

	const [showingDisplayEmailTooltip, setShowingDisplayEmailPrompt] =
		useState(false);

	const [email, setEmail] = useState("");
	const [shouldDisplaySpinner, setShouldDisplaySpinner] = useState(false);
	const [isEmailInputValid, setIsEmailInputValid] = useState(false);

	const isLoggedIn = useAppSelector(selectIsLoggedIn);
	const authorizationCodeUrl = useAppSelector(selectAuthorizationCodeUrl);

	const loginInputRef = useRef<HTMLInputElement>(null);
	const loginButtonRef = useRef<HTMLButtonElement>(null);

	interface CustomToastProps {
		email: string;
	}

	// Bold the email supplied by the visitor
	// in Toast message; 
	const CustomToast: React.FC<CustomToastProps> = ({ email }) => (
		<div>
			One-Time Passcode sent to: <strong>{email}</strong>
		</div>
	);

	useEffect(() => {
		loginInputRef.current?.focus();
	});

	useEffect(() => {
		setIsEmailInputValid(isEmailValid(email));
	}, [email]);

	useEffect(() => {
		if (window.location.hash.startsWith("#code=")) {
			setShouldDisplaySpinner(true);
		}
	}, [shouldDisplaySpinner]);
	useEffect(() => {
		if (isLoggedIn) {
			navigate("/");
		}
	}, [isLoggedIn, navigate]);

	return (
		<div className="m-0 p-0 auth__page-wrapper">
			<AzureADAuthHandler></AzureADAuthHandler>
			<AcceptanceTestHydrator></AcceptanceTestHydrator>
			<Layout pageTitle="Login">
				<Row>
					{/* Col: Main Content; */}
					<Col className="d-flex flex-column pt-4 justify-content-center auth__main-form">
						{/* Inputs; */}
						<Row>
							<Col className="px-4 mb-0 d-flex flex-column">
								<label
									id="labelEmail" 
									htmlFor="inputEmail" 
									className="pb-1 auth__main-label minmax">
									Email
								</label>
								<input
									type="email" 
									ref={loginInputRef}
									id="inputEmail" 
									className="auth__main-input auth__main-input--email minmax custom-focus" 
									placeholder="Enter your email address"
									aria-labelledby="labelEmail"
									required
									onChange={(e) => {
										setEmail(e.target.value);
										setShowingDisplayEmailPrompt(false);
									}}
									onKeyUp={(e) => {
										if (e.code === "Enter" && loginButtonRef.current) {
											loginButtonRef.current.click();
										}
									}} 
								/>
								<UncontrolledTooltip
									placement="bottom-end"
									isOpen={showingDisplayEmailTooltip}
									target="inputEmail"
								>
									Check your email address. We require an email.
								</UncontrolledTooltip>
							</Col>
						</Row>

						{/* Action; */}
						<Row>
							<Col className="m-0 px-4 py-4 d-flex align-items-center justify-items-center auth__action">
								<button
									ref={loginButtonRef}
									className="w-100 btn btn-cx-primary auth__main-btn minmax custom-focus"
									disabled={!isEmailInputValid}
									onClick={async () => {
										if (email.trim() === "") {
											setShowingDisplayEmailPrompt(true);
											return;
										}

										const isAzureAdEmail = isNewmarkAzureAdEmail(email);

										if (isAzureAdEmail) {
											window.location.href = authorizationCodeUrl;
										} else {
											// Include visitor email in the toast 
											// one extra place for them to verify
											// their email was correct;
											toast.info(<CustomToast email={email} />, {
												position: "top-center",
												autoClose: 5000,
												hideProgressBar: true,
												closeOnClick: true,
												pauseOnHover: true,
												draggable: false,
												progress: undefined,
												theme: "colored",
												transition: Bounce,
												}
											);

										setShouldDisplaySpinner(true);

										sendOneTimePasswordRequest(email);
										setShouldDisplaySpinner(false);
										navigate("/login/otp", { state: { email: email } });
										}
										// The rest of login process is to be handled by useEffect after Connexus -> Azure -> Connexus redirect
										// Having 'convergenceRedirect' in identitySlice is the trigger for the rest of login process
									}}
								>
								{/* Show spinner on button; */}
								{shouldDisplaySpinner ? (
									<span className="auth__main-spinner" title="Please wait..."></span>
								) : (
									<span>Login</span>
								)}
								</button>
							</Col>
						</Row>
					</Col>
				</Row>
				{/* Optional Legal Text; */}
				{typeof NM_OPTIONAL_LEGAL.props.children !== 'undefined' && (
				<Row>
					<p className="lh-1 px-0 pt-4 pb-0 auth__optional-legal">
						{NM_OPTIONAL_LEGAL}
					</p>
				</Row>
				)}
			</Layout>
		</div>
	);
}