import { useEffect, useState } from "react";
import { Container, Row, Col } from "reactstrap";
import { NavigateFunction } from "react-router-dom";
import {
  ColDef,
  GetRowIdParams,
  ICellRendererParams,
  IRowNode,
  ITooltipParams,
  ValueFormatterParams,
} from "ag-grid-community";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsisVertical,
  faSpinner,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

import "./requestedItems.scss";
import DataGrid from "components/shared/grid";
import { AppDispatch } from "store";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "components/shared/popover";
import { Document } from "dto/document";
import { RequestItemsDetails } from "dto/requestItemsDetails";
import {
  RequestItemStatusUpdating,
  ValuationsType,
} from "dto/valuationsSystemInfo";
import UploadPanel from "./uploadPanel";
import CommentPanel from "./commentPanel";
import { isEmailValid } from "components/utils/inputValidation/email";
import { updateDocument } from "components/api/documentData";
import { useAppDispatch, useAppSelector } from "hooks";
import { selectIdentity } from "slices/user/identitySlice";
import {
  RequestItemsDetailsState,
  selectRequestItemsDetails,
} from "slices/assignment/requestItemsDetailsSlice";
import Modal from "components/shared/modal";
import { RequestItem } from "dto/requestItem";
import {
  RequestItemStatusComplete,
  RequestItemStatusNotAvailable,
  RequestItemStatusRequested,
  selectValuationsSystemInfo,
} from "slices/assignment/valuationsSystemInfoSlice";
import { selectUser } from "slices/user/userSlice";

import Badge from "components/shared/badge/badge";
import "./dropdown.scss";
import { MatomoActions, MatomoEventCategories, trackEvent } from "matomo";
enum RequestedItemTableRowType {
  RequestItem = "requestItem",
  Document = "document",
}

export type RequestedItemTableRow = {
  id: number;
  requestedItem: string;
  response: string;
  assignedTo: string;
  status: string | ValuationsType;
  fileCount: string;
  commentCount: string;
  type: RequestedItemTableRowType;
  order: number;
};

function requestItemToRequestItemTableItem(
  requestItem: RequestItem,
  requestItemsDetails: RequestItemsDetails,
  requestItemStatusTypes: ValuationsType[]
): RequestedItemTableRow {
  const contacts = requestItem.contacts.map(({ contactId }) =>
    requestItemsDetails.contactsDetail.find((c) => c.id === contactId)
  );
  const { name, email } = contacts[0] || {};
  const firstContactName = name || email || "";
  const assignedTo =
    contacts.length === 1
      ? firstContactName
      : contacts.length > 1
      ? `${firstContactName} +${contacts.length - 1}`
      : "";

  return {
    id: requestItem.id,
    requestedItem: requestItem.requestItemTypeDescription || "",
    response:
      // Get document names from documentIds
      requestItemsDetails.documentsDetail
        .filter((d: Document) => requestItem.documentIds.includes(d.id))
        .map((d: Document) => d.name)
        .join(", ") || "",
    assignedTo,
    status:
      // Get status from status id
      requestItemStatusTypes.find(
        (s: ValuationsType) => s.id === requestItem.requestItemStatusTypeId
      )!,
    fileCount: `${requestItem.documentIds?.length || 0}`,
    commentCount: `${requestItem.commentIds?.length || 0}`,
    type: RequestedItemTableRowType.RequestItem,
    order: requestItem.requestItemDisplaySortOrder,
  };
}

function documentToRequestItemTableItem(
  document: Document
): RequestedItemTableRow {
  return {
    id: document.id,
    requestedItem: "NOT CATEGORIZED",
    response: document.name || "",
    assignedTo: "-",
    status: "-",
    fileCount: "-",
    commentCount: "-",
    type: RequestedItemTableRowType.Document,
    order: 0,
  };
}

export default function RequestedItems({
  requestItemsDetails = {} as RequestItemsDetails,
}: {
  requestItemsDetails?: RequestItemsDetails;
}) {
  const dispatch = useAppDispatch();
  const identity = useAppSelector(selectIdentity);
  const user = useAppSelector(selectUser);
  const contactId = useAppSelector(
    selectRequestItemsDetails
  )?.contactsDetail.find(
    (c) => c.email.toLowerCase() === user?.email.toLowerCase()
  )?.id;
  const { mimeTypes: acceptedFileTypes, requestItemStatusTypes } =
    useAppSelector(selectValuationsSystemInfo);
  const [isUploadPanelOpen, setIsUploadPanelOpen] = useState(false);
  const [requestedItems, setRequestedItems] = useState<RequestedItemTableRow[]>(
    []
  );
  const [uncategorizedDocs, setUncategorizedDocs] = useState<
    RequestedItemTableRow[]
  >([]);
  const [showUncategorizedDocs, setShowUncategorizedDocs] = useState(false);
  const [selectedRequestItem, setSelectedRequestItem] = useState<
    RequestItem | undefined
  >();
  const [isCommentPanelOpen, setIsCommentPanelOpen] = useState(false);
  const [reassignDetails, setReassignDetails] = useState<
    RequestItem | undefined
  >();
  const [reassignEmail, setReassignEmail] = useState("");
  const [reassignName, setReassignName] = useState("");
  const [reassignPhoneNumber, setReassignPhoneNumber] = useState("");

  function changeRequestItemStatus(
    jobAssignmentPropertyId: number,
    id: number,
    newStatusId: number
  ) {
    dispatch({
      type: "requestItemsDetails/updateRequestItemStatus",
      payload: {
        [jobAssignmentPropertyId]: {
          requestedItems: [{ id, requestItemStatusTypeId: newStatusId }],
        },
      } as RequestItemsDetailsState,
    });
  }

  function upload(data: RequestedItemTableRow) {
    const requestItem: RequestItem = requestItemsDetails.requestedItems.find(
      (ri) => ri.id === data.id
    )!;
    setSelectedRequestItem(requestItem);
    setIsUploadPanelOpen(true);
  }

  function reassign(data: RequestedItemTableRow) {
    const requestItem: RequestItem = requestItemsDetails.requestedItems.find(
      (ri) => ri.id === data.id
    )!;
    setReassignDetails(requestItem);
  }

  async function markAsNotAvailable(
    data: RequestedItemTableRow,
    requestItemsDetails: RequestItemsDetails
  ) {
    const { jobAssignmentPropertyId } = requestItemsDetails;
    const requestItem: RequestItem = requestItemsDetails.requestedItems.find(
      (ri) => ri.id === data.id
    )!;
    const { id, requestItemStatusTypeId: originalStatusTypeId } = requestItem;
    const notAvailableStatusTypeId = RequestItemStatusNotAvailable?.id;
    changeRequestItemStatus(
      // Display spinning wheel with temporary status
      jobAssignmentPropertyId,
      id,
      RequestItemStatusUpdating.id
    );

    let newStatusTypeId: number, matomoAction: MatomoActions;
    if (originalStatusTypeId === notAvailableStatusTypeId) {
      matomoAction = MatomoActions.UndoMarkAsUnavailable;
      const fileCount = parseInt(data.fileCount) || 0;
      if (fileCount > 0) {
        newStatusTypeId = RequestItemStatusComplete?.id;
      } else {
        newStatusTypeId = RequestItemStatusRequested?.id;
      }
    } else {
      matomoAction = MatomoActions.MarkAsUnavailable;
      newStatusTypeId = notAvailableStatusTypeId;
    }
    const newStatusDescription = requestItemStatusTypes.find(
      (s) => s.id === newStatusTypeId
    )!.description;

    try {
      await updateDocument(identity, {
        id,
        requestItemStatusTypeId: newStatusTypeId,
      });
    } catch (e) {
      console.error(e);
      toast.error(`Failed to mark as ${newStatusDescription}`);
      changeRequestItemStatus(
        jobAssignmentPropertyId,
        id,
        originalStatusTypeId
      );
      return;
    }

    trackEvent(
      MatomoEventCategories.RequestItem,
      matomoAction,
      `${requestItem.id}`
    );
    toast.success(`1 document marked as ${newStatusDescription}`);
    changeRequestItemStatus(jobAssignmentPropertyId, id, newStatusTypeId);
  }

  function addComment(data: RequestedItemTableRow) {
    const requestItem: RequestItem = requestItemsDetails.requestedItems.find(
      (ri) => ri.id === data.id
    )!;
    setSelectedRequestItem(requestItem);
    setIsCommentPanelOpen(true);
  }

  const columnDefs: ColDef[] = [
    {
      field: "requestedItem",
      tooltipField: "requestedItem",
      headerName: "Requested Item",
      flex: 3,
      cellRenderer: (params: ICellRendererParams<RequestedItemTableRow>) => {
        const { type, requestedItem } = params.data!;
        return type === RequestedItemTableRowType.RequestItem ? (
          requestedItem
        ) : (
          <>
            <FontAwesomeIcon icon={faTriangleExclamation} />{" "}
            <span className="requested-item">{requestedItem}</span>
          </>
        );
      },
    },
    {
      field: "",
      headerName: "Actions",
      flex: 3,
      cellRenderer: (params: ICellRendererParams<RequestedItemTableRow>) => {
        if (params.data!.requestedItem === "NOT CATEGORIZED") {
          return;
        }
        const requestItem: RequestItem =
          requestItemsDetails.requestedItems.find(
            (ri) => ri.id === params.data!.id
          )!;
        const isStatusUpdating =
          requestItem.requestItemStatusTypeId === RequestItemStatusUpdating.id;
        return (
          <span className="action-icon-parent">
            <button
              className="action-icon"
              onClick={() => upload(params.data!)}
            >
              Upload
            </button>
            <button
              className="action-icon"
              disabled={isStatusUpdating}
              onClick={() =>
                markAsNotAvailable(params.data!, requestItemsDetails)
              }
            >
              {isStatusUpdating ? (
                <span className="spinner" title="Updating Not Available">
                  Not Available
                </span>
              ) : (
                <span>Not Available</span>
              )}
            </button>
            {requestItem.contacts.find((c) => c.contactId === contactId)
              ?.canReassign && (
              <button
                className="action-icon"
                onClick={() => reassign(params.data!)}
              >
                Reassign
              </button>
            )}
          </span>
        );
      },
    },
    {
      field: "assignedTo",
      headerName: "Assigned To",
      flex: 1.5,
      tooltipValueGetter: (params: ITooltipParams<RequestedItemTableRow>) => {
        const requestItem: RequestItem =
          requestItemsDetails.requestedItems.find(
            (ri) => ri.id === params.data!.id
          )!;
        const contactNames = requestItem.contacts.map(({ contactId }) => {
          const contact = requestItemsDetails.contactsDetail.find(
            (c) => c.id === contactId
          )!;
          return contact.name || contact.email;
        });
        return contactNames.join("\n");
      },
    },
    {
      field: "status",
      headerName: "Status",
      valueFormatter: (params: ValueFormatterParams<RequestedItemTableRow>) =>
        !params.data
          ? params.value
          : typeof params.data.status === "string"
          ? params.data.status
          : params.data.status.description.toUpperCase(),
      autoHeight: true,
      wrapText: true,
      cellRenderer: (params: ICellRendererParams<RequestedItemTableRow>) => {
        const status = params.valueFormatted || "-";

        const statusToBadgeColor: { [key: string]: string } = {
          "-": "",
          REQUESTED: "grey",
          "NOT AVAILABLE": "light-grey",
          "IN PROGRESS": "yellow",
          COMPLETED: "green",
        };
        const badgeColor = statusToBadgeColor[status];
        return status === "-" ? (
          status
        ) : (
          <>
            <Badge color={badgeColor}>
              {status.replace("STATUS UPDATING", "UPDATING")}
            </Badge>

            {params.data!.status === RequestItemStatusUpdating && (
              <FontAwesomeIcon icon={faSpinner} spin />
            )}
          </>
        );
      },
    },
    {
      field: "fileCount",
      headerName: "Files",
      flex: 0.75,
      cellRenderer: (params: ICellRendererParams<RequestedItemTableRow>) => {
        const { fileCount } = params.data!;
        const requestItem: RequestItem =
          requestItemsDetails.requestedItems.find(
            (ri) => ri.id === params.data!.id
          )!;
        const documentNames =
          fileCount === "-"
            ? []
            : requestItem.documentIds
                ?.map(
                  (docId: number) =>
                    requestItemsDetails.documentsDetail.find(
                      (d: Document) => d.id === docId
                    )!
                )
                .sort((docA, docB) => {
                  const docATime = new Date(docA.createdOn).getTime();
                  const docBTime = new Date(docB.createdOn).getTime();

                  if (docATime === docBTime) return 0;
                  return docATime < docBTime ? 1 : -1; // Display newest on top
                })
                .map((doc) => doc.name);
        return (
          <Popover>
            <PopoverTrigger>{fileCount}</PopoverTrigger>
            {documentNames?.length > 0 && (
              <PopoverContent>
                <ul>
                  {documentNames.map((name, i) => (
                    <li key={name + i}>{name}</li>
                  ))}
                </ul>
              </PopoverContent>
            )}
          </Popover>
        );
      },
    },
    {
      field: "commentCount",
      headerName: "Comments",
      flex: 1,
      cellRenderer: (params: ICellRendererParams<RequestedItemTableRow>) => {
        const { commentCount } = params.data!;
        if (commentCount === "-") {
          return commentCount;
        }
        return (
          <div
            className="clickable-number"
            onClick={() => addComment(params.data!)}
          >
            {params.data!.commentCount}
          </div>
        );
      },
    },
    {
      flex: 0.1,
      field: "triple dots",
      cellStyle: {
        overflow: "hidden",
      },
      headerName: "",
      cellRenderer: (params: ICellRendererParams<RequestedItemTableRow>) => {
        const closePopover = () => {
          // TODO temp solution to close the Popover
          document.dispatchEvent(
            new KeyboardEvent("keydown", {
              key: "Escape",
            })
          );
        };
        const requestItem: RequestItem =
          requestItemsDetails.requestedItems.find(
            (ri) => ri.id === params.data!.id
          )!;
        const conditionalPopoverContent =
          params.data!.requestedItem === "NOT CATEGORIZED"
            ? []
            : [
                <li
                  key="add-comment"
                  onClick={() => {
                    closePopover();
                    addComment(params.data!);
                  }}
                >
                  Add Comment
                </li>,
              ];
        const popoverContent = [...conditionalPopoverContent];
        if (popoverContent.length === 0) {
          // Don't even show the triple dots if there's nothing to show
          return;
        }
        return (
          <Popover>
            <PopoverTrigger>
              <FontAwesomeIcon icon={faEllipsisVertical} />
            </PopoverTrigger>
            <PopoverContent>
              <ul>{popoverContent}</ul>
            </PopoverContent>
          </Popover>
        );
      },
    },
  ];

  useEffect(() => {
    // Set requestedItems and uncategorizedDocs (to display table) when requestItemsDetails is available
    if (
      requestItemsDetails.requestedItems?.length > 0 &&
      requestItemStatusTypes.length > 0
    ) {
      setRequestedItems([
        ...requestItemsDetails.requestedItems
          .map((ri) =>
            requestItemToRequestItemTableItem(
              ri,
              requestItemsDetails,
              requestItemStatusTypes
            )
          )
          .sort((a: RequestedItemTableRow, b: RequestedItemTableRow) => {
            if (a.order === b.order) return 0;
            return a.order - b.order;
          }),
      ]);

      const uncategorizedDocsOriginal =
        requestItemsDetails.assignmentDocumentIds?.map(
          (docId) =>
            requestItemsDetails.documentsDetail.find((doc) => doc.id === docId)!
        );
      setUncategorizedDocs(
        uncategorizedDocsOriginal.map((d) => documentToRequestItemTableItem(d))
      );
    }
  }, [
    requestItemStatusTypes,
    requestItemsDetails,
    requestedItems.length,
    uncategorizedDocs.length,
  ]);
  return (
    <Container fluid className="requested-items-parent">
      {isUploadPanelOpen && (
        <UploadPanel
          acceptedFileTypes={acceptedFileTypes}
          requestItem={selectedRequestItem}
          closePanel={() => {
            setIsUploadPanelOpen(false);
            setSelectedRequestItem(undefined);
          }}
        />
      )}
      {isCommentPanelOpen && (
        <CommentPanel
          commentsDetail={requestItemsDetails.commentsDetail}
          requestItem={selectedRequestItem}
          closePanel={() => {
            setIsCommentPanelOpen(false);
            setSelectedRequestItem(undefined);
          }}
        />
      )}
      <Row className="requested-items-list">
        <DataGrid
          data={
            showUncategorizedDocs
              ? [...requestedItems, ...uncategorizedDocs]
              : requestedItems
          }
          columnDefs={columnDefs}
          getRowId={(params: GetRowIdParams<RequestedItemTableRow>) =>
            `${params.data!.id}`
          }
          getRowClass={(params: any) => {
            if (params.data.type === RequestedItemTableRowType.Document) {
              return "uncategorized-row";
            }
            return undefined;
          }}
          navigateWrapper={(navigate: NavigateFunction, data: any) => {}}
          dispatchWrapper={(dispatch: AppDispatch, data: any) => {}}
        />
      </Row>

      <Modal
        isOpen={!!reassignDetails}
        confirmButtonProps={{
          disabled: !reassignEmail || !reassignName || !reassignPhoneNumber,
        }}
        onConfirm={async () => {
          const { id } = (reassignDetails || {}) as RequestItem;
          try {
            await updateDocument(identity, {
              id,
              contactDetailEmail: reassignEmail,
              contactDetailName: reassignName,
              contactDetailPhone: reassignPhoneNumber,
            });
          } catch (e) {
            console.error(e);
            toast.error("Failed to reassign");
            return;
          } finally {
            setReassignEmail("");
            setReassignName("");
            setReassignPhoneNumber("");
            setReassignDetails(undefined);
          }

          trackEvent(
            MatomoEventCategories.RequestItem,
            MatomoActions.Reassign,
            `${reassignDetails?.id}` // request item id
          );
          toast.success("1 item was reassigned");
        }}
        onClose={() => {
          setReassignEmail("");
          setReassignName("");
          setReassignPhoneNumber("");
          setReassignDetails(undefined);
        }}
        contentLabel="Reassign Modal"
        ariaDescription="Allowing clients to reassign the request item to a different person"
        title="Reassign request"
      >
        <span className="reassign-modal-text">
          Please note that the person you assign this request to will be able to
          view all comments under this request. As our client contact, you will
          retain visibility of all updates even after the reassignment.
        </span>
        <div className="reassign-modal-input-parent">
          Email
          <span className="mandatory-field-color"> *</span>
          <br />
          <input
            type="text"
            className="reassign-modal-input"
            onChange={(e) => {
              const email = e.target.value;
              if (isEmailValid(email, { isForValuations: true })) {
                setReassignEmail(email);
              } else {
                setReassignEmail("");
              }
            }}
          />
        </div>
        <div className="reassign-modal-input-parent">
          Name
          <br />
          <input
            type="text"
            className="reassign-modal-input"
            onChange={(e) => setReassignName(e.target.value)}
          />
        </div>
        <div className="reassign-modal-input-parent">
          Phone Number
          <br />
          <input
            type="text"
            className="reassign-modal-input"
            onChange={(e) => setReassignPhoneNumber(e.target.value)}
          />
        </div>
      </Modal>
    </Container>
  );
}
