import axios, { AxiosRequestConfig, AxiosResponse } from "axios";

/**
 * Creates a request to send a one time passcode to the user's email
 * @param email the email address of the user to send the one time passcode to
 * @returns a promise that resolves to true if the request was successful, false otherwise
 */
export async function sendOneTimePasswordRequest(email: string
): Promise<boolean> {
    const config: AxiosRequestConfig = {
        headers: {
            "Content-Type": "application/json",
        },
    };

    // Assign params for each data operation
    let params = [
        {
            ...config,
            email
        } as AxiosRequestConfig,
    ];
    // Make the request against APIM
    const url = `${process.env.REACT_APP_IDENTITY_PROVIDER_APIM_GATEWAY_URL}/${process.env.REACT_APP_IDENTITY_PROVIDER_ONE_TIME_PASSWORD}`;
    const response: AxiosResponse = await axios.post(url, ...params);

    // Return the result
    if (response.status < 300) {
        return true;
    }
    return false;
}

export async function exchangeOneTimePasscode(email: string, oneTimePasscode: string): Promise<{ accessToken: string, identityToken: string, refreshToken: string }> {
    return await axios.post(`${process.env.REACT_APP_IDENTITY_PROVIDER_APIM_GATEWAY_URL}/${process.env.REACT_APP_IDENTITY_PROVIDER_EXCHANGE_ONE_TIME_PASSWORD}`, {
        email,
        otp: oneTimePasscode
    }).then(function (response) {
        const { accesstoken, identitytoken, refreshtoken } = response.headers;
        return {
            accessToken: accesstoken,
            identityToken: identitytoken,
            refreshToken: refreshtoken
        }
    });
}
