import { useEffect } from "react";
import { Container, Row, Col } from "reactstrap";
import { toast } from "react-toastify";

import "./index.scss";
import { getDocuments } from "components/api/documentSearchData";
import { selectIdentity } from "slices/user/identitySlice";
import { RequestItemsDetails } from "dto/requestItemsDetails";
import { useAppDispatch, useAppSelector } from "hooks";
import {
  RequestItemsDetailsState,
  selectRequestItemsDetails,
} from "slices/assignment/requestItemsDetailsSlice";
import { useNavigate, useParams } from "react-router-dom";
import { selectAssignmentSelected } from "slices/assignment/assignmentSelectedSlice";
import { Identity } from "dto/identity";
import { getAllAssignmentData } from "components/api/dataRoomSearchData";
import { Assignment } from "dto/assignment";
import HeaderTemplate from "components/shared/header";
import ValuationsDataRoomHeader from "./header";
import Tabs from "components/shared/tabs";
import Comments from "./comments";
import RequestedItems from "./requestedItems";
import { selectValuationsSystemInfo } from "slices/assignment/valuationsSystemInfoSlice";
import { ValuationsSystemInfo } from "dto/valuationsSystemInfo";
import { getSystemInfo } from "components/api/systemInfo";

export default function ValuationsDataRoomComponent() {
  const identity: Identity = useAppSelector(selectIdentity);
  const params = useParams();
  const jobAssignmentPropertyId = parseInt(
    params.jobAssignmentPropertyId || ""
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const assignmentData = useAppSelector(selectAssignmentSelected);
  const requestItemsDetails = useAppSelector(selectRequestItemsDetails);
  const valuationsSystemInfo = useAppSelector(selectValuationsSystemInfo);
  const tabs = [
    {
      name: "RequestedItems",
      component: RequestedItems,
      props: { requestItemsDetails },
      count: requestItemsDetails?.requestedItems?.length || 0,
    },
    {
      name: "Comments",
      component: Comments,
      props: {
        commentsDetail: requestItemsDetails?.commentsDetail || [],
        requestedItems: requestItemsDetails?.requestedItems || [],
      },
      count: requestItemsDetails?.commentsDetail?.length || 0,
    },
  ];
  const selectedIndex = tabs
    .map((tab) => tab.name)
    .indexOf(params["tabName"] || "");

  useEffect(() => {
    if (assignmentData?.jobAssignmentPropertyId === -1) {
      // Coming to the page directly, not from the assign list
      getAllAssignmentData(identity)
        .then(function (data: Assignment[]) {
          dispatch({ type: "assignmentList/load", payload: data });

          const selectedAssignment = data.filter(
            (d) => d.jobAssignmentPropertyId === jobAssignmentPropertyId
          )[0];
          if (selectedAssignment) {
            dispatch({
              type: "assignmentSelected/select",
              payload: selectedAssignment,
            });
          } else {
            toast.error("You don't have access to this assignment");
            navigate("/");
          }
        })
        .catch((error) => console.error("Error fetching data:", error));
    }
  }, [
    assignmentData?.jobAssignmentPropertyId,
    dispatch,
    identity,
    jobAssignmentPropertyId,
    navigate,
  ]);

  useEffect(() => {
    if (!requestItemsDetails) {
      getDocuments(identity, jobAssignmentPropertyId)
        .then(function (data: RequestItemsDetails) {
          return dispatch({
            type: "requestItemsDetails/load",
            payload: {
              [jobAssignmentPropertyId]: data,
            } as RequestItemsDetailsState,
          });
        })
        .catch((error) => console.error("Error fetching data:", error));
    }
  }, [dispatch, identity, jobAssignmentPropertyId, requestItemsDetails]);

  useEffect(() => {
    if (
      valuationsSystemInfo.mimeTypes.length === 0 ||
      valuationsSystemInfo.requestItemCategoryTypes.length === 0 ||
      valuationsSystemInfo.requestItemStatusTypes.length === 0 ||
      valuationsSystemInfo.storageTypes.length === 0
    ) {
      getSystemInfo(identity)
        .then(function (data: ValuationsSystemInfo) {
          return dispatch({
            type: "valuationsSystemInfo/load",
            payload: data,
          });
        })
        .catch((error) => console.error("Error fetching data:", error));
    }
  }, [
    dispatch,
    identity,
    valuationsSystemInfo.mimeTypes.length,
    valuationsSystemInfo.requestItemCategoryTypes.length,
    valuationsSystemInfo.requestItemStatusTypes.length,
    valuationsSystemInfo.storageTypes.length,
  ]);

  return (
    <Container fluid>
      <Row>
        <ValuationsDataRoomHeader />
      </Row>
      <Row>
        <Tabs
          tabs={tabs}
          selectedIndex={selectedIndex}
          onSelectWrapper={({ index, navigate }) => {
            const newEndpoint = `/valuations/dataRooms/${jobAssignmentPropertyId}/${tabs[index].name}`;
            navigate(newEndpoint);
          }}
        />
      </Row>
    </Container>
  );
}
