import { Identity } from "dto/identity";

import { User } from "dto/user";
import DATA_OPERATIONS from "enums/dataOperations";
import { makeHttpCall } from "./apiHelper";
import { decodeIdentityToken } from "slices/user/identitySlice";

async function _performDataOp(
  identity: Identity,
  user: User | null = null,
  dataOp: DATA_OPERATIONS,
  toastMessage?: string
): Promise<User | undefined> {
  let authId, firstName, lastName, email;
  if (user) {
    ({ id: authId, firstName, lastName, email } = user);
  } else {
    const decodedToken = decodeIdentityToken(identity);
    authId = decodedToken?.id;
    firstName = decodedToken?.firstName;
    lastName = decodedToken?.lastName;
    email = decodedToken?.email;
  }
  let endpoint: string = process.env.REACT_APP_APIM_USER!,
    data: any;
  switch (dataOp) {
    case DATA_OPERATIONS.READ:
      endpoint += `?userEmail=${encodeURIComponent(email)}`;
      break;
    case DATA_OPERATIONS.CREATE:
      data = {
        userFirstName: firstName,
        userLastName: lastName,
        userEmail: email,
      };
      break;
    case DATA_OPERATIONS.UPDATE:
      data = {
        userFirstName: firstName,
        userLastName: lastName,
        userEmail: email,
      };
      break;
    case DATA_OPERATIONS.ARCHIVE:
      data = {};
      break;
  }

  const response = await makeHttpCall(identity, dataOp, endpoint, { data, numberOfTries: 3, toastMessage });

  if (response?.status < 300) {
    const { id, FirstName, LastName, Email } = response.data?.[0]?.[0] || {};
    if (dataOp === DATA_OPERATIONS.READ && !id) {
      return;
    }
    return {
      id: id || authId,
      firstName: FirstName || firstName,
      lastName: LastName || lastName,
      email: Email || email,
    } as User;
  }
}

async function _readOtherUserDataOp(
  identity: Identity,
  userEmail: string,
  toastMessage?: string
): Promise<User | undefined> {

  let authId, firstName, lastName, email;
  const decodedToken = decodeIdentityToken(identity);
  authId = decodedToken?.id;
  firstName = decodedToken?.firstName;
  lastName = decodedToken?.lastName;
  email = userEmail;

  let endpoint = `${process.env.REACT_APP_APIM_USER!}?userEmail=${encodeURIComponent(email)}`;

  const response = await makeHttpCall(identity, DATA_OPERATIONS.READ, endpoint, { numberOfTries: 3, toastMessage });

  if (response?.status < 300) {
    const { id, FirstName, LastName, Email } = response.data?.[0]?.[0] || {};
    if (!id) {
      return;
    }
    return {
      id: id || authId,
      firstName: FirstName || firstName,
      lastName: LastName || lastName,
      email: Email || email,
    } as User;
  }
}

export async function readUserData(
  identity: Identity,
  toastMessage?: string
): Promise<User | undefined> {
  return await _performDataOp(identity, null, DATA_OPERATIONS.READ, toastMessage);
}
export async function readOtherUserData(
  identity: Identity,
  otherUserEmail: string,
  toastMessage?: string,
): Promise<User | undefined> {
  return await _readOtherUserDataOp(identity, otherUserEmail, toastMessage);
}

export async function createUserData(identity: Identity, user: User | null = null
): Promise<User | undefined> {
  return await _performDataOp(identity, user, DATA_OPERATIONS.CREATE);
}

export async function updateUserData(identity: Identity, user: User | null = null
): Promise<User | undefined> {
  return await _performDataOp(identity, user, DATA_OPERATIONS.UPDATE);
}

export async function archiveUserData(identity: Identity
): Promise<User | undefined> {
  return await _performDataOp(identity, null, DATA_OPERATIONS.ARCHIVE);
}
