import React, { useState, useEffect, useCallback } from "react";
import collapsible from "components/shared/collapsible/index.module.scss";
import { useAppDispatch, useAppSelector } from "hooks";
import { selectSelectedFolder } from "slices/document/selectedFolderSlice";
import FolderData from "../types/folderData";
import { selectExpandedFolder } from "slices/document/folderListExpandFolderSlice";

import "./HierarchicalView.scss";
const ROOT_PARENT_ID = -1;
function assignParentIds(json: FolderData[]) {
  function traverse(node: FolderData, parentId: number) {
    node.parentId = parentId;
    if (node.childData) {
      node.childData.forEach((child) => traverse(child, node.id));
    }
  }
  json.forEach((node) => traverse(node, ROOT_PARENT_ID));
}

/**
 * Assigns a unique number id to each element recursively
 * @param json the object to have ids assigned uniquely
 */
function assignIds(json: FolderData[]) {
  let idCounter = 1; // Initialize ID counter

  function traverse(node: FolderData) {
    node.id = idCounter++; // Assign unique ID to the current node
    if (node.childData) {
      node.childData.forEach((child) => traverse(child)); // Recursively traverse child nodes
    }
  }

  json.forEach((node) => traverse(node)); // Traverse each top-level node
}

interface Props {
  data: FolderData[];
}

interface Props {
  data: FolderData[];
}

interface Props {
  data: FolderData[];
  selectedRowId: number;
  setSelectedRowId: (index: number) => void;
  rowIdToFolderMap: { [key: number]: FolderData };
  activeNodes: number[];
  toggleExpanded: (id: number) => void;
}

const TreeNode: React.FC<Props> = ({
  data,
  selectedRowId,
  setSelectedRowId,
  rowIdToFolderMap,
  activeNodes,
  toggleExpanded,
}) => {
  const dispatch = useAppDispatch();

  return (
    <ul className="tree prevent-select">
      {data.map(
        (node) =>
          node.isFolder && ( // Only render if the node is a folder TODO remove this once we're grabbing from the server--the API should _not_ be supplying non-folders for the hierarchical view call
            <li key={node.id}>
              <div
                className={`${collapsible.collapsible} ${
                  activeNodes.includes(node.id) ? collapsible.active : ""
                }`}
              >
                <div className={`d-flex align-items-center`}>
                  {node.childData &&
                    node.childData.find((x) => x.isFolder) && ( // leave this check in--it only renders the icon if there are children which are themselves folders
                      <button
                        className={`${collapsible.collapsible__toggle} ${
                          activeNodes.includes(node.id)
                            ? collapsible.active
                            : ""
                        }`}
                        style={{ padding: 0 }}
                        onClick={() => {
                          toggleExpanded(node.id);
                        }}
                      ></button>
                    )}
                  <div
                    className={`${
                      node.id === selectedRowId ? "selected-tree-node" : ""
                    }`}
                    onClick={() => {
                      dispatch({
                        type: "selectedFolder/select",
                        payload: rowIdToFolderMap[node.id],
                      });
                    }}
                  >
                    {node.name}
                  </div>
                </div>
                {node.childData && activeNodes.includes(node.id) && (
                  <TreeNode
                    data={node.childData}
                    selectedRowId={selectedRowId}
                    setSelectedRowId={setSelectedRowId}
                    rowIdToFolderMap={rowIdToFolderMap}
                    activeNodes={activeNodes}
                    toggleExpanded={toggleExpanded}
                  />
                )}
              </div>
            </li>
          )
      )}
    </ul>
  );
};

/**
 * Renders a treegrid of the given data
 * @param param0 an array of TreeData
 * @returns a treegrid of the given data
 */
const HierarchicalView = ({ data }: { data: FolderData[] }) => {
  const [presentationRows, setPresentationRows] = useState<FolderData[]>([]);
  const [selectedRowId, setSelectedRowId] = useState(-1);
  const [alreadySelectedFirst, setAlreadySelectedFirst] = useState(false);

  const dispatch = useAppDispatch();
  const flattenFolderHierarchy = function (data: FolderData[]): {
    [id: number]: FolderData;
  } {
    const flattenedData: { [id: number]: FolderData } = {};

    // Recursive function to flatten the hierarchy
    function flatten(folder: FolderData) {
      if (!folder.isFolder) {
        return;
      }
      flattenedData[folder.id] = folder;

      if (folder.childData) {
        folder.childData.forEach((child) => {
          flatten(child);
        });
      }
    }

    // Iterate through each element in the initial array
    data.forEach((folder) => {
      flatten(folder);
    });

    return flattenedData;
  };
  const rowIdToFolderMap: { [key: number]: FolderData } =
    flattenFolderHierarchy(presentationRows);

  useEffect(() => {
    const cloned: FolderData[] = JSON.parse(JSON.stringify(data)); // clone the data so we don't impact the upstream source
    assignParentIds(cloned);
    assignIds(cloned);
    setPresentationRows(cloned);
  }, [data]);

  useEffect(() => {
    // TODO setup deeplink select rather than first
    if (presentationRows.length && !alreadySelectedFirst) {
      setAlreadySelectedFirst(true);
      setSelectedRowId(presentationRows[0].id);
      dispatch({
        type: "selectedFolder/select",
        payload: rowIdToFolderMap[presentationRows[0].id],
      });
    }
  }); // only fire on first render
  const [expandedNodes, setExpandedNodes] = useState<number[]>([]);
  const toggleExpanded = useCallback(
    (id: number) => {
      if (expandedNodes.includes(id)) {
        setExpandedNodes(expandedNodes.filter((i) => i !== id));
      } else {
        setExpandedNodes([...expandedNodes, id]);
      }
    },
    [expandedNodes]
  );
  // for requests from folder contents view
  const selectedFolderContents = useAppSelector(selectSelectedFolder);
  useEffect(() => {
    setSelectedRowId(selectedFolderContents.id);
  }, [selectedFolderContents, expandedNodes, toggleExpanded]);
  const expandedFolderId = useAppSelector(selectExpandedFolder);
  useEffect(() => {
    if (!expandedNodes.includes(expandedFolderId)) {
      toggleExpanded(expandedFolderId);
    }
  }, [expandedFolderId, expandedNodes, toggleExpanded]);

  return (
    <TreeNode
      data={presentationRows}
      selectedRowId={selectedRowId}
      setSelectedRowId={setSelectedRowId}
      rowIdToFolderMap={rowIdToFolderMap}
      activeNodes={expandedNodes}
      toggleExpanded={toggleExpanded}
    />
  );
};

export default HierarchicalView;
