import { AxiosResponse } from "axios";

import { Identity } from "dto/identity";
import { makeHttpCall } from "./apiHelper";
import DATA_OPERATIONS from "enums/dataOperations";
import DATA_PROVIDERS from "enums/dataProviders";

async function _performDataOp(
    identity: Identity,
    dataOp: DATA_OPERATIONS,
    data: {
        jobId: number;
        jobAssignmentPropertyId: number;
        targetContactEmail: string;
        targetContactName: string;
        targetContactPhone: string;
    }
): Promise<any> {
    const response: AxiosResponse = await makeHttpCall(
        identity,
        dataOp,
        process.env.REACT_APP_APIM_INVITE!,
        { data: data, dataProvider: DATA_PROVIDERS.VALUATIONS }
    );
    if (response?.status < 300) {
        return response.data;
    }
    return {};
}

export async function inviteContact(
    identity: Identity,   
    jobIdValue: number,
    jobAssgPropertyId: number,
    email: string,
    name: string,
    phone: string

): Promise<any> {
    const data = {
        jobId: jobIdValue,
        jobAssignmentPropertyId: jobAssgPropertyId,
        targetContactEmail: email,
        targetContactName: name,
        targetContactPhone: phone,
        Operation: "Collaborate"
    };
    return await _performDataOp(identity, DATA_OPERATIONS.CREATE, data);
}
