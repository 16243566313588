import { forwardRef } from "react";
import * as PopoverPrimitive from "@radix-ui/react-popover";

import "./popover.scss";

export const Popover = PopoverPrimitive.Root;

export const PopoverTrigger = forwardRef(
  ({ children, ...props }: any, forwardedRef) => (
    <PopoverPrimitive.Trigger
      className="popover-trigger"
      {...props}
      ref={forwardedRef}
    >
      {children}
    </PopoverPrimitive.Trigger>
  )
);

export const PopoverContent = forwardRef(
  ({ children, ...props }: any, forwardedRef) => {
    return (
      <PopoverPrimitive.Portal>
        <PopoverPrimitive.Content
          {...props}
          className={`popover-content ${
            props.className ? props.className : ""
          }`}
          ref={forwardedRef}
        >
          {children}
        </PopoverPrimitive.Content>
      </PopoverPrimitive.Portal>
    );
  }
);

