// Slices are combined actions and reducers
// more at https://react-redux.js.org/tutorials/typescript-quick-start#define-slice-state-and-action-types

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "store";

export type Breadcrumb = { text: string; path: string };

interface BreadcrumbState {
  breadcrumb: Breadcrumb[];
}

const initialState: BreadcrumbState = {
  breadcrumb: [
    {
      text: "HOME",
      path: "/",
    },
  ],
};

const breadcrumbSlice = createSlice({
  name: "breadcrumb",
  initialState,
  reducers: {
    override: (state, action: PayloadAction<Breadcrumb[]>) => {
      state.breadcrumb = action.payload;
    },
    reset: (state) => {
      state.breadcrumb = [
        {
          text: "HOME",
          path: "/",
        },
      ];
    },
  },
});

export const { override, reset } = breadcrumbSlice.actions;

export const selectBreadcrumb = (state: RootState): Breadcrumb[] =>
  state.breadcrumb.breadcrumb;

export default breadcrumbSlice.reducer;
