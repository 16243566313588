import { AxiosResponse } from "axios";

import { makeHttpCall } from "./apiHelper";
import DATA_OPERATIONS from "enums/dataOperations";
import { InviteSummary } from "dto/inviteSummary";
import { Identity } from "dto/identity";  

export default async function getAllAccessData(
  identity: Identity,
  dataRoomId: string
): Promise<InviteSummary[]> {
  const response: AxiosResponse = await makeHttpCall(
    identity,
    DATA_OPERATIONS.READ,
    `${process.env.REACT_APP_APIM_DATAROOM_SUMMARY}?dataroomId=${dataRoomId}`
  );

  // Return the DataRoom object
  if (response?.status < 300) {
    const responseData = response.data?.[0] || [];
    return responseData?.map((invite: any) => {
      return {
        inviteId: invite.inviteId,
        userId: invite.userId,
        email: invite.email,
        dataRoomId,
        addedDate: invite.addedDate,
        inviteeRoleInCurrentDataRoom: invite.roleName,
        status: invite.status,
      } as InviteSummary;
    });
  }
  return [];
}
