// Slice for identity provider user data
// Slices are combined actions and reducers
// more at https://react-redux.js.org/tutorials/typescript-quick-start#define-slice-state-and-action-types

import { createSlice, createSelector, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from 'store'

import { Identity } from 'dto/identity';

const initialState = {
    identityProvider: "",
    identityToken: "",
    accessToken: "",
    refreshToken: "",
    exp: -1,
    obtained: -1,
    convergenceRedirect: "",
    codeChallenge: "",
    authorizationCodeUrl: "",
}

export const identityTokenUserSlice = createSlice({
    name: 'identity',
    initialState,
    reducers: {
        setTokens(state, action: PayloadAction<Identity>) {
            state.identityProvider = action.payload.identityProvider;
            state.identityToken = action.payload.identityToken;
            state.accessToken = action.payload.accessToken;
            state.refreshToken = action.payload.refreshToken;
            state.exp = action.payload.exp;
            state.obtained = action.payload.obtained;
        },
        logout(state) {
            state.identityProvider = "";
            state.identityToken = "";
            state.accessToken = "";
            state.refreshToken = "";
            state.exp = -1;
            state.obtained = -1;
            state.convergenceRedirect = "";            
        },
        setConvergenceRedirect(state, action: PayloadAction<string>) {
            state.convergenceRedirect = action.payload;
        },
        setCodeChallenge(state, action: PayloadAction<string>) {
            state.codeChallenge = action.payload;
        },
        setAuthorizationCodeUrl(state, action: PayloadAction<string>) {
            state.authorizationCodeUrl = action.payload;
        },

    }
});
export const { setTokens, logout } = identityTokenUserSlice.actions

export function selectIsLoggedIn(state: RootState): Boolean {
    return !!state.identity.identityProvider
}
const selectRoot = (state: RootState) => state;
export const selectIdentity = createSelector([selectRoot], function (state: RootState): Identity {
    return {
        identityProvider: state.identity.identityProvider,
        identityToken: state.identity.identityToken,
        accessToken: state.identity.accessToken,
        refreshToken: state.identity.refreshToken,
        exp: state.identity.exp,
        obtained: state.identity.obtained
    };
});
export function decodeIdentityToken(identity: Identity): { id: string, firstName: string, lastName: string, email: string } {
    const parsed = JSON.parse(atob(identity.identityToken!.split('.')[1]));
    return {
        id: parsed.oid,
        firstName: parsed?.name?.split(', ')[1] || "",
        lastName: parsed?.name?.split(', ')[0] || "",
        email: parsed.preferred_username || parsed.email || ""
    }
}
export function selectConvergenceRedirect(state: RootState): string {
    return state.identity.convergenceRedirect;
}
export function selectCodeChallenge(state: RootState): string {
    return state.identity.codeChallenge;
}
export function selectAuthorizationCodeUrl(state: RootState): string {
    return state.identity.authorizationCodeUrl
}
export function getClaim(token: string, claim: string): string {
    const parsed = JSON.parse(atob(token.split('.')[1]));
    return parsed[claim];
}

export default identityTokenUserSlice.reducer;

