import { useState, useEffect } from "react";
import { NavigateFunction } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolder } from "@fortawesome/free-solid-svg-icons";
import { ColDef, ValueFormatterParams } from "ag-grid-community";

import DataGrid from "components/shared/grid";
import { dateFormatter } from "components/utils/columnFormatting/date";

import { useAppSelector } from "hooks";
import { AppDispatch } from "store";
import { selectSelectedFolder } from "slices/document/selectedFolderSlice";

import "./FolderContentsView.scss";

const FolderContentsView = () => {
  const selectedFolder = useAppSelector(selectSelectedFolder);
  const [selectedFolderContents, setSelectedFolderContents] = useState(
    selectedFolder.childData || []
  );
  useEffect(() => {
    // TODO make an api call to grab the data for the selected folder based on the selectedFolderContents
    setSelectedFolderContents(selectedFolder.childData || []); // TODO after the api call, set the data here
  }, [selectedFolder]);

  const columnDefs = [
    {
      field: "name",
      headerName: "Name",
      minWidth: 200,
      flex: 1,
      sort: "asc",
      sortable: true,
      cellRenderer: (params: any) => {
        const {
          data: { name, isFolder, fileSize },
        } = params;
        return (
          <span>
            {isFolder && (
              <FontAwesomeIcon
                className="nmrk-fontawesome-spacer"
                icon={faFolder}
              />
            )}
            {!isFolder && <span className="nmrk-fontawesome-spacer"></span>}
            {name}
          </span>
        );
      },
    },
    {
      field: "addedDate",
      headerName: "Uploaded",
      sortable: true,
      valueFormatter: (params: ValueFormatterParams) =>
        dateFormatter(params.data.addedDate ?? params.data.invitedDate),
    },
    {
      field: "fileSize",
      headerName: "Size",
      sortable: true,
    },
    // TODO: handle triple dot
    // {
    //   field: "triple dots",
    //   headerName: " ",
    //   sortable: false,
    //   width: 70,
    //   minWidth: 30,
    //   cellRenderer: (params: any) => {
    //     const {
    //       data: {
    //         inviteId,
    //         email,
    //         userId: receivingUserId,
    //         inviteeRoleInCurrentDataRoom,
    //       },
    //     } = params;
    //     const closePopover = () => {
    //       document.dispatchEvent(
    //         new KeyboardEvent("keydown", {
    //           key: "Escape",
    //         })
    //       );
    //     };

    //     const popoverContent = [
    //       <li
    //         key="dosomething"
    //         onClick={() => {
    //           closePopover();
    //         }}
    //       >
    //         DoSomething
    //       </li>,
    //       <li
    //         key="doanotherthing"
    //         onClick={() => {
    //           closePopover();
    //         }}
    //       >
    //         DoAnotherThing
    //       </li>,
    //     ];

    //     return (
    //       <Popover>
    //         <PopoverTrigger>
    //           <FontAwesomeIcon icon={faEllipsisVertical} />
    //         </PopoverTrigger>
    //         <PopoverContent>
    //           <ul>{popoverContent}</ul>
    //         </PopoverContent>
    //       </Popover>
    //     );
    //   },
    // },
  ] as ColDef[];
  return (
    <DataGrid
      data={selectedFolderContents}
      columnDefs={columnDefs}
      navigateWrapper={(navigate: NavigateFunction, data: any) => {
        // TODO: populate this once we have story for rendering specified document
      }}
      className="nmrk-folder-contents-view-height px-0"
      dispatchWrapper={(dispatch: AppDispatch, data: any) => {
        if (data.isFolder) {
          dispatch({
            type: "selectedFolder/select",
            payload: data,
          });
          dispatch({
            type: "folderListExpandFolder/expandFolder",
            payload: data.parentId,
          });
        } else {
          // TODO: populate this once we have story for rendering specified document
        }
      }}
      doubleClickWrapper={(data: any) => {}}
    />
  );
};

export default FolderContentsView;
