import "./badge.scss";

function Badge(props: { children: string; color: string }) {
  return (
    <span className={`nmrk-badge nmrk-badge-${props.color}`}>
      {props.children}
    </span>
  );
}
export default Badge;
