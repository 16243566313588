import { NavigateFunction, useNavigate } from "react-router-dom";
import { AgGridReact } from "ag-grid-react";
import {
  CellClickedEvent,
  ColDef,
  GetRowIdParams,
  RowClassParams,
} from "ag-grid-community"; // Import ColDef if you want to provide type information for columnDefs
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { useRef, useState, useEffect } from "react";

import {
  Container,
  Row,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";

import "./grid.scss";
import { AppDispatch } from "store";
import Loading from "./loading";
import { useAppDispatch } from "hooks";

// Types
import { DataRoom } from "dto/dataRoom";
import { Assignment } from "dto/assignment";
import { RequestedItemTableRow } from "components/main/valuations/dataRoom/requestedItems";
import FolderData from "./types/folderData";

type DataType = DataRoom | Assignment | RequestedItemTableRow | FolderData;
type GenericProps<T extends React.HTMLAttributes<HTMLElement>> = T;

function PaginationControls({
  data,
  rowType,
  currentVisible,
  total,
  currentPage,
  lastVisible,
  totalPages,
  skipToPage,
  setItemsPerPage,
}: {
  data: DataType[];
  rowType: string;
  currentVisible: number;
  total: number;
  currentPage: number;
  lastVisible: number;
  totalPages: number;
  skipToPage: (page: number) => void;
  setItemsPerPage: (itemsPerPage: number) => void;
}) {
  const canShowPrevious = currentPage > 1;
  const canShowNext = currentPage < totalPages;

  const numbers = [];
  for (var i = 1; i < totalPages + 1; i++) {
    numbers.push(i);
  }
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const itemsPerPage = [5, 10, 20, 50, 100];
  const [itemsPerPageSelected, setItemsPerPageSelected] = useState(
    itemsPerPage[1]
  );
  const [enabledItemsPerPage, setEnabledItemsPerPage] = useState(
    localStorage.FEATURE_TOGGLE_enableItemsPerPage
  );
  useEffect(() => {
    setEnabledItemsPerPage(localStorage.FEATURE_TOGGLE_enableItemsPerPage);
  }, [localStorage.FEATURE_TOGGLE_enableItemsPerPage]);

  return (
    <Row className="nmrk-grid-pagination-options">
      <Col sm={4}>
        <Row>
          <span className="nmrk-grid-showing-label">
            Showing {currentVisible} - {lastVisible} / {total} {rowType}
          </span>
        </Row>
        {enabledItemsPerPage && (
          <Row>
            <Col>
              <span>Items per page: </span>

              <Dropdown
                className="nmrk-white-dropdown"
                isOpen={dropdownOpen}
                toggle={toggle}
              >
                <DropdownToggle className="" caret>
                  {itemsPerPageSelected}
                </DropdownToggle>
                <DropdownMenu className="nmrk-dropdown-no-border-radius">
                  {itemsPerPage.map((i) => {
                    return (
                      <DropdownItem
                        key={i}
                        onClick={() => {
                          setItemsPerPageSelected(i);
                          setItemsPerPage(i);
                        }}
                      >
                        {i}
                      </DropdownItem>
                    );
                  })}
                </DropdownMenu>
              </Dropdown>
            </Col>
          </Row>
        )}
      </Col>
      <Col sm={5}></Col>
      <Col sm={3} className="text-end">
        {canShowPrevious && (
          <span
            className="grid-selectable-pagination-index"
            onClick={() => {
              const oneIndexedMinusTwo = currentPage - 2;
              skipToPage(oneIndexedMinusTwo);
            }}
          >
            {"<"}
          </span>
        )}
        {numbers.map((i) => {
          return (
            <span
              key={i}
              className={
                i === currentPage
                  ? "grid-selected-pagination-index"
                  : "grid-selectable-pagination-index"
              }
              onClick={() => {
                if (i !== currentPage) {
                  skipToPage(i - 1);
                }
              }}
            >
              {i}
            </span>
          );
        })}
        {canShowNext ? (
          <span
            className="grid-selectable-pagination-index"
            onClick={() => {
              const oneIndexed = currentPage;
              skipToPage(oneIndexed);
            }}
          >
            {">"}
          </span>
        ) : (
          <span className="grid-selectable-pagination-index">&nbsp;&nbsp;</span>
        )}
      </Col>
    </Row>
  );
}
type DataGridProps<T extends React.HTMLAttributes<HTMLElement>> = {
  data: DataType[];
  columnDefs: ColDef[];
  getRowId?: (params: GetRowIdParams<any>) => string; // Prevents flickering on data change
  getRowClass?: (params: RowClassParams<any>) => string | string[] | undefined;
  dispatchWrapper: (dispatch: AppDispatch, data: DataType) => void;
  navigateWrapper: (navigate: NavigateFunction, data: DataType) => void;
  doubleClickWrapper?: (data: DataType) => void;
} & GenericProps<T>;

export default function DataGrid<T extends React.HTMLAttributes<HTMLElement>>({
  data,
  columnDefs,
  getRowId,
  getRowClass,
  dispatchWrapper,
  navigateWrapper,
  doubleClickWrapper,
  ...rest
}: DataGridProps<T>) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const gridRef = useRef<AgGridReact>(null);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [total, setTotal] = useState(-1);
  const [firstDisplayedRow, setFirstDisplayedRow] = useState(
    gridRef.current?.api?.getFirstDisplayedRow() || 0
  );
  const [lastVisible, setLastVisible] = useState(0);

  return (
    <Container fluid className="h-100 p-0" {...rest}>
      {loading ? (
        <Loading></Loading>
      ) : (
        <PaginationControls
          data={data}
          rowType="items"
          currentVisible={firstDisplayedRow}
          lastVisible={lastVisible}
          setItemsPerPage={setItemsPerPage}
          total={total}
          currentPage={currentPage}
          totalPages={gridRef.current?.api?.paginationGetTotalPages() || 1}
          skipToPage={(page) => {
            gridRef.current!.api.paginationGoToPage(page);
          }}
        ></PaginationControls>
      )}
      <Row className="h-100 d-flex align-self-stretch">
        <div className="ag-theme-custom-react grid-container">
          <div className="nmrk-grid">
            <AgGridReact
              ref={gridRef}
              onGridReady={(params) => {
                setLoading(false);
              }}
              onRowDataUpdated={(grid) => {
                const tot = grid.api?.getModel().getRowCount();
                const firstVis = grid.api?.getFirstDisplayedRow();
                if (tot > 0) {
                  setFirstDisplayedRow(firstVis + 1);
                } else {
                  setFirstDisplayedRow(0);
                }
                setTotal(tot);
                const lastVis = grid.api?.getLastDisplayedRow();
                if (tot > 0) {
                  setLastVisible(lastVis + 1);
                } else {
                  setLastVisible(0);
                }
              }}
              onPaginationChanged={(params) => {
                setCurrentPage(params.api.paginationGetCurrentPage() + 1);
              }}
              suppressPaginationPanel={true}
              pagination={true}
              paginationPageSize={itemsPerPage}
              rowData={data}
              columnDefs={columnDefs}
              getRowClass={getRowClass}
              gridOptions={{
                defaultColDef: {
                  resizable: false,
                },
                suppressScrollOnNewData: true,
                getRowId,
                tooltipShowDelay: 1200,
                tooltipHideDelay: 2000,
              }}
              onCellDoubleClicked={(params: any) => {
                if (!doubleClickWrapper) {
                  return;
                }
                doubleClickWrapper(params.data);
              }}
              onCellClicked={(params: CellClickedEvent) => {
                if (params.colDef.field === "triple dots") {
                  return;
                }
                dispatchWrapper(dispatch, params.data);
                navigateWrapper(navigate, params.data);
              }}
            />
          </div>
        </div>
      </Row>
    </Container>
  );
}
