import { useState } from "react";
import { FileError, FileWithPath } from "react-dropzone";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { Buffer } from "buffer";
import { Container, Row, Col } from "reactstrap";

import "./uploadPanel.scss";
import SIDE_PANEL_SIDE from "enums/sidePanelSide";
import DragAndDropZone from "components/shared/dragAndDropZone";
import FloatingSidePanel from "components/shared/floatingSidePanel";
import { MimeType } from "dto/mimeType";
import { createDocument } from "components/api/documentData";
import { useAppDispatch, useAppSelector } from "hooks";
import { selectIdentity } from "slices/user/identitySlice";
import { selectAssignmentSelected } from "slices/assignment/assignmentSelectedSlice";
import { FileToUpload } from "dto/fileWithUploadStatus";
import UPLOAD_STATUS from "enums/uploadStatus";
import { RequestItem } from "dto/requestItem";
import { selectValuationsSystemInfo } from "slices/assignment/valuationsSystemInfoSlice";
import { Document } from "dto/document";
import { RequestItemsDetailsState } from "slices/assignment/requestItemsDetailsSlice";
import Badge from "components/shared/badge/badge";
import { MatomoActions, MatomoEventCategories, trackEvent } from "matomo";

export default function UploadPanel({
  acceptedFileTypes,
  requestItem = {} as RequestItem,
  closePanel,
}: {
  acceptedFileTypes: MimeType[];
  requestItem?: RequestItem;
  closePanel: () => void;
}) {
  const identity = useAppSelector(selectIdentity);
  const dispatch = useAppDispatch();
  const maxUploadFileSize = useAppSelector(
    selectValuationsSystemInfo
  )?.maxUploadFileSize;
  const assignmentData = useAppSelector(selectAssignmentSelected);
  const [filesToUpload, setFilesToUpload] = useState<FileToUpload[]>([]);
  const [isUploadButtonDisabled, setIsUploadButtonDisabled] = useState(false);

  function bytesToMB(bytes: number) {
    // Rounding to one decimal
    return Math.floor((bytes / 1024 / 1024) * 10) / 10;
  }

  function fileUploadValidator(file: FileWithPath) {
    if (maxUploadFileSize === null) {
      return null;
    }
    if (file.size > maxUploadFileSize) {
      const displayableFileSize =
        bytesToMB(file.size) === 0
          ? `${Math.floor((file.size / 1024) * 10) / 10} KB` // Rounding to one decimal
          : `${bytesToMB(file.size)} MB`;
      const errorMessage = `File (${displayableFileSize}) 
        is larger than max file size of ${bytesToMB(maxUploadFileSize)} MB`;
      toast.error(errorMessage);
      return {
        code: "file-too-large",
        message: errorMessage,
      } as FileError;
    }
    return null;
  }

  function updateFileStatus(file: FileToUpload, status: UPLOAD_STATUS) {
    setFilesToUpload(
      filesToUpload.map((f) => {
        if (f.name === file.name) {
          f.uploadStatus = status;
        }
        return f;
      })
    );
  }
  function updateFileProgress(file: FileToUpload, progress: number) {
    setFilesToUpload(
      filesToUpload.map((f) => {
        if (f.name === file.name) {
          const progressPercent = progress;

          f.uploadProgress = progressPercent * 100;
        }
        return f;
      })
    );
  }

  const { requestItemTypeDescription, id } = requestItem;
  const { propertyName, jobAssignmentPropertyId } = assignmentData;
  const classByFileStatus = {
    [UPLOAD_STATUS.PENDING]: "nmrk-file-upload-pending",
    [UPLOAD_STATUS.PROCESSING]: "nmrk-file-upload-processing",
    [UPLOAD_STATUS.DONE]: "nmrk-file-upload-done",
    [UPLOAD_STATUS.FAILED]: "nmrk-file-upload-failed",
  };
  const onUploadClick = () => {
    setIsUploadButtonDisabled(true);
    const postUploadProcess = () => {
      // Check if all files are done
      const areAllFilesCompleted = filesToUpload
        .map(
          (f) =>
            f.uploadStatus === UPLOAD_STATUS.DONE ||
            f.uploadStatus === UPLOAD_STATUS.FAILED
        )
        .every(Boolean);
      if (areAllFilesCompleted) {
        setIsUploadButtonDisabled(false);
        const countAllSuccessful = filesToUpload.filter(
          (f) => f.uploadStatus === UPLOAD_STATUS.DONE
        ).length;
        if (countAllSuccessful > 0) {
          toast.success(`${countAllSuccessful} document(s) uploaded`);
          trackEvent(
            MatomoEventCategories.RequestItem,
            MatomoActions.Upload,
            `${requestItem.id}`
          );
        }
      }
    };
    filesToUpload.forEach((file: FileToUpload) => {
      if (file.uploadStatus === UPLOAD_STATUS.DONE) {
        return;
      }

      const reader = new FileReader();
      reader.onload = () => {
        const binary = reader.result as ArrayBuffer;
        const base64String = Buffer.from(binary).toString("base64");

        const data = {
          file: base64String,
          fileName: file.name,
          fileExtension: file.name.split(".")[file.name.split(".").length - 1],
          jobAssignmentPropertyId,
          id,
        };

        updateFileStatus(file, UPLOAD_STATUS.PROCESSING);
        createDocument(identity, data, (event: { progress: number }) => {
          updateFileProgress(file, event.progress);
        })
          .then((response: Document) => {
            updateFileStatus(file, UPLOAD_STATUS.DONE);
            dispatch({
              type: "requestItemsDetails/appendRequestItemFile",
              payload: {
                [jobAssignmentPropertyId]: {
                  documentsDetail: [response],
                  requestedItems: [{ id, documentIds: [response.id] }],
                },
              } as RequestItemsDetailsState,
            });
            postUploadProcess();
          })
          .catch((e) => {
            console.error(e);
            updateFileStatus(file, UPLOAD_STATUS.FAILED);
            postUploadProcess();
            toast.error(`Failed to upload ${file.name}`);
          });
      };
      reader.readAsArrayBuffer(file);
    });
  };
  const fileTypes = acceptedFileTypes.map(({ fileExtension }: MimeType, i) => {
    return fileExtension + (i === acceptedFileTypes.length - 1 ? "" : ", ");
  });
  return (
    <FloatingSidePanel closePanel={closePanel} side={SIDE_PANEL_SIDE.RIGHT}>
      <span className="button-container">
        <span>
          <button className="nmrk-sidepanel-close" onClick={closePanel}>
            X
          </button>
          <h3 className="nmrk-sidepanel-header capitalize">Upload Files</h3>
          <div className="nmrk-sidepanel-subtext">{propertyName}</div>
          <Badge color="black">{requestItemTypeDescription}</Badge>
          <hr className="nmrk-sidepanel-horizontal-line-title" />

          <br />
          <DragAndDropZone
            accept={acceptedFileTypes.reduce((acc, fileType: MimeType) => {
              const existingExtensions =
                (acc as any)[fileType.description] || [];
              return {
                ...acc,
                [fileType.description]: [
                  ...existingExtensions,
                  `.${fileType.fileExtension}`,
                ],
              };
            }, {})}
            validator={fileUploadValidator}
            files={filesToUpload}
            setFiles={setFilesToUpload}
          >
            <Container className=" allcaps">
              <Row className="justify-content-center">
                <Col>
                  <div
                    style={{ width: "100%" }}
                    className="nmrk-file-upload-bold"
                  >
                    Supported formats:
                  </div>
                </Col>
                <Col> {fileTypes}</Col>
              </Row>
              <Row>
                <Col className="nmrk-file-upload-bold">MAX. FILE SIZE:</Col>
                <Col>
                  {bytesToMB(maxUploadFileSize)}
                  {" MB"}
                </Col>
              </Row>
            </Container>
          </DragAndDropZone>
          <Container>
            <Row className="justify-content-between d-flex">
              {filesToUpload.map((file, index) => {
                return (
                  <Col
                    xs={6}
                    key={file.name}
                    className="nmrk-file-upload flex-grow-1"
                  >
                    <Row className="match-parent-height">
                      <Col
                        xs={index % 2 === 0 ? 11 : 12}
                        className={`${classByFileStatus[file.uploadStatus!]}`}
                      >
                        {(file.uploadStatus === UPLOAD_STATUS.PROCESSING ||
                          file.uploadStatus === UPLOAD_STATUS.DONE) && (
                          <Row>
                            <progress
                              className="nmrk-file-upload-processing-progress-bar"
                              value={file.uploadProgress}
                              max="100"
                            ></progress>
                          </Row>
                        )}
                        <Row className="match-parent-height align-items-center ">
                          <Col xs={10}>{file.name}</Col>
                          {file.uploadStatus !== UPLOAD_STATUS.DONE &&
                            file.uploadStatus !== UPLOAD_STATUS.PROCESSING && (
                              <Col xs={1}>
                                <div
                                  className="nmrk-clickable nmrk-icon-trashcan"
                                  onClick={() =>
                                    setFilesToUpload(
                                      filesToUpload.filter(
                                        (fileToUpload) =>
                                          fileToUpload.name !== file.name
                                      )
                                    )
                                  }
                                ></div>
                              </Col>
                            )}
                        </Row>
                      </Col>

                      {index % 2 === 0 && <Col xs={1}></Col>}
                    </Row>
                    {file.uploadStatus === UPLOAD_STATUS.FAILED && (
                      <div className="nmrk-file-upload-failure-notice">
                        <FontAwesomeIcon icon={faTriangleExclamation} />
                        <span> Failed to upload!</span>
                      </div>
                    )}
                  </Col>
                );
              })}
              {filesToUpload.length % 2 === 1 && <Col xs={6}></Col>}
            </Row>
          </Container>
        </span>
        <span className="button-holder">
          <button onClick={closePanel} className="cancel-span">
            Cancel
          </button>
          <button
            disabled={
              !filesToUpload ||
              filesToUpload.length === 0 ||
              isUploadButtonDisabled
            }
            onClick={onUploadClick}
            className="upload-button"
          >
            Upload
          </button>
        </span>
      </span>
    </FloatingSidePanel>
  );
}
