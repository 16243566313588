import React, { useState, useRef, MouseEvent, FocusEvent } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import "./typeahead.scss"
interface Option {
  label: string;
  value: any;
}

interface TypeaheadProps {
  options: Option[];
  onSelect: (selectedOption: Option | null) => void;
}

const Typeahead: React.FC<TypeaheadProps> = ({ options, onSelect }) => {
  const MINIMUM_CHARACTERS_TO_SEARCH = 2;
  const [inputValue, setInputValue] = useState<string>("");
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const selectRef = useRef<HTMLInputElement | null>(null);
  const typeaheadOptionsRef = useRef<HTMLUListElement | null>(null);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setInputValue(value);
    setShowOptions(value.length >= MINIMUM_CHARACTERS_TO_SEARCH);
  };

  const handleOptionSelect = (option: Option) => {
    setInputValue(option.label);
    setShowOptions(false);
    onSelect(option);
  };
  const [mousePosition, setMousePosition] = useState({
    left: 0,
    top: 0,
  });
  const handleInputBlur = (e: FocusEvent) => {
    if (!typeaheadOptionsRef.current) return;

    const optionsBoundingRectangle =
      typeaheadOptionsRef.current.getBoundingClientRect();
    const isMouseClickInsideBounds =
      optionsBoundingRectangle.x <= mousePosition.left &&
      optionsBoundingRectangle.x + optionsBoundingRectangle.width >=
        mousePosition.left &&
      optionsBoundingRectangle.y <= mousePosition.top &&
      optionsBoundingRectangle.y + optionsBoundingRectangle.height >=
        mousePosition.top;

    // Hide options on blur
    if (!isMouseClickInsideBounds) {
      setShowOptions(false);
    }
  };

  const handleClear = () => {
    setInputValue("");
    onSelect(null);
    setShowOptions(false);
    selectRef.current?.focus();
  };

  function handleMouseMove(ev: MouseEvent) {
    setMousePosition({ left: ev.pageX, top: ev.pageY });
  }

  const filteredOptions = options.filter((option) =>
    option.label.toLowerCase().includes(inputValue.toLowerCase())
  );

  return (
    <div
      className="typeahead-container"
      onMouseMove={(ev: MouseEvent) => handleMouseMove(ev)}
    >
      <input
        placeholder="Search data rooms"
        ref={selectRef}
        type="text"
        value={inputValue}
        onChange={handleChange}
        className="typeahead-input"
        onBlur={handleInputBlur}
      />
      {inputValue && (
        <FontAwesomeIcon
          icon={faClose}
          className="typeahead-clear"
          onClick={handleClear}
        />
      )}
      {showOptions && (
        <ul className="typeahead-options" ref={typeaheadOptionsRef}>
          {filteredOptions.map((option, i) => (
            <li
              key={`option.label${i}`}
              onClick={() => handleOptionSelect(option)}
            >
              {option.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Typeahead;
