import { MatomoActions, MatomoEventCategories, trackEvent } from "matomo";
import { AppDispatch } from "store";

export function logoutAndClearRedux(dispatch: AppDispatch): void {
  dispatch({
    type: "identity/logout",
  });
  dispatch({
    type: "user/clearUser",
  });
  dispatch({
    type: "access/clearAccess",
  });
  dispatch({
    type: "dataRoomList/clearDataRoomList",
  });
  dispatch({
    type: "dataRoomSelected/clearDataRoomSelected",
  });
  dispatch({
    type: "assignmentList/clearAssignmentList",
  });
  dispatch({
    type: "assignmentSelected/clearAssignmentSelected",
  });
  dispatch({
    type: "requestItemList/clearRequestItemList",
  });
  trackEvent(MatomoEventCategories.Login, MatomoActions.Logout);
}
