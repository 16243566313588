import { Identity } from "dto/identity";
import { AxiosResponse } from "axios";

import { makeHttpCall } from "./apiHelper";
import DATA_OPERATIONS from "enums/dataOperations";
import { RequestItemsDetails } from "dto/requestItemsDetails";
import DATA_PROVIDERS from "enums/dataProviders";
import { requestItemsDetailsData as tempRequestItemsDetailsData } from "debug/requestItemsDetailsData";
import { toast } from "react-toastify";

function _getSampleData() {
  toast.warn(
    "Failed to fetch valuations request items. Using temp data (some functionalities will not work)"
  );
  return tempRequestItemsDetailsData;
}

async function _performDataOp(
  identity: Identity,
  dataOp: DATA_OPERATIONS,
  { jobAssignmentPropertyId }: { jobAssignmentPropertyId?: number }
): Promise<any> {
  let endpoint: string = process.env.REACT_APP_APIM_DOCUMENT_SEARCH!;
  switch (dataOp) {
    case DATA_OPERATIONS.READ:
      endpoint += `?jobAssignmentPropertyId=${encodeURIComponent(
        jobAssignmentPropertyId!
      )}`;
      break;
  }

  let response: AxiosResponse;
  try {
    response = await makeHttpCall(identity, dataOp, endpoint, {
      dataProvider: DATA_PROVIDERS.VALUATIONS,
    });
  } catch (e) {
    if (process.env.REACT_APP_IS_LOCAL) {
      return _getSampleData();
    } else {
      throw e;
    }
  }
  if (process.env.REACT_APP_IS_LOCAL && !response.data) {
    return _getSampleData();
  }

  if (response?.status < 300) {
    return response.data as RequestItemsDetails;
  }
  return [];
}

export async function getDocuments(
  identity: Identity,
  jobAssignmentPropertyId: number
): Promise<RequestItemsDetails> {
  return await _performDataOp(identity, DATA_OPERATIONS.READ, {
    jobAssignmentPropertyId,
  });
}
