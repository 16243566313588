import { useNavigate } from "react-router-dom";

import Typeahead from "components/shared/typeahead/typeahead";
import { useAppDispatch } from "hooks";
import { DataRoom } from "dto/dataRoom";
import DATA_PROVIDERS from "enums/dataProviders";

export default function DataRoomTypeahead(props: { dataRooms: DataRoom[], dataProvider: DATA_PROVIDERS }) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  return (
    <Typeahead
      options={props.dataRooms.map(function (dr: DataRoom) {
        return { label: dr.name, value: dr };
      })}
      onSelect={(option) => {
        if (!option) {
          return;
        }
        dispatch({
          type: "dataRoomSelected/select",
          payload: option!.value,
        });
        navigate(`${props.dataProvider.toLowerCase()}/dataRooms/${option!.value.id}/Documents`);
      }}
    ></Typeahead>
  );
}
