// Slice for user data
// Slices are combined actions and reducers
// more at https://react-redux.js.org/tutorials/typescript-quick-start#define-slice-state-and-action-types

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from 'store'
import { User } from 'dto/user'

interface UserState {
  user: User;
}

const initialState: UserState = {
  user: {} as User,
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload;
    },
    clearUser: (state) => {
      state.user = {} as User;
    },
  },
});



export const { setUser, clearUser } = userSlice.actions

export const selectUser = (state: RootState): User => state.user.user;


export default userSlice.reducer;
