import React from "react";
import { useEffect } from "react";
import ReactDOM from "react-dom/client";
import "index.scss";
import App from "App";
// import reportWebVitals from './reportWebVitals';
import "bootstrap/dist/css/bootstrap.css";
import { PersistGate } from "redux-persist/integration/react";

// Redux
import { Provider } from "react-redux";
import { store, persistor } from "./store";
import Loading from "components/shared/loading";

// Common Terms / Links;
import { appInfo } from "constants/termsLinks";
const NM_APP_NAME = appInfo.NM_APP_NAME;
const NM_APP_DESC = appInfo.NM_APP_DESCRIPTION;
const NEWMARK_NAME = appInfo.NEWMARK_NAME;

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);

// Adjust for IOS Safari Viewport Scaling bug;
const adjustViewportForIOS = () => {
	/**
	 * 	Address IOS bug causing Safari to zoom viewport when keyboard
	 * is displayed without restoring original zoom level after 
	 * keyboard is hidden.
	 * 
	 * This leaves maximum-scale at 5 for non-IOS. Ugly, but does not
	 * impact IOS accessibility tools, e.g., pinch/zoom scaling and 
	 * IOS font-sizing functionality. 
	*/ 

	// Check User Agent if IOS;
	if (navigator.userAgent.indexOf("iPhone") > -1) {
		// Store reference to viewport meta tag;
		const viewport = document.querySelector("[name=viewport]");
		if (viewport) {
			// Update the attributes on the meta tag specific to IOS;
			viewport.setAttribute(
				"content",
				"width=device-width, initial-scale=1, maximum-scale=1"
			);
		}
	}
};

const updateMetaTagsFromDictionary = () => {
	// Update the page title with the offical app name;
	const docTitle = document.title;
	if (docTitle) {
		document.title = NEWMARK_NAME + " " + NM_APP_NAME;		
	}

	// Update the page description with the official app name;
	const desc = document.querySelector("[name=description]");
	if (desc) {
		// Update the attributes on the meta tag specific to IOS;
		desc.setAttribute(
			"content",
			NM_APP_DESC
		);
	}

}

// Make adjustViewportForIOS call;
const RootComponent = () => {
	useEffect(() => {
		adjustViewportForIOS();
		updateMetaTagsFromDictionary();
	}, []);

	return (
		<Provider store={store}>
			<PersistGate loading={<Loading></Loading>} persistor={persistor}>
				<App />
			</PersistGate>
		</Provider>
	);
};

root.render(<RootComponent />);

/** 
 * If you want to start measuring performance in your app, pass a 
 * function to log results (for example: reportWebVitals(console.log))
 * or send to an analytics endpoint. 
 * 
 * Learn more: https://bit.ly/CRA-vitals
 */
// reportWebVitals();
