import { AxiosResponse } from "axios";

import { makeHttpCall } from "./apiHelper";
import DATA_OPERATIONS from "enums/dataOperations";
import { Identity } from "dto/identity";
import DATA_PROVIDERS from "enums/dataProviders";
import { DataRoom } from "dto/dataRoom";
import { Assignment } from "dto/assignment";
import { assignmentListData as tempAssignmentListData } from "debug/assignmentData";
import { toast } from "react-toastify";

function _getSampleData() {
  toast.warn(
    "Failed to fetch valuations assignments. Using temp data (some functionalities will not work)"
  );
  return tempAssignmentListData;
}

async function _getAllDataRoomData(
  identity: Identity,
  dataProvider: DATA_PROVIDERS
): Promise<any[]> {
  let response: AxiosResponse;
  try {
    response = await makeHttpCall(
      identity,
      DATA_OPERATIONS.READ,
      `${process.env.REACT_APP_APIM_DATAROOM_SEARCH}?searchTerm=`,
      { dataProvider }
    );
  } catch (e) {
    if (process.env.REACT_APP_IS_LOCAL) {
      return _getSampleData();
    } else {
      throw e;
    }
  }
  if (
    process.env.REACT_APP_IS_LOCAL &&
    (!response.data || response.data?.length === 0)
  ) {
    return _getSampleData();
  }

  // Return the DataRoom object
  if (response?.status < 300) {
    const responseData =
      (dataProvider === DATA_PROVIDERS.VALUATIONS
        ? response.data
        : response.data?.[0]) || [];
    return responseData?.map((dataRoom: any) => {
      switch (dataProvider) {
        case DATA_PROVIDERS.VALUATIONS:
          return dataRoom as Assignment;
        case DATA_PROVIDERS.NATIVE:
        default:
          return {
            id: dataRoom.id,
            name: dataRoom.name,
            archivedDate: dataRoom.archivedDate,
            createdDate: dataRoom.createdDate,
            createdBy: dataRoom.createdBy,
            userRoleInCurrentDataRoom: dataRoom.roleName,
          } as DataRoom;
      }
    });
  }
  return [];
}

export async function getAllDataRoomData(
  identity: Identity
): Promise<DataRoom[]> {
  return await _getAllDataRoomData(identity, DATA_PROVIDERS.NATIVE);
}

export async function getAllAssignmentData(
  identity: Identity
): Promise<Assignment[]> {
  return await _getAllDataRoomData(identity, DATA_PROVIDERS.VALUATIONS);
}
