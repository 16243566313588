import { Container, Row, Col } from "reactstrap";
import {
  ColDef,
  ICellRendererParams,
  ValueFormatterParams,
} from "ag-grid-community";

import "./index.scss";
import Loading from "components/shared/loading";

import { useAppSelector } from "hooks";
import { selectUser } from "slices/user/userSlice";
import DATA_PROVIDERS from "enums/dataProviders";
import { selectAssignmentList } from "slices/assignment/assignmentListSlice";
import { Assignment } from "dto/assignment";
import DataGrid from "components/shared/grid";
import { AppDispatch } from "store";
import { NavigateFunction } from "react-router-dom";

type GenericProps<T extends React.HTMLAttributes<HTMLElement>> = T;

export default function ValuationsDataRoomListComponent<
  T extends React.HTMLAttributes<HTMLElement>
>(props: GenericProps<T>) {
  const { ...rest } = props;
  const user = useAppSelector(selectUser);
  const userId: string = user.id;
  const listOfAssignments: Assignment[] = useAppSelector(selectAssignmentList);
  const columnDefs: ColDef[] = [
    {
      field: "jobAssignmentNumber",
      headerName: "Assignment",
      minWidth: 200,
      flex: 1,
      sortable: true,
      wrapText: true,
      autoHeight: true,
      cellRenderer: (params: ICellRendererParams<Assignment>) => {
        const { jobAssignmentNumber, jobAssignmentClientReference } =
          params.data!;
        return (
          <div className="light-text" style={{ lineHeight: 2 }}>
            {jobAssignmentNumber}
            {jobAssignmentClientReference && (
              <>
                <br />
                Client Ref: {jobAssignmentClientReference}
              </>
            )}
          </div>
        );
      },
    },
    {
      field: "propertyName",
      headerName: "Property",
      flex: 5,
      sortable: true,
      autoHeight: true,
      cellRenderer: (params: ICellRendererParams<Assignment>) => {
        const {
          propertyName,
          propertyAddress1,
          propertyCity,
          propertyState,
          propertyZip,
          propertyTypeDescription,
        } = params.data!;
        return (
          <div style={{ lineHeight: 2 }}>
            {propertyName}
            <div className="light-text">{`${propertyAddress1}, ${propertyCity}, ${propertyState} ${propertyZip}`}</div>
            <div className="light-text">{propertyTypeDescription}</div>
          </div>
        );
      },
    },
    // {
    //   field: "jobAssignmentDueDate",
    //   headerName: "Upload By",
    //   minWidth: 200,
    //   flex: 1,
    //   sortable: true,
    //   sort: "asc",
    //   cellRenderer: (params: ICellRendererParams<Assignment>) => {
    //     const { jobAssignmentDueDate } = params.data!;
    //     return <div>{new Date(jobAssignmentDueDate).toLocaleDateString()}</div>;
    //   },
    // },
    {
      field: "jobAssignmentLeadAppraisers",
      headerName: "Appraiser",
      flex: 2,
      sortable: true,
      autoHeight: true,
      valueFormatter: (params: ValueFormatterParams) => {
        const {
          data: { jobAssignmentLeadAppraisers: appraisers },
        } = params;
        if (appraisers.length === 1) {
          return appraisers[0];
        } else if (appraisers.length > 1) {
          return `${appraisers[0]} +${appraisers.length - 1}`;
        }
        return "";
      },
      cellRenderer: (params: ICellRendererParams<Assignment>) => {
        const { jobAssignmentLeadAppraisers } = params.data!;
        return (
          <div style={{ lineHeight: 2 }}>
            {jobAssignmentLeadAppraisers.map((appraiser) => (
              <div className="light-text">
                {appraiser}
                <br />
              </div>
            ))}
          </div>
        );
      },
    },
  ];

  return (
    <>
      {!userId ? (
        <Loading withoutHeader={true} />
      ) : (
        <div {...rest}>
          <Container
            fluid
            id="valuations-data-room-list"
            className="valuations-data-room-list-parent h-100"
          >
            <Row>
              <h2 className="valuations-data-room-list-header">Valuations</h2>
            </Row>
            <Row className="valuations-data-room-list-data">
              <DataGrid
                data={listOfAssignments}
                columnDefs={columnDefs}
                navigateWrapper={(navigate: NavigateFunction, data: any) => {
                  navigate(
                    `${DATA_PROVIDERS.VALUATIONS.toLowerCase()}/dataRooms/${
                      data.jobAssignmentPropertyId
                    }`
                  );
                }}
                dispatchWrapper={(dispatch: AppDispatch, data: any) => {
                  dispatch({
                    type: "assignmentSelected/select",
                    payload: data,
                  });
                }}
              />
            </Row>
          </Container>
        </div>
      )}
    </>
  );
}
