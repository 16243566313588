import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

import "./loading.scss";

export default function Loading({
  withoutHeader = false,
}: {
  withoutHeader?: boolean;
}) {
  return (
    <div
      className={withoutHeader ? "loading-parent-without-header" : "loading-parent"}
    >
      <FontAwesomeIcon icon={faSpinner} size="3x" spin />
    </div>
  );
}
