import { Identity } from "dto/identity";

import { DataRoom } from "dto/dataRoom";
import DATA_OPERATIONS from "enums/dataOperations";
import { makeHttpCall } from "./apiHelper";
import { User } from "dto/user";

async function _performDataOp(
  identity: Identity,
  user: User,
  dataOp: DATA_OPERATIONS,
  {
    dataRoomId,
    dataRoomName,
  }: { dataRoomId?: string; dataRoomName?: string } = {}
): Promise<DataRoom | undefined> {

  let endpoint: string = process.env.REACT_APP_APIM_DATAROOM!, data: any;
  switch (dataOp) {
    case DATA_OPERATIONS.READ:
      endpoint += `?dataroomId=${encodeURIComponent(dataRoomId!)}`
      break;
    case DATA_OPERATIONS.CREATE:
      data = { dataroomname: dataRoomName };
      break;
    case DATA_OPERATIONS.UPDATE:
      data = { dataRoomId, dataroomname: dataRoomName }
      break;
    case DATA_OPERATIONS.ARCHIVE:
      data = { dataRoomId };
      break;
  }

  const response = await makeHttpCall(identity, dataOp, endpoint, { data, numberOfTries: 3 });

  // Return the data room object
  if (response?.status < 300) {
    const { id, name, createdDate, archivedDate, createdBy, roleName, } = response.data?.[0]?.[0] || {};
    return {
      id,
      name,
      createdDate,
      archivedDate,
      createdBy,
      userRoleInCurrentDataRoom: roleName,
    } as DataRoom;
  }
  return {} as DataRoom;
}

export async function readDataRoomData(
  identity: Identity,
  user: User,
  dataRoomId: string
): Promise<DataRoom | undefined> {
  return await _performDataOp(identity, user, DATA_OPERATIONS.READ, {
    dataRoomId,
  });
}

export async function createDataRoomData(
  identity: Identity,
  user: User,
  dataRoomName: string
): Promise<DataRoom | undefined> {
  return await _performDataOp(identity, user, DATA_OPERATIONS.CREATE, {
    dataRoomName,
  });
}

// TODO: Implement this correctly
// export async function updateDataRoomData(
//   identity: Identity,
//   user: User,
//   dataRoomName: string
// ): Promise<DataRoom | undefined> {
//   return await _performDataRoomDataOp(identity, user, DATA_OPERATIONS.UPDATE, {
//     dataRoomName,
//   });
// }

export async function archiveDataRoomData(
  identity: Identity,
  user: User,
  dataRoomId: string
): Promise<DataRoom | undefined> {
  return await _performDataOp(identity, user, DATA_OPERATIONS.ARCHIVE, {
    dataRoomId,
  });
}
