import { Identity } from "dto/identity";
import { AxiosResponse } from "axios";

import { makeHttpCall } from "./apiHelper";
import DATA_OPERATIONS from "enums/dataOperations";
import ROLES from "enums/roles";
import { InviteSummary } from "dto/inviteSummary";

async function _performDataOp(
  identity: Identity,
  dataOp: DATA_OPERATIONS,
  { data, numberOfTries = 1 }: { data?: any, numberOfTries?: number } = {}
): Promise<InviteSummary> {
  const response: AxiosResponse = await makeHttpCall(
    identity,
    dataOp,
    process.env.REACT_APP_APIM_INVITE!,
    { data, numberOfTries }
  );
  if (response?.status < 300) {
    const { invitedUserEmail, inviteCreatedDate, inviteStatus, roleName, id, userId, dataRoomId } =
      response.data?.[0]?.[0] || {};
    return {
      email: invitedUserEmail,
      addedDate: inviteCreatedDate,
      status: inviteStatus,
      inviteeRoleInCurrentDataRoom: roleName,
      inviteId: id,
      userId,
      dataRoomId,
    } as InviteSummary;
  }
  return {} as InviteSummary;
}

export async function createInvite(
  identity: Identity,
  dataRoomId: string,
  inviteType: ROLES,
  email: string
): Promise<InviteSummary> {
  const data = {
    InviteEmail: email,
    DataroomId: dataRoomId,
    RoleName: inviteType,
    Operation: "CreateInvite"
  };
  return await _performDataOp(identity, DATA_OPERATIONS.CREATE, { data, numberOfTries: 3 });
}

export async function updateInvite(
  identity: Identity,
  inviteId: string,
  {
    inviteStatus,
    newRole,
  }: {
    inviteStatus?: string;
    newRole?: string;
  }
): Promise<boolean> {
  const data = {
    InviteId: inviteId,
    InviteStatus: inviteStatus,
    RoleName: newRole,
  };
  const response: InviteSummary = await _performDataOp(identity, DATA_OPERATIONS.UPDATE, { data });
  return Object.keys(response).length > 0;
}
