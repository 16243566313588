import { useEffect } from "react";
import { Container, Row, Col } from "reactstrap";

import { useAppDispatch, useAppSelector } from "hooks";
import HierarchicalView from "components/shared/HierarchicalView/HierarchicalView";
import FolderContentsView from "components/shared/FolderContentsView/FolderContentsView";
import FolderData from "components/shared/types/folderData";
import { selectFolderHierarchy } from "slices/document/folderHierarchySlice";

import "./documents.scss";

export default function Documents() {
  const folderHierarchyData: FolderData[] = [
    // todo select from store when available
    {
      id: 1,
      name: "Originations",
      isFolder: true,
      addedDate: "01/01/2024",
      childData: [
        {
          id: 2,
          name: "boilerplate.pdf",
          isFolder: false,
          addedDate: "01/01/2024",
        },
        {
          id: 3,
          name: "Architectural",
          isFolder: true,
          addedDate: "01/02/2024",
          childData: [
            {
              id: 4,
              name: "siteDrawings.cad",
              isFolder: false,
              addedDate: "01/03/2024",
            },
            {
              id: 5,
              name: "statusReports.pdf",
              isFolder: false,
              addedDate: "01/04/2024",
            },
          ],
        },
      ],
    },
    {
      id: 6,
      name: "Accounting",
      isFolder: true,
      addedDate: "01/06/2024",
      childData: [
        {
          id: 7,
          name: "spreadsheet.xlsx",
          isFolder: false,
          addedDate: "01/07/2024",
        },
      ],
    },
    {
      id: 8,
      name: "Human Resources",
      isFolder: true,
      addedDate: "01/03/2024",
      childData: [
        {
          id: 9,
          name: "Payroll",
          isFolder: true,
          addedDate: "01/04/2024",
          childData: [
            {
              id: 10,
              name: "accounts_payable.xlsx",
              isFolder: false,
              addedDate: "01/05/2024",
            },
            {
              id: 11,
              name: "Accounts Receivable",
              isFolder: true,
              addedDate: "01/11/2024",
              childData: [
                {
                  id: 12,
                  name: "mainCorp.xlsx",
                  isFolder: false,
                  addedDate: "01/12/2024",
                },
                {
                  id: 13,
                  name: "subCorps.xlsx",
                  isFolder: false,
                  addedDate: "01/13/2024",
                },
              ],
            },
          ],
        },
        {
          id: 14,
          name: "Timelines",
          isFolder: true,
          addedDate: "01/05/2024",
          childData: [
            {
              id: 15,
              name: "FinalTimeline.xlsx",
              isFolder: false,
              addedDate: "01/17/2024",
            },
            {
              id: 32,
              name: "FinalFinalTimeline_v2.xlsx",
              isFolder: false,
              addedDate: "01/16/2024",
            },
            {
              id: 33,
              name: "FinalTimeline.xlsx",
              isFolder: false,
              addedDate: "01/17/2024",
            },
            {
              id: 34,
              name: "FinalFinalTimeline_v2.xlsx",
              isFolder: false,
              addedDate: "01/16/2024",
            },
            {
              id: 35,
              name: "FinalTimeline.xlsx",
              isFolder: false,
              addedDate: "01/17/2024",
            },
            {
              id: 36,
              name: "FinalFinalTimeline_v2.xlsx",
              isFolder: false,
              addedDate: "01/16/2024",
            },
            {
              id: 37,
              name: "FinalTimeline.xlsx",
              isFolder: false,
              addedDate: "01/17/2024",
            },
            {
              id: 38,
              name: "FinalFinalTimeline_v2.xlsx",
              isFolder: false,
              addedDate: "01/16/2024",
            },
            {
              id: 39,
              name: "FinalTimeline.xlsx",
              isFolder: false,
              addedDate: "01/17/2024",
            },
            {
              id: 40,
              name: "FinalFinalTimeline_v2.xlsx",
              isFolder: false,
              addedDate: "01/16/2024",
            },
            {
              id: 41,
              name: "FinalTimeline.xlsx",
              isFolder: false,
              addedDate: "01/17/2024",
            },
            {
              id: 42,
              name: "FinalFinalTimeline_v2.xlsx",
              isFolder: false,
              addedDate: "01/16/2024",
            },
            {
              id: 43,
              name: "FinalTimeline.xlsx",
              isFolder: false,
              addedDate: "01/17/2024",
            },
            {
              id: 44,
              name: "FinalFinalTimeline_v2.xlsx",
              isFolder: false,
              addedDate: "01/16/2024",
            },
            {
              id: 45,
              name: "FinalTimeline.xlsx",
              isFolder: false,
              addedDate: "01/17/2024",
            },
            {
              id: 46,
              name: "FinalFinalTimeline_v2.xlsx",
              isFolder: false,
              addedDate: "01/16/2024",
            },
            {
              id: 47,
              name: "FinalTimeline.xlsx",
              isFolder: false,
              addedDate: "01/17/2024",
            },
            {
              id: 48,
              name: "FinalFinalTimeline_v2.xlsx",
              isFolder: false,
              addedDate: "01/16/2024",
            },
            {
              id: 49,
              name: "FinalTimeline.xlsx",
              isFolder: false,
              addedDate: "01/17/2024",
            },
            {
              id: 50,
              name: "FinalFinalTimeline_v2.xlsx",
              isFolder: false,
              addedDate: "01/16/2024",
            },
            {
              id: 51,
              name: "FinalTimeline.xlsx",
              isFolder: false,
              addedDate: "01/17/2024",
            },
            {
              id: 52,
              name: "FinalFinalTimeline_v2.xlsx",
              isFolder: false,
              addedDate: "01/16/2024",
            },
          ],
        },
      ],
    },
  ];

  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch({
      type: "folderHierarchy/load",
      payload: folderHierarchyData,
    });
  }, []); // TODO: replace this with a call to read the data from the server
  // the empty [] is intentional to load the data only once
  const selectedFolderHierarchy = useAppSelector(selectFolderHierarchy);

  return (
    <Container
      fluid
      className="documents-container-background documents-container-height d-flex"
    >
      <Row className="flex-fill">
        <Col
          className="background-hierarchy align-self-baseline border-end"
          xs={12}
          sm={12}
          md={12}
          lg={3}
          xl={4}
        >
          <HierarchicalView data={selectedFolderHierarchy}></HierarchicalView>
        </Col>

        <Col
          xs={12}
          sm={12}
          md={12}
          lg={9}
          xl={8}
          className="rest-of-height pt-sm-1 pt-xl-0 pt-lg-0 pt-md-1 pt-xs-1 h-100 align-self-stretch"
        >
          <FolderContentsView />
        </Col>
      </Row>
    </Container>
  );
}
