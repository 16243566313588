import React from 'react';
import { ReactNode } from 'react';
import { Row, Col } from 'reactstrap';
import { useNavigate } from 'react-router-dom';

// Header and Footer component styles;
import "./authHeaderFooter.scss";
import { appInfo } from "../../constants/termsLinks";

// Common Terms / Links;
const NM_APP_NAME = appInfo.NM_APP_NAME;
const NEWMARK_NAME = appInfo.NEWMARK_NAME; 
const NM_INITIAL_YEAR = appInfo.NM_INITIAL_YEAR;
const NM_RIGHTS_STATEMENT = appInfo.NM_RIGHTS_STATEMENT;
const NM_PRIVACY = appInfo.NM_PRIVACY;
const NM_NOTICES = appInfo.NM_NOTICES;
const NM_BUSINESS_CONTINUITY = appInfo.NM_BUSINESS_CONTINUITY; 
const NM_DISCLAIMER = appInfo.NM_DISCLAIMER;
const NM_TERMS = appInfo.NM_TERMS;
const NM_SELECT_PRIVACY = appInfo.NM_SELECT_PRIVACY; 

// Calculate current copyright year; if after first year, 
// return string with starting year and current year
// separated by an en dash: 2024–2025.
const currentCopyrightYear = (): ReactNode => {
	let currentYear = new Date().getFullYear();
	if (currentYear === NM_INITIAL_YEAR) {
		return currentYear as ReactNode;
	} else {
		return (
			<>
				{NM_INITIAL_YEAR}&#8211;{currentYear}
			</>
		);
	}
};

interface HeaderProps {
	pageTitle: string;
	userEmail?: string;
	optionalText?: string;
}

// Header Component: Authorization Pages;
export const Header: React.FC<HeaderProps> = ({ pageTitle, userEmail, optionalText }) => {
	const navigate = useNavigate();

	return (	
		<header className="row m-auto my-0 py-0 w-100 auth__header">
			<Col className="d-grid auth__header-contents">
				{/* Branding; */}
				<Row className="mx-0 px-1 pt-1 pb-0 auth__header-content-brand">
					<Col className="px-1 pt-8 pb-0">
					<button
						className="d-flex align-items-center justify-content-start mx-0 px-1 py-0 custom-focus branding"
						aria-label="Main"
						tab-index="-1"
						onClick={() => navigate("/")}
					>
						{/* Newmark N; */}
						<div className="branding__logo" title="myNMRK"></div>
						
						{/* Newmark Separator Line; */}
						<div className="branding__line" title=""></div>

						{/* Newmark App Logo Text; */}
						<div className="d-flex align-items-center justify-content-start pr-1 mr-0 branding__logo-text--wrapper">
							<h2 className="mb-0 lh-1 text-nowrap branding__logo-text">{NM_APP_NAME}</h2>
						</div>
					</button>
					</Col>
				</Row>

				{/* Page Title Group; */}
				<Row className="mx-0 px-1 auth__header-content-title">
					<Col xs={{ size: 12 }} className="px-1 py-0 d-flex flex-column">
						<div className="auth__header-group">
							<h1 className="px-0 mx-0 fs-1 lh-1 auth__header-title">{pageTitle}
							</h1>
							{userEmail && <p className="lh-1 mb-0 auth__header-title--email">
								{userEmail}
						
							</p>}
							{optionalText && <p className="lh-1 mb-0 pt-3 auth__header-title--opt">
								{optionalText}
							</p>}
						</div>
					</Col>
				</Row>

				{/* Section Rule; */}
				<Row className="mx-0 px-1 auth__header-content-rule">
					<Col xs={{ size: 12 }} className="px-1">
						<hr className="p-0 m-0 auth__rule"/>
					</Col>
				</Row>
			</Col>
		</header>
	);
};

// Footer Component: Authorization Pages; 
export const Footer: React.FC = () => {

	return (
		<footer className="row m-auto w-100 my-0 py-0 auth__page-footer">
			{/* Col: Spacer; */}
			<Col xs={{ size: 0}} sm={{ size: 1 }}></Col>

			{/* Col: Footer Content; */}
			<Col xs={{ size: 12 }} sm={{ size: 10 }} className="px-0 login__footer-container">
				<div className="mt-auto mb-0 px-3 pt-3 px-sm-4 pt-sm-4 footer pb-2">
					<div className="d-flex flex-wrap flex-sm-wrap gap-4">
						<div className="footer__brand">
							<a href="https://nmrk.com"
								className="footer__brand-logo custom-focus" 
								title="Visit the Newmark website"
								aria-labelledby="logoDescription"
								target="_blank"
								rel="noreferrer"
								tab-index="-1"
							>Newmark</a>
							<p id="logoDescription" className="sr-only">A logo image linking to the Newmark company website. Link opens in a new tab.</p> 
						</div>
						<div className="footer__legal">
							<p className="d-flex pb-2 pb-3-sm justify-content-start justify-content-sm-end footer__legal-copyright">
								&#169;{currentCopyrightYear()} {NEWMARK_NAME} | {NM_RIGHTS_STATEMENT}
							</p>
							<ul className="text-start text-sm-end footer__legal-links">
								<li className="footer__legal-doc">
									<a href={NM_PRIVACY.url} className="text-nowrap footer__legal-doc-link custom-focus">{NM_PRIVACY.ttl}</a>
								</li>
								<li className="footer__legal-doc">
									<a href={NM_TERMS.url} className="text-nowrap footer__legal-doc-link custom-focus">{NM_TERMS.ttl}</a>
								</li>
								<li className="footer__legal-doc">
									<a href={NM_SELECT_PRIVACY.url} className="text-nowrap footer__legal-doc-link custom-focus">{NM_SELECT_PRIVACY.ttl}</a>
								</li>
								<li className="footer__legal-doc">
									<a href={NM_BUSINESS_CONTINUITY.url} className="text-nowrap footer__legal-doc-link custom-focus">{NM_BUSINESS_CONTINUITY.ttl}</a>
								</li>
								<li className="footer__legal-doc">
									<a href={NM_DISCLAIMER.url} className="text-nowrap footer__legal-doc-link custom-focus">{NM_DISCLAIMER.ttl}</a>
								</li>
								<li className="footer__legal-doc">
									<a href={NM_NOTICES.url} className="text-nowrap footer__legal-doc-link custom-focus">{NM_NOTICES.ttl}</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</Col>

			{/* Col: Spacer; */}
			<Col xs={{ size: 0}} sm={{ size: 1 }}></Col>
		</footer>
	);
};