import { Identity } from "dto/identity";
import { User } from "dto/user";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

function AcceptanceTestHydrator() {
  const dispatch = useDispatch();

  useEffect(() => {
    // Add an event listener to listen for the custom event
    const listener = (event: any) => {
      // Extract the token from the event object
      const identity: Identity = {
        identityProvider: event.detail.identityProvider,
        identityToken: event.detail.identityToken,
        accessToken: event.detail.accessToken,
        refreshToken: event.detail.refreshToken,
        exp: event.detail.exp,
        obtained: event.detail.obtained,
      };
      // Dispatch the action with the token
      dispatch({
        type: "identity/setTokens",
        payload: identity,
      });

      const user: User = {
        email: event.detail.email,
        firstName: event.detail.firstName,
        lastName: event.detail.lastName,
        id: event.detail.id,
      };
      dispatch({
        type: "user/setUser",
        payload: user,
      });
    };
    window.addEventListener("hydrateServiceAccount", listener);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("hydrateServiceAccount", listener);
    };
  }, [dispatch]);

  return <div id="acceptance-test-hydrator"></div>;
}

export default AcceptanceTestHydrator;
