import {Identity} from "dto/identity";
import { AxiosResponse } from "axios";

import { makeHttpCall } from "./apiHelper";
import DATA_OPERATIONS from "enums/dataOperations";

export async function updateDataroomUsers(
 identity: Identity,
  updateReceivingUserId: string,
  dataRoomId: string,
  { newRole }: { newRole?: string }
): Promise<boolean> {
  const data = {
    UpdateReceivingUserId: updateReceivingUserId,
    DataRoomId: dataRoomId,
    RoleName: newRole,
  };
  const response: AxiosResponse = await makeHttpCall(
    identity,
    DATA_OPERATIONS.UPDATE,
    process.env.REACT_APP_APIM_DATAROOM_USERS!,
    { data }
  );

  return response.status === 200 && !!response.data?.[0]?.[0].id;
}
