// Slices are combined actions and reducers
// more at https://react-redux.js.org/tutorials/typescript-quick-start#define-slice-state-and-action-types

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "store";
import { RequestItemsDetails } from "dto/requestItemsDetails";
import { RequestItem } from "dto/requestItem";
import { Document } from "dto/document";

export type RequestItemsDetailsState = {
  [jobAssignmentPropertyId: string]: RequestItemsDetails;
};

const initialState: RequestItemsDetailsState = {};

export const requestItemsDetailsSlice = createSlice({
  name: "requestItemsDetails",
  initialState,
  reducers: {
    load(
      state: RequestItemsDetailsState,
      action: PayloadAction<RequestItemsDetailsState>
    ) {
      for (const [
        jobAssignmentPropertyId,
        requestItemsDetails,
      ] of Object.entries(action.payload)) {
        state[jobAssignmentPropertyId] = requestItemsDetails;
      }
    },
    updateRequestItemStatus(
      state: RequestItemsDetailsState,
      action: PayloadAction<RequestItemsDetailsState>
    ) {
      for (const [
        jobAssignmentPropertyId,
        requestItemsDetails,
      ] of Object.entries(action.payload)) {
        for (const requestItem of requestItemsDetails.requestedItems) {
          state[jobAssignmentPropertyId].requestedItems.find(
            (ri: RequestItem) => ri.id === requestItem.id
          )!.requestItemStatusTypeId = requestItem.requestItemStatusTypeId;
        }
      }
    },
    addNewComment(
      state: RequestItemsDetailsState,
      action: PayloadAction<RequestItemsDetailsState>
    ) {
      for (const [
        jobAssignmentPropertyId,
        requestItemsDetails,
      ] of Object.entries(action.payload)) {
        // New comment
        state[jobAssignmentPropertyId].commentsDetail.push(
          requestItemsDetails.commentsDetail[0]
        );

        // Link the new comment to the request item
        for (const requestItem of requestItemsDetails.requestedItems) {
          state[jobAssignmentPropertyId].requestedItems
            .find((ri: RequestItem) => ri.id === requestItem.id)!
            .commentIds.push(requestItem.commentIds[0]);
        }
      }
    },
    appendRequestItemFile(
      state: RequestItemsDetailsState,
      action: PayloadAction<RequestItemsDetailsState>
    ) {
      for (const [
        jobAssignmentPropertyId,
        requestItemsDetails,
      ] of Object.entries(action.payload)) {
        // Update documentsDetail
        state[jobAssignmentPropertyId].documentsDetail.push(
          requestItemsDetails.documentsDetail[0] as Document
        );

        // Update documentIds for the specific requestItem
        for (const requestItem of requestItemsDetails.requestedItems) {
          state[jobAssignmentPropertyId].requestedItems
            .find((ri: RequestItem) => ri.id === requestItem.id)!
            .documentIds.push(
              requestItemsDetails.documentsDetail[0].id as number
            );
        }
      }
    },
    clearRequestItemsDetails(state: RequestItemsDetailsState) {
      Object.keys(state).forEach((key) => {
        delete state[key];
      });
    },
  },
});
export const { load, updateRequestItemStatus, clearRequestItemsDetails } =
  requestItemsDetailsSlice.actions;

export const selectRequestItemsDetails = (
  state: RootState
): RequestItemsDetails => {
  const jobAssignmentPropertyId =
    state.assignmentSelected?.jobAssignmentPropertyId;
  return state.requestItemsDetails?.[jobAssignmentPropertyId];
};

export const selectRequestItemList = (state: RootState): RequestItem[] => {
  return selectRequestItemsDetails(state).requestedItems;
};

export default requestItemsDetailsSlice.reducer;
