import { useNavigate } from "react-router";

import "./index.scss"

export default function Error() {
  const navigate = useNavigate();
  return (
    <>
      <div>Oops</div>
      <button
        className="error-page-back-to-data-rooms"
        onClick={() => navigate("/")}
      >
        Back to Data Rooms
      </button>
    </>
  );
}
