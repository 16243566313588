import SIDE_PANEL_SIDE from "enums/sidePanelSide";
import "./floatingSidePanel.scss";
import { useEffect, useState } from "react";

export default function FloatingSidePanel({
  side,
  children,
  closePanel,
}: {
  side?: SIDE_PANEL_SIDE;
  children: any;
  closePanel: () => void;
}) {
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    // Trigger animation when component mounts
    setIsVisible(true);
  }, []);
  return (
    <>
      <div className="floating-side-panel-shadow" onClick={closePanel}></div>

      <div
        className={`floating-side-panel ${
          side === SIDE_PANEL_SIDE.RIGHT ? "floating-side-panel-right" : ""
        } ${isVisible ? "visible" : ""}`}
      >
        {children}
      </div>
    </>
  );
}
