import "./comments.scss";
import CommentComponent from "./comment/comment";
import { RequestItem } from "dto/requestItem";
import { Comment } from "dto/comment";
import {
  Col,
  Container,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
} from "reactstrap";
import { useEffect, useState } from "react";
import "./dropdown.scss"; // TODO: make a better import w/ Webpack 😭

export default function Comments({
  commentsDetail = [],
  requestedItems = [],
}: {
  commentsDetail: Comment[];
  requestedItems: RequestItem[];
}) {
  const [sortedComments, setSortedComments] = useState(
    commentsDetail
      ? [...commentsDetail]?.sort(
          (c1, c2) =>
            // To display newer dates on top
            new Date(c2.createdOn).getTime() - new Date(c1.createdOn).getTime()
        )
      : []
  );
  useEffect(() => {
    setSortedComments(
      commentsDetail
        ? [...commentsDetail]?.sort(
            (c1, c2) =>
              // To display newer dates on top
              new Date(c2.createdOn).getTime() -
              new Date(c1.createdOn).getTime()
          )
        : []
    );
  }, [commentsDetail, setSortedComments]);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const [sortDirection, setSortDirection] = useState("Newest");

  const sort = function (dir: string) {
    if (dir === "newest") {
      setSortDirection("Newest");
      setSortedComments(
        [...commentsDetail]?.sort(
          (c1, c2) =>
            // To display newer dates on top
            new Date(c2.createdOn).getTime() - new Date(c1.createdOn).getTime()
        )
      );
    } else {
      setSortDirection("Oldest");

      setSortedComments(
        [...commentsDetail]?.sort(
          (c1, c2) =>
            // To display newer dates on top
            new Date(c1.createdOn).getTime() - new Date(c2.createdOn).getTime()
        )
      );
    }
  };
  return (
    <Container fluid className="comment-container">
      <Row>
        <Col sm={2}>
          <h3 className="nmrk-comment-header">Comments</h3>
        </Col>
      </Row>
      <Row>
        <Dropdown
          className="nmrk-comment-sort-dropdown nmrk-white-dropdown"
          isOpen={dropdownOpen}
          toggle={toggle}
        >
          <DropdownToggle className="nmrk-dropdown-toggle" caret>
            <span>
              <span className="dropdown-grey">Sort: </span>
              <span className="dropdown-black nmrk-dropdown-pad-right">
                {sortDirection}
              </span>
            </span>
          </DropdownToggle>
          <DropdownMenu className="nmrk-dropdown-no-border-radius">
            <DropdownItem onClick={() => sort("newest")}>
              <span className="nmrk-dropdown-margin-right nmrk-dropdown-no-border-radius">
                <span className="dropdown-grey">Sort: </span>
                <span className="dropdown-black">Newest</span>
              </span>
            </DropdownItem>
            <DropdownItem onClick={() => sort("oldest")}>
              <span className="nmrk-dropdown-margin-right nmrk-dropdown-no-border-radius">
                <span className="dropdown-grey">Sort: </span>
                <span className="dropdown-black">Oldest</span>
              </span>
            </DropdownItem>
          </DropdownMenu>
        </Dropdown>
      </Row>
      {sortedComments.map((comment, i) => {
        const requestItemTypeDescriptions =
          requestedItems
            .filter((ri) => ri.commentIds.includes(comment.id))
            ?.map((ri) => ri.requestItemTypeDescription) || [];
        return (
          <Row>
            <Col>
              <CommentComponent
                key={i}
                comment={comment}
                requestItemTypeDescriptions={requestItemTypeDescriptions}
              />
            </Col>
          </Row>
        );
      })}
    </Container>
  );
}
