import { configureStore } from '@reduxjs/toolkit'
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from "redux-persist";
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import userSlice from './slices/user/userSlice'
import dataRoomSelectedSlice from './slices/dataRoom/dataRoomSelectedSlice'
import dataRoomListSlice from './slices/dataRoom/dataRoomListSlice'
import assignmentListSlice from './slices/assignment/assignmentListSlice'
import accessSlice from './slices/access/accessSlice';
import identitySlice from "./slices/user/identitySlice";
import assignmentSelectedSlice from './slices/assignment/assignmentSelectedSlice';
import requestItemsDetailsSlice from './slices/assignment/requestItemsDetailsSlice';
import valuationsSystemInfoSlice from './slices/assignment/valuationsSystemInfoSlice';
import breadcrumbSlice from 'slices/breadcrumb/breadcrumbSlice';
import folderHierarchySlice from 'slices/document/folderHierarchySlice';
import selectedFolderSlice from "slices/document/selectedFolderSlice";
import folderListExpandFolderSlice from 'slices/document/folderListExpandFolderSlice';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['user', 'identity']
};

const rootReducer = combineReducers({
  user: userSlice,
  dataRoomList: dataRoomListSlice,
  dataRoomSelected: dataRoomSelectedSlice,
  assignmentList: assignmentListSlice,
  assignmentSelected: assignmentSelectedSlice,
  requestItemsDetails: requestItemsDetailsSlice,
  valuationsSystemInfo: valuationsSystemInfoSlice,
  access: accessSlice,
  breadcrumb: breadcrumbSlice,
  identity: identitySlice,
  folderHierarchy: folderHierarchySlice,
  selectedFolder: selectedFolderSlice,
  folderListExpandFolder: folderListExpandFolderSlice
});
const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
    }
  })
});

const persistor = persistStore(store);

export { store, persistor };

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch