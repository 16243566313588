import { ValuationsSystemInfo } from "dto/valuationsSystemInfo";

const systemInfoData: ValuationsSystemInfo = {
  requestDetailsMessage: "Please upload each requested item listed below.",
  maxUploadFileSize: 21750000,
  mimeTypes: [
    {
      id: 1,
      description: "application/msword",
      fileExtension: "doc",
    },
    {
      id: 2,
      description: "application/msword",
      fileExtension: "dot",
    },
    {
      id: 3,
      description: "application/msword",
      fileExtension: "docx",
    },
    {
      id: 4,
      description: "application/vnd.ms-word.template.macroEnabled.12",
      fileExtension: "docm",
    },
    {
      id: 10,
      description: "application/pdf",
      fileExtension: "pdf",
    },
    {
      id: 11,
      description: "application/octet-stream",
      fileExtension: "avux",
    },
    {
      id: 12,
      description: "application/octet-stream",
      fileExtension: "sf",
    },
    {
      id: 13,
      description: "application/xml",
      fileExtension: "xml",
    },
    {
      id: 14,
      description: "application/json",
      fileExtension: "json",
    },
    {
      id: 20,
      description: "application/vnd.ms-excel",
      fileExtension: "xls",
    },
    {
      id: 21,
      description: "application/vnd.ms-excel",
      fileExtension: "xlt",
    },
    {
      id: 22,
      description: "application/vnd.ms-excel",
      fileExtension: "xlsx",
    },
    {
      id: 24,
      description: "application/vnd.ms-excel.sheet.macroEnabled.12",
      fileExtension: "xlsm",
    },
    {
      id: 30,
      description: "application/vnd.ms-powerpoint",
      fileExtension: "ppt",
    },
    {
      id: 31,
      description: "application/vnd.ms-powerpoint",
      fileExtension: "pps",
    },
    {
      id: 32,
      description: "application/vnd.ms-powerpoint",
      fileExtension: "pot",
    },
    {
      id: 33,
      description: "application/vnd.ms-outlook",
      fileExtension: "msg",
    },
    {
      id: 40,
      description: "application/x-gzip",
      fileExtension: "gz",
    },
    {
      id: 41,
      description: "application/zip",
      fileExtension: "zip",
    },
    {
      id: 58,
      description: "application/png",
      fileExtension: "png",
    },
  ],
  requestItemCategoryTypes: [
    {
      id: 1,
      description: "Physical",
    },
    {
      id: 2,
      description: "Legal",
    },
    {
      id: 3,
      description: "Regulatory",
    },
    {
      id: 4,
      description: "Market",
    },
    {
      id: 5,
      description: "Financial",
    },
  ],
  requestItemStatusTypes: [
    {
      id: 1,
      description: "Requested",
    },
    {
      id: 2,
      description: "Complete",
    },
    {
      id: 3,
      description: "Not Available",
    },
  ],
  storageTypes: [
    {
      id: 1,
      description: "Processing",
    },
    {
      id: 2,
      description: "Completed",
    },
    {
      id: 3,
      description: "Failed",
    },
  ],
};

export { systemInfoData }