// Slice for user data
// Slices are combined actions and reducers
// more at https://react-redux.js.org/tutorials/typescript-quick-start#define-slice-state-and-action-types

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "store";
import { DataRoom } from "dto/dataRoom";
import ROLES from "enums/roles";
import FolderData from "components/shared/types/folderData";

const initialState: FolderData = {
    id: -1,
    name: "",
    addedDate: "",
    isFolder: false,
    childData: []
};

export const selectedFolderSlice = createSlice({
    name: "selectedFolder",
    initialState,
    reducers: {
        select(state, action: PayloadAction<FolderData>) {

            state.id = action.payload.id
            state.name = action.payload.name
            state.addedDate = action.payload.addedDate
            state.isFolder = action.payload.isFolder
            state.childData = action.payload.childData

        }
    },
});
export const { select } = selectedFolderSlice.actions;

export const selectSelectedFolder = (state: RootState): FolderData =>
    state.selectedFolder;


export default selectedFolderSlice.reducer;
