import { AxiosResponse } from "axios";

import { Identity } from "dto/identity";
import { makeHttpCall } from "./apiHelper";
import DATA_OPERATIONS from "enums/dataOperations";
import DATA_PROVIDERS from "enums/dataProviders";

async function _performDataOp(
  identity: Identity,
  dataOp: DATA_OPERATIONS,
  data: {
    jobAssignmentPropertyId: number;
    id: number;
    content?: string;
  }
): Promise<any> {
  const response: AxiosResponse = await makeHttpCall(
    identity,
    dataOp,
    process.env.REACT_APP_APIM_COMMENT!,
    { data: data, dataProvider: DATA_PROVIDERS.VALUATIONS }
  );
  if (response?.status < 300) {
    return response.data;
  }
  return {};
}

export async function createComment(
  identity: Identity,
  data: {
    jobAssignmentPropertyId: number;
    id: number;
    content: string;
  }
): Promise<any> {
  return await _performDataOp(identity, DATA_OPERATIONS.CREATE, data);
}
