declare const window: Window &
  typeof globalThis & {
    _paq: any; // Let TypeScript know that _paq exists on `window`
  };

const canUseMatomo = () => {
  // if we are on prod and _paq (matomo) exists - check public/index.html
  return process.env.NODE_ENV === "production" && window._paq;
};

const pageTitles: { [key: string]: string } = {
  "/": "Landing Page",
  "/native/dataRooms/<UUID>": "Native DataRoom", // when one of the tabs is loading
  "/native/dataRooms/<UUID>/Documents": "Native DataRoom - Documents",
  "/native/dataRooms/<UUID>/Tourbooks": "Native DataRoom - Tourbooks",
  "/native/dataRooms/<UUID>/Intelligence": "Native DataRoom - Intelligence",
  "/native/dataRooms/<UUID>/Access/Clients": "Native DataRoom - Access/Clients",
  "/native/dataRooms/<UUID>/Access/Brokers": "Native DataRoom - Access/Brokers",
  "/valuations/dataRooms/<AssignmentId>/RequestedItems":
    "Valuations DataRoom - Requested Items",
};

enum MatomoEventCategories {
  Login = "Login",
  RequestItem = "RequestItem",
  Collaborate = "Collaborate"
}
enum MatomoActions {
  Login = "Login",
  Logout = "Logout",
  Upload = "Upload",
  MarkAsUnavailable = "MarkAsUnavailable",
  UndoMarkAsUnavailable = "UndoMarkAsUnavailable", // it may be marked as Requested or Completed
  Reassign = "Reassign",
  AddComment = "AddComment",
  Collaborate = "Collaborate"
}

const trackEvent = (
  category: MatomoEventCategories,
  action: MatomoActions,
  name?: string,
  value?: any
) => {
  /*
   * Category (Required):            This describes the type of events you want to track.
   *                                 For example, Link Clicks, Videos, Outbound Links, and Form Events.
   *                                 A value from eventCategories enum.
   * Action (Required):              This is the specific action that is taken. For example, with the Video
   *                                 category, you might have a Play, Pause and Complete action.
   * Name (Optional – Recommended):  This is usually the title of the element that is being interacted
   *                                 with, to aid with analysis. For example, it could be the name of a
   *                                 Video that was played or the specific form that is being submitted.
   * Value (Optional):               This is a numeric value and is often added dynamically. It could be the cost
   *                                 of a product that is added to a cart, or the completion percentage of a video.
   * the above is taken from: https://matomo.org/faq/reports/the-anatomy-of-an-event/
   */
  if (!canUseMatomo()) {
    return;
  }

  const eventArray = value
    ? [category, action, name, value]
    : [category, action, name];
  window._paq.push(["trackEvent", ...eventArray]);
};

const trackPageView = (path: string, email?: string) => {
  if (canUseMatomo()) {
    const pathForTitles = path
      .replaceAll(
        /[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}/g,
        "<UUID>"
      ) // Remove UUID in url
      .replace(
        /valuations\/dataRooms\/\d{6}/,
        "valuations/dataRooms/<AssignmentId>"
      ); // Remove Assignment Id in url
    const pageTitle = pageTitles[pathForTitles] || "Connexus";
    window._paq.push(["setCustomUrl", path]);
    window._paq.push(["setDocumentTitle", pageTitle]);
    if (email) {
      // https://developer.matomo.org/guides/tracking-javascript-guide#user-id
      // "You must set the user ID for each pageview, otherwise the pageview will be tracked without the user ID set."
      setUserEmail(email);
    }
    window._paq.push(["trackPageView"]);
  }
};

const setUserEmail = (email: string) => {
  if (canUseMatomo()) {
    window._paq.push(["setUserId", email]);
  }
};

export {
  trackPageView,
  setUserEmail,
  trackEvent,
  MatomoEventCategories,
  MatomoActions,
};
