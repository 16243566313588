import { AxiosResponse } from "axios";

import { makeHttpCall } from "./apiHelper";
import DATA_OPERATIONS from "enums/dataOperations";
import { Identity } from "dto/identity";
import DATA_PROVIDERS from "enums/dataProviders";
import { ValuationsSystemInfo } from "dto/valuationsSystemInfo";
import { systemInfoData as tempSystemInfoData } from "debug/systemInfoData";
import { toast } from "react-toastify";

function _getSampleData() {
  toast.warn(
    "Failed to fetch valuations system info. Using temp data (some functionalities will not work)"
  );
  return tempSystemInfoData;
}

async function _performDataOp(
  identity: Identity,
  dataOp: DATA_OPERATIONS
): Promise<ValuationsSystemInfo> {
  let response: AxiosResponse;
  try {
    response = await makeHttpCall(
      identity,
      dataOp,
      process.env.REACT_APP_APIM_SYSTEM_INFO!,
      { dataProvider: DATA_PROVIDERS.VALUATIONS }
    );
  } catch (e) {
    if (process.env.REACT_APP_IS_LOCAL) {
      return _getSampleData();
    } else {
      throw e;
    }
  }
  if (process.env.REACT_APP_IS_LOCAL && !response.data) {
    return _getSampleData();
  }
  if (response?.status < 300) {
    return response.data;
  }
  return {} as ValuationsSystemInfo;
}

export async function getSystemInfo(
  identity: Identity
): Promise<ValuationsSystemInfo> {
  return await _performDataOp(identity, DATA_OPERATIONS.READ);
}
