// Slice for user data
// Slices are combined actions and reducers
// more at https://react-redux.js.org/tutorials/typescript-quick-start#define-slice-state-and-action-types

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "store";
import { DataRoom } from "dto/dataRoom";
import ROLES from "enums/roles";

const initialState: DataRoom = {
  id: "",
  name: "",
  createdDate: "",
  archivedDate: "",
  createdBy: "",
  userRoleInCurrentDataRoom: ROLES.CLIENT,
};

export const dataRoomSelectedSlice = createSlice({
  name: "dataRoomSelected",
  initialState,
  reducers: {
    select(state, action: PayloadAction<DataRoom>) {
      state.id = action.payload.id;
      state.name = action.payload.name;
      state.createdDate = action.payload.createdDate;
      state.archivedDate = action.payload.archivedDate;
      state.createdBy = action.payload.createdBy;
      state.userRoleInCurrentDataRoom = action.payload.userRoleInCurrentDataRoom;
    },
    clearDataRoomSelected(state) {
      state.id = "";
      state.name = "";
      state.createdDate = "";
      state.archivedDate = "";
      state.createdBy = "";
      state.userRoleInCurrentDataRoom = ROLES.CLIENT;
    }
  },
});
export const { select } = dataRoomSelectedSlice.actions;

export const selectDataRoomSelected = (state: RootState): DataRoom =>
  state.dataRoomSelected;
export const selectIsAdmin = (state: RootState): boolean =>
  [ROLES.ADMIN].includes(state.dataRoomSelected?.userRoleInCurrentDataRoom as ROLES);
export const selectIsBroker = (state: RootState): boolean =>
  [ROLES.BROKER, ROLES.ADMIN].includes(
    state.dataRoomSelected?.userRoleInCurrentDataRoom as ROLES
  );
export const selectIsClient = (state: RootState): boolean =>
  [ROLES.CLIENT].includes(state.dataRoomSelected?.userRoleInCurrentDataRoom as ROLES);

export default dataRoomSelectedSlice.reducer;
