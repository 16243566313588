import { Container, Row, Col } from "reactstrap";
import HeaderTemplate from "components/shared/header";
import { Outlet, useLocation } from "react-router-dom";
import "./pageLayout.scss";
import { useEffect } from "react";
import { trackPageView } from "matomo";
import { useAppSelector } from "hooks";
import { selectUser } from "slices/user/userSlice";

export default function PageLayout() {
  const location = useLocation();
  const user = useAppSelector(selectUser);
  useEffect(() => {
    trackPageView(location.pathname, user.email);
  }, [location, user.email]);

  return (
    <>
      <HeaderTemplate />
      <Container fluid className="h-100">
        <Row>
          <Col xs={0} sm={1} md={1}></Col>
          <Col xs={12} sm={10} md={10}>
            <span
              className={
                localStorage.FEATURE_TOGGLE_enableDropShadow
                  ? "nmrk-page-layout-drop-shadow"
                  : ""
              }
            >
              <Outlet></Outlet>
            </span>
          </Col>

          <Col xs={0} sm={1} md={1}></Col>
        </Row>
      </Container>
    </>
  );
}
