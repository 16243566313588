import { useNavigate } from "react-router-dom";

import styles from "./header.module.scss";

import { ReactComponent as Logo } from "assets/img/logo-portal__n.svg";
import { ReactComponent as Line } from "assets/img/logo-portal__line.svg";
import { Container, Row, Col } from "reactstrap";
import { logoutAndClearRedux } from "components/utils/redux/logout";
import { useAppDispatch } from "hooks";
import { appInfo } from "constants/termsLinks";
const { NM_APP_NAME } = appInfo;

export default function HeaderTemplate() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleLogout = () => {
    logoutAndClearRedux(dispatch);
    navigate("/");
  };

  // Only process Space and Enter for keydown logout event;
  const handleLogoutKeyDown = (
    event: React.KeyboardEvent<HTMLButtonElement>
  ) => {
    // Do extra test for Space key for cross-browser compatibility;
    if (event.key === "Enter" || event.key === " " || event.code === "Space") {
      handleLogout();
    }
  };

  return (
    <header className={`${styles.header}`}>
      <Container fluid>
        <Row>
          {/* Branding Section; */}
          <Col
            xs={{ size: 6 }}
            md={{ size: 4 }}
            className="d-flex align-items-center justify-content-start mx-0 px-2 py-0 gap-2"
          >
            {/* This is for accessibility:
             * The skip link will need to be defined on each
             * individual page somewhere below the header by
             * by adding id="main" to the appropriate section.
             */}
            <a
              href="#mainContent"
              id="skipToMainContent"
              className="visually-hidden-focusable"
            >
              Skip to Main Content
            </a>

            {/* Branding / Home Nav; */}
            <button
              className={`d-flex align-items-center justify-content-start mx-0 px-2 py-3 gap-3 ${styles.branding}`}
              role="navigation"
              aria-label="Main"
              onClick={() => navigate("/")}
            >
              <Logo className={`${styles.logo}`} title="myNMRK" />
              <Line className={`${styles.logo__line}`} title="" />
              <div
                className={`d-flex align-items-center justify-content-start ${styles["logo__text-wrapper"]}`}
              >
                <p className={`mb-0 lh-1 text-nowrap ${styles.logo__text}`}>
                  {NM_APP_NAME}
                </p>
              </div>
            </button>
          </Col>
          <Col
            xs={{ size: 6 }}
            md={{ size: 8 }}
            className="d-flex align-items-center justify-content-end mx-0 px-3"
          >
            {/* Add onKeyDown support for accessibility; */}
            <button
              className={`fw-bold px-3 py-1 ${styles.logout}`}
              onClick={handleLogout}
              onKeyDown={handleLogoutKeyDown}
            >
              Logout
            </button>
          </Col>
        </Row>
      </Container>
    </header>
  );
}
