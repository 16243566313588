import { trackPageView } from "matomo";
import { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";

export default function PageLayoutLoggedOut() {
  const location = useLocation();
  useEffect(() => {
    trackPageView(location.pathname);
  }, [location]);

  return <Outlet></Outlet>;
}
