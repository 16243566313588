// Slices are combined actions and reducers
// more at https://react-redux.js.org/tutorials/typescript-quick-start#define-slice-state-and-action-types

import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { InviteSummary } from "dto/inviteSummary";
import ROLES from "enums/roles";
import type { RootState } from "store";

export type AccessState = { [dataRoomId: string]: InviteSummary[] };

const initialState: AccessState = {};

export const accessSlice = createSlice({
  name: "access",
  initialState,
  reducers: {
    update(state: AccessState, action: PayloadAction<AccessState>) {
      for (const [dataRoomId, accessList] of Object.entries(action.payload)) {
        if (!state[dataRoomId]) {
          state[dataRoomId] = [] as InviteSummary[];
        }
        const newData = accessList.filter(
          (item: InviteSummary) =>
            !state[dataRoomId].some(
              (existingItem) => existingItem.inviteId === item.inviteId && existingItem.userId === item.userId
            )
        );
        state[dataRoomId].push(...newData);
      }
    },
    add(state: AccessState, action: PayloadAction<AccessState>) {
      for (const [dataRoomId, accessList] of Object.entries(action.payload)) {
        if (!state[dataRoomId]) {
          state[dataRoomId] = [] as InviteSummary[];
        }
        for (const individualAccess of accessList) {
          if (state[dataRoomId].find((x) => x.email === individualAccess.email)) {
            continue;
          }
          state[dataRoomId].push({
            email: individualAccess.email,
            dataRoomId: individualAccess.dataRoomId,
            addedDate: individualAccess.addedDate,
            status: individualAccess.status,
            inviteId: individualAccess.inviteId,
            userId: individualAccess.userId,
            inviteeRoleInCurrentDataRoom: individualAccess.inviteeRoleInCurrentDataRoom,
          } as InviteSummary);
        }
      }
    },
    clearAccess(state: AccessState) {
      Object.keys(state).forEach((key) => {
        delete state[key];
      });
    }
  },
});
export const { update, add } = accessSlice.actions;

export const selectAccess = (state: RootState): AccessState => state.access;
export const selectAccessOfCurrentDataRoom = (state: RootState): InviteSummary[] => {
  const dataRoomId = state.dataRoomSelected?.id;
  return state.access?.[dataRoomId];
};
export const selectInvitedClients = createSelector(
  [selectAccessOfCurrentDataRoom],
  (currentDataroomAccess: InviteSummary[]): InviteSummary[] =>
    currentDataroomAccess?.filter((inviteSummary: InviteSummary): boolean =>
      [ROLES.CLIENT].includes(inviteSummary.inviteeRoleInCurrentDataRoom as ROLES)
    )
);
export const selectInvitedBrokers = createSelector(
  [selectAccessOfCurrentDataRoom],
  (currentDataroomAccess: InviteSummary[]): InviteSummary[] =>
    currentDataroomAccess?.filter((inviteSummary: InviteSummary): boolean =>
      [ROLES.BROKER, ROLES.ADMIN].includes(inviteSummary.inviteeRoleInCurrentDataRoom as ROLES)
    )
);
export const selectInvitedAdmins = createSelector(
  [selectAccessOfCurrentDataRoom],
  (currentDataroomAccess: InviteSummary[]): InviteSummary[] =>
    currentDataroomAccess?.filter((inviteSummary: InviteSummary): boolean =>
      [ROLES.ADMIN].includes(inviteSummary.inviteeRoleInCurrentDataRoom as ROLES)
    )
);

export default accessSlice.reducer;
