import React from 'react';

/** 
 * Application information including the official name,
 * creation date, Newmark copyright info, legal links, and
 * optional legal text for user authorization;
 */
export const appInfo = {

	NM_APP_NAME:  "myNMRK",    // Official app name;
	NM_INITIAL_YEAR: 2024,     // Year written;
	NEWMARK_NAME: "Newmark",   // Company name;
	NM_APP_DESCRIPTION: "myNMRK provides structured, secure filesharing and other services for our commercial real estate clients.",

	/** 
	 * Optional Legal Text (with example):
	 *	<>
	 * 		Legal text with <a href="https://www.nmrk.com" className="auth__optional-legal--link custom-focus">some link</a>.
	 * </>
	 * NOTE: Include link class names shown in example;
	 */ 
	NM_OPTIONAL_LEGAL: <></>,

	// Legal text after copyright;
	NM_RIGHTS_STATEMENT: "All Rights Reserved.",

	// Legal document links and titles;
	NM_PRIVACY: {
		ttl: "Privacy Policy",
		url: "https://www.nmrk.com/privacy-statement"
	},
	NM_NOTICES: {
		ttl: "Select US States Consumer Privacy Notices",
		url: "https://www.nmrk.com/notices"
	},
	NM_BUSINESS_CONTINUITY: {
		ttl: "Business Continuity",
		url: "https://www.nmrk.com/storage-nmrk/uploads/documents/Newmar_Group_Business_Continuity_Plan_Summary_2021.pdf"
	},
	NM_DISCLAIMER: {
		ttl: "Disclaimer and Legal Information",
		url: "https://ir.nmrk.com/disclaimer-and-legal-information/default.aspx"
	},
	NM_TERMS: {
		ttl: "Terms of Use",
		url: "https://www.nmrk.com/storage-nmrk/uploads/documents/Terms_of_Use.pdf"
	},
	NM_SELECT_PRIVACY: {
		ttl: "Select US States Consumer Privacy Notices",
		url: "https://www.nmrk.com/ccpa"
	}
}