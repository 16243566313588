// Slices are combined actions and reducers
// more at https://react-redux.js.org/tutorials/typescript-quick-start#define-slice-state-and-action-types

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "store";
import {
  RequestItemStatusUpdating,
  ValuationsSystemInfo,
  ValuationsType,
} from "dto/valuationsSystemInfo";

const initialState: ValuationsSystemInfo = {
  requestDetailsMessage: "",
  maxUploadFileSize: 0,
  mimeTypes: [],
  requestItemCategoryTypes: [],
  requestItemStatusTypes: [],
  storageTypes: [],
};

export let RequestItemStatusRequested: ValuationsType,
  RequestItemStatusComplete: ValuationsType,
  RequestItemStatusNotAvailable: ValuationsType;

export const valuationsSystemInfoSlice = createSlice({
  name: "valuationsSystemInfo",
  initialState,
  reducers: {
    load(
      state: ValuationsSystemInfo,
      action: PayloadAction<ValuationsSystemInfo>
    ) {
      state.requestDetailsMessage = action.payload.requestDetailsMessage;
      state.maxUploadFileSize = action.payload.maxUploadFileSize;
      state.mimeTypes = action.payload.mimeTypes;
      state.requestItemCategoryTypes = action.payload.requestItemCategoryTypes;
      state.requestItemStatusTypes = action.payload.requestItemStatusTypes;
      RequestItemStatusRequested = state.requestItemStatusTypes.find(
        (status) => status.description === "Requested"
      ) as ValuationsType;
      RequestItemStatusComplete = state.requestItemStatusTypes.find(
        (status) => status.description === "Complete"
      ) as ValuationsType;
      RequestItemStatusNotAvailable = state.requestItemStatusTypes.find(
        (status) => status.description === "Not Available"
      ) as ValuationsType;
      state.requestItemStatusTypes.push(
        RequestItemStatusUpdating // for internal use
      );
      state.storageTypes = action.payload.storageTypes;
    },
  },
});
export const { load } = valuationsSystemInfoSlice.actions;

export const selectValuationsSystemInfo = (
  state: RootState
): ValuationsSystemInfo => state.valuationsSystemInfo;

export default valuationsSystemInfoSlice.reducer;
