// Slice for selected assignment
// Slices are combined actions and reducers
// more at https://react-redux.js.org/tutorials/typescript-quick-start#define-slice-state-and-action-types

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "store";
import { Assignment } from "dto/assignment";
import JobAssignmentStatus from "enums/jobAssignmentStatus";

const initialState: Assignment = {
  jobId: -1,
  jobName: "",
  jobAssignmentNumber: "",
  jobAssignmentGroupId: -1,
  jobAssignmentPropertyId: -1,
  jobAssignmentDueDate: "",
  jobAssignmentStatus: JobAssignmentStatus.ACTIVE,
  jobAssignmentRequestedItemsTotalCount: -1,
  jobAssignmentRequestedItemsUploadedCount: -1,
  propertyEventId: -1,
  propertyName: "",
  propertyAddress1: "",
  propertyAddress2: "",
  propertyCity: "",
  propertyState: "",
  propertyCounty: "",
  propertyZip: "",
  propertyCountryName: "",
  companyId: -1,
  companyName: "",
  companyAddress1: "",
  companyAddress2: "",
  companyCity: "",
  companyState: "",
  companyZip: "",
  companyCountry: "",
  clientPortalContactEmail: "",
  clientPortalResponsibleContactsEmail: "",
  jobAssignmentLeadAppraisers: [],
  propertySubtypeDescription: "",
  propertySubtypeId: -1,
  propertyTypeDescription: "",
  propertyTypeId: -1,
};

export const assignmentSelectedSlice = createSlice({
  name: "assignmentSelected",
  initialState,
  reducers: {
    select(state, action: PayloadAction<Assignment>) {
      state.jobId = action.payload.jobId;
      state.jobName = action.payload.jobName;
      state.jobAssignmentNumber = action.payload.jobAssignmentNumber;
      state.jobAssignmentGroupId = action.payload.jobAssignmentGroupId;
      state.jobAssignmentPropertyId = action.payload.jobAssignmentPropertyId;
      state.jobAssignmentDueDate = action.payload.jobAssignmentDueDate;
      state.jobAssignmentStatus = action.payload.jobAssignmentStatus;
      state.jobAssignmentRequestedItemsTotalCount =
        action.payload.jobAssignmentRequestedItemsTotalCount;
      state.jobAssignmentRequestedItemsUploadedCount =
        action.payload.jobAssignmentRequestedItemsUploadedCount;
      state.jobAssignmentClientReference =
        action.payload.jobAssignmentClientReference;
      state.propertyEventId = action.payload.propertyEventId;
      state.propertyName = action.payload.propertyName;
      state.propertyAddress1 = action.payload.propertyAddress1;
      state.propertyAddress2 = action.payload.propertyAddress2;
      state.propertyCity = action.payload.propertyCity;
      state.propertyState = action.payload.propertyState;
      state.propertyCounty = action.payload.propertyCounty;
      state.propertyZip = action.payload.propertyZip;
      state.propertyCountryName = action.payload.propertyCountryName;
      state.companyId = action.payload.companyId;
      state.companyName = action.payload.companyName;
      state.companyAddress1 = action.payload.companyAddress1;
      state.companyAddress2 = action.payload.companyAddress2;
      state.companyCity = action.payload.companyCity;
      state.companyState = action.payload.companyState;
      state.companyZip = action.payload.companyZip;
      state.companyCountry = action.payload.companyCountry;
      state.clientPortalContactEmail = action.payload.clientPortalContactEmail;
      state.clientPortalResponsibleContactsEmail =
        action.payload.clientPortalResponsibleContactsEmail;
      state.jobAssignmentLeadAppraisers =
        action.payload.jobAssignmentLeadAppraisers;
      state.propertySubtypeDescription =
        action.payload.propertySubtypeDescription;
      state.propertySubtypeId = action.payload.propertySubtypeId;
      state.propertyTypeDescription = action.payload.propertyTypeDescription;
      state.propertyTypeId = action.payload.propertyTypeId;
    },
    clearAssignmentSelected(state) {
      state.jobId = -1;
      state.jobName = "";
      state.jobAssignmentNumber = "";
      state.jobAssignmentGroupId = -1;
      state.jobAssignmentPropertyId = -1;
      state.jobAssignmentDueDate = "";
      state.jobAssignmentStatus = JobAssignmentStatus.ACTIVE;
      state.jobAssignmentRequestedItemsTotalCount = -1;
      state.jobAssignmentRequestedItemsUploadedCount = -1;
      state.propertyEventId = -1;
      state.propertyName = "";
      state.propertyAddress1 = "";
      state.propertyAddress2 = "";
      state.propertyCity = "";
      state.propertyState = "";
      state.propertyCounty = "";
      state.propertyZip = "";
      state.propertyCountryName = "";
      state.companyId = -1;
      state.companyName = "";
      state.companyAddress1 = "";
      state.companyAddress2 = "";
      state.companyCity = "";
      state.companyState = "";
      state.companyZip = "";
      state.companyCountry = "";
      state.clientPortalContactEmail = "";
      state.clientPortalResponsibleContactsEmail = "";
      state.jobAssignmentLeadAppraisers = [];
      state.propertySubtypeDescription = "";
      state.propertySubtypeId = -1;
      state.propertyTypeDescription = "";
      state.propertyTypeId = -1;
    },
  },
});
export const { select } = assignmentSelectedSlice.actions;

export const selectAssignmentSelected = (state: RootState): Assignment =>
  state.assignmentSelected;

export default assignmentSelectedSlice.reducer;
