import { Comment } from "dto/comment";

import "./comment.scss";
import { useEffect, useState } from "react";
import { useAppSelector } from "hooks";
import { selectIdentity } from "slices/user/identitySlice";
import { readOtherUserData } from "components/api/userData";
import { User } from "dto/user";
import { getTimezone } from "components/utils/datetime/datetime";

type DateInfo = {
  date: string;
  time: string;
  isNow: boolean;
  isToday: boolean;
  isYesterday: boolean;
};

function getDate(dateString: string): DateInfo {
  const dateObj = new Date(dateString);

  const timeZone = getTimezone();
  const date = dateObj.toLocaleString("en-US", {
    timeZone: timeZone,
    day: "2-digit",
    month: "short",
    year: "numeric",
  });
  const time = dateObj.toLocaleString("en-US", {
    timeZone: timeZone,
    hour: "numeric",
    minute: "2-digit",
  });

  const today = new Date();
  const isToday =
    today.getFullYear() === dateObj.getFullYear() &&
    today.getMonth() === dateObj.getMonth() &&
    today.getDate() === dateObj.getDate();
  const isNow =
    isToday &&
    today.getHours() === dateObj.getHours() &&
    today.getMinutes() === dateObj.getMinutes();

  let isYesterday;
  if (isToday) {
    isYesterday = false;
  } else {
    const yesterday = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate() - 1
    );
    isYesterday =
      yesterday.getFullYear() === dateObj.getFullYear() &&
      yesterday.getMonth() === dateObj.getMonth() &&
      yesterday.getDate() === dateObj.getDate();
  }

  return { date, time, isNow, isToday, isYesterday };
}

function getDisplayableTime(dateInfo: DateInfo) {
  const { date, time, isNow, isToday, isYesterday } = dateInfo;

  if (isNow) {
    return "Now";
  }
  return `${
    isToday ? "Today" : isYesterday ? "Yesterday" : date
  } at ${time.toLocaleUpperCase()}`;
}

export default function CommentComponent({
  requestItemTypeDescriptions = [],
  comment,
}: {
  requestItemTypeDescriptions?: string[];
  comment: Comment;
}) {
  const { createdBy, createdOn, content } = comment;
  const dateInfo = getDate(createdOn);
  const [createdByConverted, setCreatedByConverted] = useState(createdBy);
  const identity = useAppSelector(selectIdentity);

  useEffect(() => {
    if (!createdBy) {
      return;
    }
    readOtherUserData(identity, createdBy)
      .then((otherUserData: User | undefined) => {
        let converted = otherUserData
          ? `${otherUserData.firstName} ${otherUserData.lastName}`
          : createdBy;
        if (converted.trim().length === 0) {
          converted = createdBy;
        }
        setCreatedByConverted(converted);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [identity, createdBy]);

  return (
    <div className="comment">
      {requestItemTypeDescriptions.length > 0 && (
        <span className="comment-type-description-container">
          {requestItemTypeDescriptions.map((requestItemTypeDescription) => {
            return (
              <span className="comment-type-description">
                {requestItemTypeDescription}
              </span>
            );
          })}
        </span>
      )}
      <div className="comment-contents">
        {createdByConverted}
        <div className="comment-time">{getDisplayableTime(dateInfo)}</div>
        <div className="comment-text">{content}</div>
      </div>
    </div>
  );
}
