import { toast } from "react-toastify";
import TagsInput from "react-tagsinput";
import "react-tagsinput/react-tagsinput.css";

import { BROKER_EMAIL_DOMAINS } from "constants/brokerEmails";
import ROLES from "enums/roles";

import "./index.scss";
import { isEmailValid } from "components/utils/inputValidation/email";

const EmailInput = function ({
  setInviteEmailList,
  inviteEmailList,
  inviteRole,
}: {
  setInviteEmailList: (inviteEmailList: string[]) => void;
  inviteEmailList: string[];
  inviteRole: ROLES;
}) {
  return (
    <TagsInput
      className="react-tagsinput invite-tags-input"
      inputProps={{
        className: `react-tagsinput-input invite-tags-input-input`,
        placeholder: "Enter emails to invite to your data room",
      }}
      addKeys={["Tab"]}
      value={inviteEmailList}
      validate={(email: string) => {
        const valid = isEmailValid(email, { displayToast: true });
        if (!valid) return false;

        if (inviteEmailList.includes(email)) {
          toast.warn("The email is already in the list");
          return false;
        }
        const emailDomain = email.split("@")[email.split("@").length - 1];
        const doesContainNewmarkDomain: boolean =
          BROKER_EMAIL_DOMAINS.includes(emailDomain);
        if (doesContainNewmarkDomain && inviteRole === ROLES.CLIENT) {
          toast.warn("This email seems to belong to a broker, not a client");
          return false;
        } else if (!doesContainNewmarkDomain && inviteRole === ROLES.BROKER) {
          toast.warn(
            `Please use a valid email ending in: ${BROKER_EMAIL_DOMAINS.join(
              ", "
            )}`
          );
          return false;
        }
        return true;
      }}
      onChange={(tags: string[]) => {
        setInviteEmailList(tags.map((email: string) => email.toLowerCase()));
      }}
    />
  );
};

export default EmailInput;
