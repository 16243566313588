import { Identity } from "dto/identity";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { toast } from "react-toastify";

import DATA_OPERATIONS from "enums/dataOperations";
import DATA_PROVIDERS from "enums/dataProviders";

function _wait(milliseconds: number) {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
}

export async function _getDefaultConfig(
  identity: Identity,
  dataProvider?: DATA_PROVIDERS
): Promise<AxiosRequestConfig> {
  if (!identity) {
    return {};
  }
  const { identityToken, accessToken, identityProvider } = identity;
  if (!identityToken || !accessToken) {
    toast.error("Your login is outdated. Please log in again");
    return {};
  }
  return {
    headers: {
      IdentityProvider: identityProvider,
      Authorization: accessToken!,
      Identity: identityToken!,
      "Content-Type": "application/json",
      "Data-Provider": (dataProvider || DATA_PROVIDERS.NATIVE).toLowerCase(),
    },
  };
}

export async function makeHttpCall(
  identity: Identity,
  callType: DATA_OPERATIONS,
  endpoint: string,
  {
    data,
    header,
    dataProvider,
    numberOfTries = 1,
    toastMessage,
    progressCallback,
  }: {
      data?: any;
      header?: any;
      dataProvider?: DATA_PROVIDERS;
      numberOfTries?: number;
      toastMessage?: string;
      progressCallback?: (event: any) => void;
  } = {}
): Promise<AxiosResponse> {
  const url = `${process.env.REACT_APP_APIM_GATEWAY_URL}/${endpoint}`;
  const config = header
    ? header
    : await _getDefaultConfig(identity, dataProvider);

  if (progressCallback) {
    config['onUploadProgress'] = progressCallback;
  }

  let response = {},
    func: (...params: any[]) => any,
    params: any[] = [];
  switch (callType) {
    case DATA_OPERATIONS.READ:
      func = axios.get;
      params = [url, config];
      break;
    case DATA_OPERATIONS.CREATE:
      func = axios.post;
      params = [url, data, config];
      break;
    case DATA_OPERATIONS.UPDATE:
      func = axios.put;
      params = [url, data, config];
      break;
    case DATA_OPERATIONS.ARCHIVE:
      func = axios.patch;
      params = [url, data, config];
      break;
    default:
      return response as AxiosResponse;
  }

  let count = 0;
  let error;
  while (count < numberOfTries) {
    try {
      return await func(...params);
    } catch (e) {
      error = e;
      console.error(e);
      count++;
      await _wait(500);
    }
  }
  if (toastMessage) {
    toast.error(toastMessage);
  }
  return Promise.reject(error);
}
