import { ColDef, ICellRendererParams, ValueFormatterParams } from "ag-grid-community";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router";
import { Tabs, TabList, Tab, TabPanel } from "react-tabs";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Container } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import { AgGridReact } from "ag-grid-react";

import "./access.scss";
import { dateFormatter } from "components/utils/columnFormatting/date";
import { Identity } from "dto/identity";
import getAllAccessData from "components/api/accessData";
import { useAppDispatch, useAppSelector } from "hooks";
import INVITE_STATUS from "enums/inviteStatus";
import {
  AccessState,
  selectInvitedAdmins,
  selectInvitedBrokers,
  selectInvitedClients,
} from "slices/access/accessSlice";
import { InviteSummary } from "dto/inviteSummary";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "components/shared/popover";
import ROLES from "enums/roles";
import {
  selectIsAdmin,
  selectIsBroker,
} from "slices/dataRoom/dataRoomSelectedSlice";
import { createInvite, updateInvite } from "components/api/inviteData";
import { updateDataroomUsers } from "components/api/dataroomUsersData";
import { selectIdentity } from "slices/user/identitySlice";
import EmailInput from "components/shared/emailInput";
import Modal from "components/shared/modal";

enum TABS {
  CLIENTS = "Clients",
  BROKERS = "Brokers",
}

type RoleDetails = {
  isAdmin: boolean;
  isBroker: boolean;
  areThereOtherAdmins: boolean;
};

type RoleUpdateDetails = {
  receivingUserId: string;
  dataRoomId: string;
  inviteId: string;
  inviteeRoleInCurrentDataRoom: string;
  email?: string;
};

async function invite(
  identity: Identity,
  dataRoomId: string,
  emails: string[],
  inviteRole: ROLES,
  dispatch: any
): Promise<void> {
  for (const email of emails) {
    let newInviteSummary;
    try {
      newInviteSummary = await createInvite(
        identity,
        dataRoomId,
        inviteRole,
        email
      );
    } catch (e) {
      toast.error(`Failed to invite ${email}`, { autoClose: false });
      return;
    }
    toast.success(`Successfully invited ${email}`);
    dispatch({
      type: "access/add",
      payload: { [dataRoomId]: [newInviteSummary] },
    });
  }
}

async function updateRole(
  identity: Identity,
  newRole: ROLES,
  { receivingUserId, dataRoomId, inviteId }: RoleUpdateDetails
): Promise<void> {
  let success = false;
  try {
    if (receivingUserId) {
      await updateDataroomUsers(identity, receivingUserId, dataRoomId, {
        newRole,
      });
    } else {
      await updateInvite(identity, inviteId, { newRole });
    }
    if (success) {
      toast.success(`Successfully updated to ${newRole}`);
    } else {
      toast.error(`Failed to update to ${newRole}`);
    }
  } catch (e) {
    toast.error(`Failed to update to ${newRole}`);
  }
}

function getRolePopoverContent(
  tabName: string,
  identity: Identity,
  roleUpdateDetails: RoleUpdateDetails,
  { isAdmin, isBroker, areThereOtherAdmins }: RoleDetails,
  closePopover: () => void,
  setModalDetails: (modalDetails: RoleUpdateDetails) => void
): JSX.Element[] {
  const { inviteeRoleInCurrentDataRoom } = roleUpdateDetails;
  const userIsAdmin = inviteeRoleInCurrentDataRoom === ROLES.ADMIN;
  const toReturn: JSX.Element[] = [];
  if (tabName === TABS.BROKERS && isAdmin) {
    if (userIsAdmin) {
      if (areThereOtherAdmins) {
        toReturn.push(
          <li
            key="brokers-remove-admin"
            onClick={() => {
              closePopover();
              setModalDetails(roleUpdateDetails);
            }}
          >
            Remove Admin
          </li>
        );
      } else {
        // Cannot self-unassign admin role when the user is the only admin
      }
    } else {
      toReturn.push(
        <li
          key="brokers-add-admin"
          onClick={() => {
            closePopover();
            updateRole(identity, ROLES.ADMIN, roleUpdateDetails);
          }}
        >
          Add Admin
        </li>
      );
    }
  }
  return toReturn;
}
function getReInvitationPopoverContent(
  identity: Identity,
  dataRoomId: string,
  inviteType: ROLES,
  email: string,
  dispatch: any
) {
  return (
    <li
      key="invitation-resend"
      onClick={() => {
        invite(identity, dataRoomId, [email], inviteType, dispatch);
      }}
    >
      Resend Invitation
    </li>
  );
}
function getColumnDefs(
  tabName: string,
  identity: Identity,
  dataRoomId: string,
  roleDetails: RoleDetails,
  setModalDetails: (details: RoleUpdateDetails) => void,
  dispatch: any
): ColDef[] {
  return [
    {
      field: "email",
      headerName: "Email",
      minWidth: 200,
      flex: 1,
      sortable: true,
      sort: "asc",
      cellRenderer: (params: ICellRendererParams) => {
        return (
          <>
            {params.data.email}
            {params.data.inviteeRoleInCurrentDataRoom === ROLES.ADMIN && (
              <span className="access-admin-tag">ADMIN</span>
            )}
            {params.data.status === INVITE_STATUS.SENT && (
              <span className="access-invited-tag">invited</span>
            )}
          </>
        );
      },
    },
    {
      field: "addedDate",
      headerName: "Date Added",
      valueFormatter: (params: ValueFormatterParams) =>
        dateFormatter(params.data.addedDate ?? params.data.invitedDate),
      sortable: true,
    },
    {
      field: "triple dots",
      headerName: "",
      sortable: false,
      width: 70,
      minWidth: 30,
      cellRenderer: (params: any) => {
        const {
          data: {
            inviteId,
            email,
            userId: receivingUserId,
            inviteeRoleInCurrentDataRoom,
          },
        } = params;
        const closePopover = () => {
          // TODO temp solution to close the Popover
          document.dispatchEvent(
            new KeyboardEvent("keydown", {
              key: "Escape",
            })
          );
        };
        const roleUpdateDetails: RoleUpdateDetails = {
          receivingUserId,
          dataRoomId,
          inviteId,
          inviteeRoleInCurrentDataRoom,
          email,
        };
        const popoverContent = getRolePopoverContent(
          tabName,
          identity,
          roleUpdateDetails,
          roleDetails,
          closePopover,
          setModalDetails
        );
        if (params.data.status === INVITE_STATUS.SENT) {
          popoverContent.push(
            getReInvitationPopoverContent(
              identity,
              dataRoomId,
              inviteeRoleInCurrentDataRoom,
              email,
              dispatch
            )
          );
        }
        if (popoverContent.length === 0) {
          // Don't even show the triple dots if there's nothing to show
          return;
        }
        return (
          <Popover>
            <PopoverTrigger>
              <FontAwesomeIcon icon={faEllipsisVertical} />
            </PopoverTrigger>
            <PopoverContent>
              <ul>{popoverContent}</ul>
            </PopoverContent>
          </Popover>
        );
      },
    },
  ] as ColDef[];
}

export default function AccessComponent() {
  // Setting up all hooks
  const { dataRoomId = "", "*": splat = "" } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const identity = useAppSelector(selectIdentity);
  const [removeConfirmationModalDetails, setRemoveConfirmationModalDetails] =
    useState<RoleUpdateDetails>();
  const [inviteModalType, setInviteModalType] = useState<ROLES>();
  const [inviteEmailList, setInviteEmailList] = useState<string[]>([]);
  const closeInviteModal = () => {
    setInviteModalType(undefined);
    setInviteEmailList([]);
  };

  // Get data from redux
  const roleDetails: RoleDetails = {
    isAdmin: useSelector(selectIsAdmin) as boolean,
    isBroker: useSelector(selectIsBroker) as boolean,
    areThereOtherAdmins: (useSelector(selectInvitedAdmins)?.filter(
      (adminInvite: InviteSummary) =>
        adminInvite.status === INVITE_STATUS.ACCEPTED
    ).length > 1) as boolean,
  };
  const accessData: any = {
    client: useSelector(selectInvitedClients) as InviteSummary[],
    broker: useSelector(selectInvitedBrokers) as InviteSummary[],
  };

  // Set up for tabs
  const tabs = [
    { name: TABS.CLIENTS, type: ROLES.CLIENT, data: accessData.client },
    { name: TABS.BROKERS, type: ROLES.BROKER, data: accessData.broker },
  ];
  const selectedIndex = tabs.map((tab) => tab.name as string).indexOf(splat);

  useEffect(() => {
    // Redirect /Access to /Access/Clients by default
    if (!splat) {
      navigate(`/native/dataRooms/${dataRoomId}/Access/${TABS.CLIENTS}`);
    }
  });
  useEffect(() => {
    // Get data if no access data exists in redux
    if (!accessData.client?.length && !accessData.broker?.length) {
      getAllAccessData(identity, dataRoomId)
        .then((data: InviteSummary[]) => {
          return dispatch({
            type: "access/update",
            payload: { [dataRoomId]: data } as AccessState,
          });
        })
        .catch((error) => console.error("Error fetching data:", error));
    }
  }, [
    dispatch,
    identity,
    dataRoomId,
    accessData.client?.length,
    accessData.broker?.length,
  ]);

  return (
    <Container fluid className="vh-100">
      <Tabs
        // TODO use custom <Tabs /> from src/components/shared/tabs.tsx
        selectedIndex={selectedIndex}
        className="react-tabs data-room-access-tabs"
        selectedTabClassName="react-tabs__tab--selected data-room-access-selected-tab"
        onSelect={(index: number, last: number, event: Event) => {
          const newEndpoint = `/native/dataRooms/${dataRoomId}/Access/${tabs[index].name}`;
          navigate(newEndpoint);
        }}
      >
        {/* Tab names on top */}
        <TabList className="data-room-access-tab-list">
          {tabs.map((tab) => (
            <Tab key={tab.name}>{tab.name}</Tab>
          ))}
        </TabList>

        {/* Tab components (rendered upon selecting the above tab) */}
        {tabs.map((tab) => {
          return (
            <TabPanel
              key={tab.name}
              className="react-tabs__tab-panel data-room-access-tab-panel"
            >
              <div className="ag-theme-custom-react h-100">
                {/* TODO use <DataGrid /> for consistency */}
                <AgGridReact
                  rowData={tab.data}
                  columnDefs={getColumnDefs(
                    tab.name,
                    identity,
                    dataRoomId,
                    roleDetails,
                    setRemoveConfirmationModalDetails,
                    dispatch
                  )}
                />
              </div>
              {roleDetails.isBroker && (
                <div className="d-flex justify-content-center">
                  <button
                    className="mb-3 text-align-center invite-button"
                    onClick={() => setInviteModalType(tab.type as ROLES)}
                  >
                    Invite {tab.name}
                  </button>
                </div>
              )}
            </TabPanel>
          );
        })}
      </Tabs>

      <Modal
        isOpen={!!removeConfirmationModalDetails}
        onConfirm={async () => {
          await updateRole(
            identity,
            ROLES.BROKER,
            removeConfirmationModalDetails as RoleUpdateDetails
          );
        }}
        onClose={() => setRemoveConfirmationModalDetails(undefined)}
        contentLabel="Remove Admin Modal"
        ariaDescription="Confirming that the admin user is removing another admin from their admin role"
        title="Confirmation"
      >
        <span className="remove-admin-modal-text">
          Are you sure you'd like to remove this user
          {removeConfirmationModalDetails?.email &&
            ` (${removeConfirmationModalDetails?.email})`}{" "}
          as an Admin?
        </span>
      </Modal>

      <Modal
        isOpen={!!inviteModalType}
        confirmButtonText="Save"
        confirmButtonProps={{
          disabled: inviteEmailList.length === 0,
          tabIndex: -1, // hitting Tab from <TagsInput> won't select this save button
        }}
        onConfirm={async () => {
          await invite(
            identity,
            dataRoomId,
            inviteEmailList,
            inviteModalType as ROLES,
            dispatch
          );
        }}
        onClose={() => closeInviteModal()}
        hideCloseButton={true}
        contentLabel="Invite Modal"
        ariaDescription="Allowing brokers and admins to invite other brokers and clients into the current data room"
        title={`Invite ${
          tabs.filter((tab) => tab.type === inviteModalType)[0]?.name
        }`}
      >
        <span className="access-modal-text">
          When inviting multiple emails at a time, type in each email and hit
          ‘Tab’.
        </span>
        <EmailInput
          inviteEmailList={inviteEmailList}
          setInviteEmailList={setInviteEmailList}
          inviteRole={inviteModalType as ROLES}
        />
        {inviteEmailList.length === 0 && (
          <span className="access-modal-text">
            Save button is disabled because no emails are provided
          </span>
        )}
        {/* {inviteEmailList.length > 0 && inviteInputValue.length > 0 && (
          <span className="access-modal-text">
            Please press Tab to confirm the email: {inviteInputValue}
          </span>
        )} */}
      </Modal>
    </Container>
  );
}
