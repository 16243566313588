import { useState } from "react";
import { Container, Row, Col } from "reactstrap";
import { ColDef, ValueFormatterParams } from "ag-grid-community";

import "./index.scss";
import Loading from "components/shared/loading";
import CreateDataroomModal from "./createDataRoomModal";
import DataRoomTypeahead from "./dataRoomTypeahead";
import { useAppSelector } from "hooks";
import { selectUser } from "slices/user/userSlice";
import { selectDataRoomList } from "slices/dataRoom/dataRoomListSlice";
import { selectIdentity } from "slices/user/identitySlice";
import DATA_PROVIDERS from "enums/dataProviders";
import DataGrid from "components/shared/grid";
import { dateFormatter } from "components/utils/columnFormatting/date";
import { DataRoom } from "dto/dataRoom";
import { Identity } from "dto/identity";
import { AppDispatch } from "store";
import { NavigateFunction } from "react-router-dom";

type GenericProps<T extends React.HTMLAttributes<HTMLElement>> = T;

export default function NativeDataRoomListComponent<
  T extends React.HTMLAttributes<HTMLElement>
>(props: GenericProps<T>) {
  const { ...rest } = props;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const user = useAppSelector(selectUser);
  const userId: string = user.id;
  const identity: Identity = useAppSelector(selectIdentity);
  const listOfDataRooms: DataRoom[] = useAppSelector(selectDataRoomList);
  const columnDefs: ColDef[] = [
    {
      field: "name",
      headerName: "Data Room Name",
      minWidth: 200,
      flex: 1,
      sortable: true,
      sort: "asc",
    },
    {
      field: "createdDate",
      headerName: "Created",
      valueFormatter: (params: ValueFormatterParams) =>
        dateFormatter(params.data.createdDate),
      sortable: true,
    },
    {
      field: "createdBy",
      headerName: "Created By",
      sortable: true,
    },
  ];

  return (
    <>
      {!userId ? (
        <Loading withoutHeader={true} />
      ) : (
        <div {...rest}>
          <Container
            fluid
            id="native-data-room-list"
            className="native-data-room-parent h-100"
          >
            <Row>
              <Col xs={{ size: 12 }} sm={{ size: 4 }}>
                <button
                  className="mb-3 text-align-center add-button"
                  onClick={() => setIsModalOpen(true)}
                >
                  Add Data Room
                </button>
              </Col>
              <Col xs={{ size: 12 }} sm={{ size: 4 }} md={{ size: 4 }}>
                {listOfDataRooms && (
                  <DataRoomTypeahead
                    dataProvider={DATA_PROVIDERS.NATIVE}
                    dataRooms={listOfDataRooms}
                  />
                )}
              </Col>
            </Row>
            <Row>
              <h2 className="native-data-room-header">Native</h2>
            </Row>
            <Row className="native-data">
              <DataGrid
                data={listOfDataRooms}
                columnDefs={columnDefs}
                navigateWrapper={(navigate: NavigateFunction, data: any) => {
                  navigate(
                    `${DATA_PROVIDERS.NATIVE.toLowerCase()}/dataRooms/${
                      data.id
                    }`
                  );
                }}
                dispatchWrapper={(dispatch: AppDispatch, data: any) => {
                  dispatch({
                    type: "dataRoomSelected/select",
                    payload: data,
                  });
                }}
              />
            </Row>
          </Container>
          <CreateDataroomModal
            identity={identity}
            isModalOpen={isModalOpen}
            closeModal={function () {
              setIsModalOpen(false);
            }}
          />
        </div>
      )}
    </>
  );
}
