// Layout.js
import React from 'react';
import { Container, Row, Col } from 'reactstrap';

// Layout component styles;
import "./authLayout.scss";
import { Header, Footer } from "../components/authHeaderFooter";

interface LayoutProps {
	// Define the Layout children type and header parameters;
	children: React.ReactNode;
	pageTitle: string;
	userEmail?: string;
	optionalText?: string;
}

export const Layout: React.FC<LayoutProps> = ({ children, pageTitle = 'Login', userEmail, optionalText }) => {

	return (
		<Container fluid className="w-100 mb-0 pb-0">
			<Row>
				<Col className="d-grid p-0 auth__page">
					{/* CONTENT WRAPPER; */}
					<Row className="m-auto h-100 w-100 auth__page-content">
						{/* Col: Spacer; */}
						<Col sm={{ size: 1 }} className="d-none d-sm-block"></Col>

						{/* Col: Header Content; */}
						<Col xs={{ size: 12 }} sm={{ size: 10}} className="px-0 auth__central">
							<Row className="m-auto w-100">
								{/* Background image; */}
								<Col className="d-flex justify-content-center auth__container-image">
									<Container fluid className="justify-content-center auth auth__container">

										{/* Header; */}
										<Header pageTitle={pageTitle} userEmail={userEmail} optionalText={optionalText}/>

										{/* Main; */}
										<main className="container-fluid m-auto my-0 py-0 auth__main">{children}</main>
									</Container>
								</Col>
							</Row>										
						</Col>
						
						{/* Col: Spacer; */}
						<Col sm={{ size: 1 }} className="d-none d-sm-block"></Col>
					</Row>
					{/* FOOTER; */}
					<Footer/>
				</Col>
			</Row>
		</Container>
	);
};

//export default Layout;