import { MimeType } from "dto/mimeType";

export type ValuationsType = {
  id: number;
  description: string;
};

export const RequestItemStatusUpdating: ValuationsType = {
  id: -2, // picked arbitrarily
  description: "Status Updating",
};

export type ValuationsSystemInfo = {
  requestDetailsMessage: string;
  maxUploadFileSize: number;
  mimeTypes: MimeType[];
  requestItemCategoryTypes: ValuationsType[];
  requestItemStatusTypes: ValuationsType[];
  storageTypes: ValuationsType[];
};
