import { HashRouter, Routes, Route, Navigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useAppSelector } from "hooks";
import Error from "error";
import Login from "auth/login";
import ExchangeOneTimePasscode from "auth/exchangeOneTimePasscode";
import Register from "auth/registerUser";
import Hydrator from "auth/hydrator";
import { selectIsLoggedIn } from "slices/user/identitySlice";
import { RedirectLoad, RedirectSave } from "auth/redirect";
import LandingPage from "components/main/landingPage";
import NativeDataRoomComponent from "components/main/native/dataRoom";
import ValuationsDataRoomComponent from "components/main/valuations/dataRoom";
import PageLayout from "components/main/pageLayout";
import PageLayoutLoggedOut from "components/main/pageLayout/pageLayoutLoggedOut";

function App() {
  const isLoggedIn = useAppSelector(selectIsLoggedIn);
  return isLoggedIn ? (
    <>
      <RedirectLoad />
      <HashRouter>
        <Hydrator />
        <Routes>
          <Route element={<PageLayout />}>
            {" "}
            {/* THIS NESTED ROUTING IS INTENTIONAL */}
            {/* <Route path="/privacy" element={<Privacy />} /> */}{" "}
            {/*TODO: Implement privacy/tos */}
            {/* <Route path="/tos" element={<Tos />} /> */}
            <Route path="" element={<LandingPage />} />
            <Route path="/" element={<LandingPage />} />
            <Route path="/login" element={<Navigate to="/" />} />
            <Route
              path="/native/dataRooms/:dataRoomId"
              element={<Navigate to="Documents" />}
            >
              {/* Redirects to /native/dataRooms/:dataRoomId/Documents */}
            </Route>
            <Route
              path="/native/dataRooms/:dataRoomId/:tabName/*"
              element={<NativeDataRoomComponent />}
            />
            <Route
              path="/valuations/dataRooms/:jobAssignmentPropertyId"
              element={<Navigate to="RequestedItems" />}
            >
              {/* Redirects to /valuations/dataRooms/:jobAssignmentPropertyId/RequestedItems */}
            </Route>
            <Route
              path="/valuations/dataRooms/:jobAssignmentPropertyId/:tabName/*"
              element={<ValuationsDataRoomComponent />}
            />
            {/* Catch-All */}
            <Route path="/*" element={<Error />} />
          </Route>
        </Routes>
      </HashRouter>
      <ToastContainer theme="colored" />
    </>
  ) : (
    <>
      <RedirectSave />
      <HashRouter>
        <Routes>
          <Route element={<PageLayoutLoggedOut />}>
            {/*Important: Leave the "" path set to login, or the convergence from AzureAD breaks */}
            <Route path="" element={<Login />} />
            {/* Important: Leave the "/*" path set to login, or the convergence from AzureAD breaks */}
            <Route path="/*" element={<Login />} />
            <Route path="/login/otp" element={<ExchangeOneTimePasscode />} />
            <Route path="/register" element={<Register />} />
          </Route>
        </Routes>
      </HashRouter>
      <ToastContainer theme="colored" />
    </>
  );
}

export default App;
