import { Assignment } from "dto/assignment";
import JobAssignmentStatus from "enums/jobAssignmentStatus";

const assignmentListData: Assignment[] = [
  {
    jobId: 205480,
    jobName: "jobName",
    jobAssignmentNumber: "24-0205480-1",
    jobAssignmentGroupId: 209497,
    jobAssignmentPropertyId: 284386, // also rendered in the URL like /valuations/dataRooms/284386/RequestedItems
    jobAssignmentDueDate: "01/01/2025",
    jobAssignmentStatus: JobAssignmentStatus.ACTIVE,
    jobAssignmentRequestedItemsTotalCount: 37,
    jobAssignmentRequestedItemsUploadedCount: 1,
    jobAssignmentClientReference: "clientReference",
    propertyEventId: 1064239,
    propertyName: "The Collection I",
    propertyAddress1: "2202 Moser Avenue",
    propertyAddress2: "",
    propertyCity: "Dallas",
    propertyState: "TX",
    propertyCounty: "Dallas",
    propertyZip: "75206",
    propertyCountryName: "USA",
    propertyTypeId: 5,
    propertyTypeDescription: "Multifamily",
    propertySubtypeId: 103,
    propertySubtypeDescription: "Townhome",
    companyId: 18489,
    companyName: "120TH & CHASE LLC",
    companyAddress1: "One Aspen Drive #86",
    companyAddress2: "",
    companyCity: "Loveland",
    companyState: "CO",
    companyZip: "80538",
    companyCountry: "USA",
    clientPortalContactEmail: "first.last@nmrk.com",
    jobAssignmentLeadAppraisers: ["leadappraiser"],
    clientPortalResponsibleContactsEmail: "",
  },
];
export { assignmentListData };
