// Slice for user data
// Slices are combined actions and reducers
// more at https://react-redux.js.org/tutorials/typescript-quick-start#define-slice-state-and-action-types

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "store";

const initialState = {
    id: -1,
};

export const folderListExpandFolderSlice = createSlice({
    name: "folderListExpandFolder",
    initialState,
    reducers: {
        expandFolder(state, action: PayloadAction<number>) {
            state.id = action.payload
        },

    },
});
export const { expandFolder } = folderListExpandFolderSlice.actions;


export const selectExpandedFolder = (state: RootState): number =>
    state.folderListExpandFolder.id;

export default folderListExpandFolderSlice.reducer;
