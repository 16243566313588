import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Col, Container, Row } from "reactstrap";

import Tabs from "components/shared/tabs";
import { readDataRoomData } from "components/api/dataroomData";
import { DataRoom } from "dto/dataRoom";
import Loading from "components/shared/loading";
import { selectDataRoomSelected } from "slices/dataRoom/dataRoomSelectedSlice";
import Documents from "./documents/documents";
import Tourbooks from "./tourbooks";
import Intelligence from "./intelligence";
import AccessComponent from "./access";
import "./index.scss";
import { useAppDispatch, useAppSelector } from "hooks";
import { selectIdentity } from "slices/user/identitySlice";
import { selectUser } from "slices/user/userSlice";

export default function NativeDataRoomComponent() {
  const identity = useAppSelector(selectIdentity);
  const params = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const dataRoomData = useSelector(selectDataRoomSelected);
  const tabs = [
    { name: "Documents", component: Documents },
    { name: "Tourbooks", component: Tourbooks },
    { name: "Intelligence", component: Intelligence },
    { name: "Access", component: AccessComponent },
  ];
  const selectedIndex = tabs
    .map((tab) => tab.name)
    .indexOf(params["tabName"] || "");

  useEffect(() => {
    if (!params["tabName"]) {
      navigate(`/native/dataRooms/${params.dataRoomId}/Documents`);
    }
  });
  let user = useAppSelector(selectUser);

  useEffect(() => {
    if (!dataRoomData?.id) {
      // Coming to the page directly, not from the data room list
      readDataRoomData(identity, user, params.dataRoomId || "")
        .then((data: DataRoom | undefined) => {
          dispatch({
            type: "dataRoomSelected/select",
            payload: data,
          });
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          if (error.response?.status === 400) {
            toast.error("You don't have access to this data room");
            navigate("/");
          }
        });
    }
  }, [dataRoomData?.id, dispatch, identity, navigate, params.dataRoomId, user]);
  return (
    <>
      {!dataRoomData?.id ? (
        <Loading />
      ) : (
        <Container fluid className="header">
          <Row>
            <Col xs={12}>
              <Row>
                <Col>
                  <Row>
                    <Col xs={6} sm={6} md={6} lg={6} xl={6} className="ps-0 ">
                      <span className="data-room-title">
                        Data Room: {dataRoomData?.name}
                      </span>
                    </Col>
                    <Col xs={2} sm={2} md={2} lg={3} xl={3}></Col>
                    <Col
                      xs={4}
                      sm={4}
                      md={4}
                      lg={3}
                      xl={3}
                      className="pe-0 align-self-end"
                    >
                      <button
                        className="data-room-back-to-data-rooms"
                        onClick={() => navigate("/")}
                      >
                        Back to Data Rooms
                      </button>
                    </Col>
                  </Row>
                </Col>
                <Tabs
                  tabs={tabs}
                  selectedIndex={selectedIndex}
                  onSelectWrapper={({ index, navigate }) => {
                    const newEndpoint = `/native/dataRooms/${params.dataRoomId}/${tabs[index].name}`;
                    navigate(newEndpoint);
                  }}
                />
              </Row>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
}
