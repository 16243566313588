import { toast } from "react-toastify";

export function isEmailValid(
  email: string,
  {
    displayToast,
    isForValuations,
  }: { displayToast?: boolean; isForValuations?: boolean } = {}
): boolean {
  email = email.toLowerCase();
  const valuationsRegex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
  const inHouseEmailRegex = /^.+@[a-zA-Z0-9\-\.\[\]\(\)]+$/;
  if (
    (isForValuations && !valuationsRegex.test(email)) ||
    !inHouseEmailRegex.test(email)
  ) {
    /* inHouseEmailRegex: checking if the email is in __@__ format
     *
     * the first/local part is left open (comprehensive regex will be too lengthy)
     * the @ symbol is required
     * the second/domain part has a limited check for LDH (letters, digits, hyphen), dot, and lastly [] and () for rare case
     *   - the domain can be dotless
     *
     * source: https://en.wikipedia.org/wiki/Email_address#Syntax
     */
    if (displayToast) {
      toast.warn(`The email is invalid: ${email}`);
    }
    return false;
  }
  return true;
}
