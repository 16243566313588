import { Row, Col } from "reactstrap";

import { Layout } from "../pageLayout/authLayout";
import { useNavigate, useLocation } from "react-router";
import { useEffect, useRef, useState } from "react";
import { useAppDispatch } from "hooks";
import {
  trackEvent,
  MatomoEventCategories,
  MatomoActions,
  setUserEmail,
} from "matomo";

import {
	exchangeOneTimePasscode,
	sendOneTimePasswordRequest,
} from "components/api/identityProviderWrapper";

import { getClaim } from "slices/user/identitySlice";
import { readUserData } from "components/api/userData";
import { Identity } from "dto/identity";
import { toast, Bounce } from "react-toastify";
import IDENTITY_PROVIDERS from "enums/identityProviders";

// Optional Legal Text;
import { appInfo } from "constants/termsLinks";
import { getTimezone } from "components/utils/datetime/datetime";
const NM_OPTIONAL_LEGAL = appInfo.NM_OPTIONAL_LEGAL;

export default function ExchangeOneTimePasscode() {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const {
		state: { email },
	} = useLocation();

	// Create unique ref for OTP input;
	const otpInputRef = useRef<HTMLInputElement>(null);
	useEffect(() => {
		otpInputRef.current?.focus();
	});

	const [oneTimePasscode, setOneTimePasscode] = useState("");
	const [loading, setLoading] = useState(false);
	const submitOtpButtonRef = useRef<HTMLButtonElement>(null);

	return (
		<div className="m-0 p-0 auth__page-wrapper ExchangeOneTimePasscode">
			<Layout pageTitle="Verification" userEmail={email}>
				<Row>
					{/* Col: Main Content (OTP); */}
					<Col className="d-flex flex-column pt-4 justify-content-center auth__main-form">
						{/* OTP Content; */}
						<Row>
							<Col className="px-4 mb-0 d-flex flex-column">
								<p className="auth__main-text">
									Verify your email by entering the 6-digit <strong className="nowrap">One-Time Password (OTP)</strong> you received at <em className="nowrap">{email}</em>.
								</p>
							</Col>
						</Row>

						{/* OTP Input; */}
						<Row>
							<Col className="px-4 mb-0 d-flex flex-column">
								<label
									id="labelOTP" 
									htmlFor="inputOTP" 
									className="pb-1 auth__main-label minmax">
									OTP
								</label>
								<input
									type="number"
									id="inputOTP" 
									ref={otpInputRef}
									className="auth__main-input minmax custom-focus"
									aria-labelledby="labelOTP"
									onChange={(e) => setOneTimePasscode(e.target.value)}
									onKeyUp={(e) => {
										if (
											oneTimePasscode.length === 6 &&
											e.code === "Enter" &&
											submitOtpButtonRef.current
										) {
											submitOtpButtonRef.current.click();
										}
									}}
								/>
							</Col>
						</Row>

						{/* Actions; */}
						<Row>
							<Col className="px-4 m-0 pt-4 d-flex flex-column">
								<button
									className="btn btn-cx-primary minmax auth__main-btn"
									disabled={oneTimePasscode.length !== 6}
									ref={submitOtpButtonRef}
									onClick={async function () {
									setLoading(true);
									let tokens;
									try {
										tokens = await exchangeOneTimePasscode(email, oneTimePasscode);
									} catch (e) {
										toast.error("Invalid One-Time Password", {
											position: "top-center",
											autoClose: 5000,
											closeOnClick: true,
											pauseOnHover: true,
											draggable: false,
											theme: "colored",
											transition: Bounce,
											}
										);
										setLoading(false);
										return;
									}
									const exp = getClaim(tokens.accessToken, "exp");
									const identity = {
										accessToken: tokens.accessToken,
										refreshToken: tokens.refreshToken,
										identityToken: tokens.identityToken,
										identityProvider: IDENTITY_PROVIDERS.CONNEXUS,
										exp: parseInt(exp) * 1000,
										obtained: Date.now(),
									} as Identity;
									
									let performingRegister = false;
									const user = await readUserData(identity).catch((e) => {
										setLoading(false);
										performingRegister = true;
										navigate("/register", {
											state: { email: email, identity: identity },
										});
									});

									if (performingRegister) {
										return;
									}

									setLoading(true);

									dispatch({
										type: "identity/setTokens",
										payload: identity,
									});

									dispatch({
										type: "user/setUser",
										payload: user,
									});

									setUserEmail(user!.email);
									trackEvent(
										MatomoEventCategories.Login,
										MatomoActions.Login,
										getTimezone()
									);
									navigate(`/`);
								}}
							>
								{/* Show spinner on button; */}
								{loading ? (
									<span className="auth__main-spinner" title="Please wait..."></span>
								) : (
									<span>Login</span>
								)}
								</button>			
							</Col>
						</Row>

						{/* Warning Text; */}
						<Row>
							<Col className="px-4 mb-4 d-flex flex-column">
								<p className="pt-4 pb-0 m-0 auth__main-text auth__main-text--special">
									Your OTP expires in <strong>10 minutes</strong>. Didn&#8217;t get the OTP? Please give it a minute, check your spam folder, or{" "}
									<u
										className="auth__main-text--special-link"
										onClick={async function () {
											try {
												setLoading(false);

												toast.info("Sending new One-Time Passcode This may take 1-3 minutes", {
													position: "top-center",
													autoClose: 5000,
													closeOnClick: true,
													pauseOnHover: true,
													draggable: false,
													theme: "colored",
													transition: Bounce,
													}
												);
												await sendOneTimePasswordRequest(email);
											} catch (e) {
												toast.error("Failed to send One-Time Password", {
													position: "top-center",
													autoClose: 5000,
													closeOnClick: true,
													pauseOnHover: true,
													draggable: false,
													theme: "colored",
													transition: Bounce,
													}
												);
												return;
											}
										}}
									>
										request a new OTP
									</u>
									.
								</p>							
							</Col>
						</Row>
					</Col>
				</Row>
				{/* Optional Legal Text; */}
				{typeof NM_OPTIONAL_LEGAL.props.children !== 'undefined' && (
				<Row>
					<p className="lh-1 px-0 pt-4 pb-0 auth__optional-legal">
						{NM_OPTIONAL_LEGAL}
					</p>
				</Row>
				)}
			</Layout>
		</div>
	);
}
