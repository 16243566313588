// Slice for user data
// Slices are combined actions and reducers
// more at https://react-redux.js.org/tutorials/typescript-quick-start#define-slice-state-and-action-types

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { DataRoom } from 'dto/dataRoom'
import { RootState } from 'store';

const initialState: DataRoom[] = []

export const dataRoomListSlice = createSlice({
    name: 'dataRoomList',
    initialState,
    reducers: {
        load(state, action: PayloadAction<DataRoom[]>) {
            const newData = action.payload.filter(item => !state.some(existingItem => existingItem.id === item.id));
            state.push(...newData);
        },
        add(state, action: PayloadAction<DataRoom>) {
            state.push(action.payload);
        },
        clearDataRoomList(state) {
            while (state.length) { state.pop(); }
        }
       
    }
});
export const { load, add } = dataRoomListSlice.actions
export const selectDataRoomList = (state: RootState): DataRoom[] => state.dataRoomList;

export default dataRoomListSlice.reducer;

