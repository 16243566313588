import { useMemo, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";

import styles from "./breadcrumb.module.scss";
import {
  Breadcrumb,
  selectBreadcrumb,
} from "slices/breadcrumb/breadcrumbSlice";
import { selectAssignmentSelected } from "slices/assignment/assignmentSelectedSlice";

export default function BreadcrumbComponent() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { jobAssignmentPropertyId } = useParams();
  const { propertyName } = useSelector(selectAssignmentSelected);

  const paths = useMemo(
    () => ({
      assignments: { text: "ASSIGNMENTS", path: "/" },
      assignment: {
        text: propertyName?.toUpperCase(),
        path: `/valuations/dataRooms/${jobAssignmentPropertyId}`,
      },
      requestedItems: {
        text: propertyName?.toUpperCase(),
        path: `/valuations/dataRooms/${jobAssignmentPropertyId}/RequestedItems`,
      },
      comments: {
        text: propertyName?.toUpperCase(),
        path: `/valuations/dataRooms/${jobAssignmentPropertyId}/Comments`,
      },
    }),
    [jobAssignmentPropertyId, propertyName]
  );

  const breadcrumb = useSelector(selectBreadcrumb);

  // Handle either onClick or onKeyDown Breadcrumb actions;
  const handleBreadcrumbAction = (
    event:
      | React.MouseEvent<HTMLButtonElement>
      | React.KeyboardEvent<HTMLButtonElement>,
    path: string
  ) => {
    // Check if the event is a keyboard event and if Enter or Space was pressed
    if (
      event.type === "keydown" &&
      (event as React.KeyboardEvent<HTMLButtonElement>).key !== "Enter" &&
      (event as React.KeyboardEvent<HTMLButtonElement>).key !== " " &&
      (event as React.KeyboardEvent<HTMLButtonElement>).code !== "Space"
    ) {
      return; // If it's a keydown event but not Enter or Space, do nothing
    }

    event.preventDefault(); // Prevent default link behavior or keydown behavior
    navigate(path); // Use the navigate function from useNavigate hook
  };

  const stringifyBreadcrumb = useCallback((breadcrumb: Breadcrumb[]) => {
    return breadcrumb.map((cur) => `_${cur.text}_${cur.path}`).join("");
  }, []);

  const updateBreadcrumb = useCallback(() => {
    let newBreadcrumb: Breadcrumb[] = [];
    switch (pathname) {
      case "":
      case paths.assignments.path:
        newBreadcrumb = [paths.assignments];
        break;
      case paths.assignment.path:
      case paths.requestedItems.path:
      case paths.comments.path:
        newBreadcrumb = [paths.assignments, paths.assignment];
        break;
      default:
        break;
    }

    const didBreadcrumbChange =
      stringifyBreadcrumb(newBreadcrumb) !== stringifyBreadcrumb(breadcrumb);
    if (didBreadcrumbChange) {
      dispatch({
        type: "breadcrumb/override",
        payload: newBreadcrumb,
      });
    }
  }, [pathname, paths, breadcrumb, dispatch, stringifyBreadcrumb]);

  useEffect(() => {
    updateBreadcrumb();
  }, [updateBreadcrumb]);

  return (
    <nav className="m-0 p-0 breadcrumb" aria-label="Breadcrumb">
      <ol
        className={`d-flex m-0 lh-1 flex-wrap align-items-center px-0 ${styles.breadcrumb__parent}`}
      >
        {breadcrumb.map(({ text, path }, index) => {
          return (
            /* Do not add click handler if current breadcrumb; */
            <li
              key={text + path}
              className="position-relative d-inline-flex lh-1 text-nowrap align-items-center user-select-none"
            >
              {/* Breadcrumb; */}
              {index !== breadcrumb.length - 1 ? (
                <button
                  type="button"
                  onClick={(event) => handleBreadcrumbAction(event, path)}
                  onKeyDown={(event) => handleBreadcrumbAction(event, path)}
                  className={`lh-1 px-0 text-nowrap ${styles.breadcrumb}`}
                  tabIndex={0}
                >
                  {text}
                </button>
              ) : (
                <span
                  className={`lh-1 px-0 text-nowrap ${styles.breadcrumb} ${styles.current}`}
                  aria-current="page"
                >
                  {text}
                </span>
              )}
            </li>
          );
        })}
      </ol>
    </nav>
  );
}
