import { useState } from "react";
import { toast } from "react-toastify";

import "./createDataRoomModal.scss";
import { Identity } from "dto/identity";
import { createDataRoomData } from "components/api/dataroomData";
import { useAppDispatch, useAppSelector } from "hooks";
import { selectUser } from "slices/user/userSlice";
import EmailInput from "components/shared/emailInput";
import ROLES from "enums/roles";
import { createInvite } from "components/api/inviteData";
import Modal from "components/shared/modal";

export default function CreateDataRoomModal({
  identity,
  isModalOpen,
  closeModal,
}: {
  identity: Identity;
  isModalOpen: boolean;
  closeModal: () => void;
}) {
  const [newDataRoomName, setNewDataRoomName] = useState("");
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);

  const [brokerEmailInviteList, setBrokerEmailInviteList] = useState<string[]>(
    []
  );

  const [clientEmailInviteList, setClientEmailInviteList] = useState<string[]>(
    []
  );
  return (
    <Modal
      isOpen={isModalOpen}
      onConfirm={async () => {
        try {
          const dataroomResult = await createDataRoomData(
            identity,
            user,
            newDataRoomName
          );
          if (!dataroomResult) {
            toast.error("Failed to create data room");
            return;
          }
          dispatch({ type: "dataRoomList/add", payload: dataroomResult });
          toast.success("Successfully created data room");

          const brokerPromises = brokerEmailInviteList.map((email) =>
            createInvite(identity, dataroomResult?.id, ROLES.BROKER, email)
          );

          const clientPromises = clientEmailInviteList.map((email) =>
            createInvite(identity, dataroomResult?.id, ROLES.CLIENT, email)
          );

          Promise.allSettled(brokerPromises).then((results) => {
            if (results.find((x) => x.status === "rejected")) {
              toast.error(
                "Failed to invite brokers to data room; please review access tab"
              );
              return;
            }
            toast.success("Successfully invited brokers");
          });
          Promise.allSettled(clientPromises).then((results) => {
            if (results.find((x) => x.status === "rejected")) {
              toast.error(
                "Failed to invite clients to data room; please review access tab"
              );
              return;
            }
            toast.success("Successfully invited clients");
          });
        } catch (e) {
          toast.error("Failed to create data room");
          return;
        }
        setNewDataRoomName("");
      }}
      confirmButtonProps={{
        disabled: newDataRoomName === "",
        title: newDataRoomName === "" ? "Name is required" : "",
      }}
      confirmButtonText="Add Data Room"
      onClose={() => closeModal()}
      hideCloseButton={true}
      className="add-modal"
      contentLabel="Create Data Room Modal"
      ariaDescription="Users are able to create a new data room using this modal and can invite users at the same time"
      title="Data Room Name"
    >
      <input
        className="add-modal-input"
        placeholder="Name this data room"
        value={newDataRoomName}
        onChange={(e) => setNewDataRoomName(e.target.value)}
      />
      <label className="add-modal-label">Invite Brokers</label>
      <EmailInput
        inviteEmailList={brokerEmailInviteList}
        setInviteEmailList={setBrokerEmailInviteList}
        inviteRole={ROLES.BROKER}
      />
      <label className="modal-label">Invite Clients</label>
      <EmailInput
        inviteEmailList={clientEmailInviteList}
        setInviteEmailList={setClientEmailInviteList}
        inviteRole={ROLES.CLIENT}
      />
    </Modal>
  );
}
