import { useAppDispatch, useAppSelector } from "hooks";
import {
  selectIsLoggedIn,
  selectConvergenceRedirect,
} from "slices/user/identitySlice";

export const RedirectSave = function () {
  const isLoggedIn = useAppSelector(selectIsLoggedIn);
  const dispatch = useAppDispatch();
  if (!isLoggedIn) {
    const toRedirect = window.location.href.split(window.location.origin)[1];
    const isAzureAdLogin = toRedirect.startsWith("/#code=");
    if (toRedirect && !isAzureAdLogin && toRedirect !== "/") {
      dispatch({
        type: "identity/setConvergenceRedirect",
        payload: toRedirect,
      });
    }
  }
  return <> </>;
};
export const RedirectLoad = function () {
  const isLoggedIn = useAppSelector(selectIsLoggedIn);
  const convergenceRedirect = useAppSelector(selectConvergenceRedirect);
  const dispatch = useAppDispatch();

  if (isLoggedIn) {
    const toRedirect = convergenceRedirect;
    if (toRedirect) {
      dispatch({
        type: "identity/setConvergenceRedirect",
        payload: null,
      });
      window.location.href = window.location.origin + toRedirect;
    }
  }
  return <> </>;
};
