import { useCallback } from "react";
import { FileError, FileWithPath, useDropzone } from "react-dropzone";

import "./dragAndDropZone.scss";
import { FileToUpload } from "dto/fileWithUploadStatus";
import UPLOAD_STATUS from "enums/uploadStatus";

export default function DragAndDropZone({
  accept = {},
  validator = () => null,
  children,
  files = [],
  setFiles,
}: {
  accept?: { [k: string]: string[] };
  validator?: (file: FileWithPath) => FileError | null;
  children?: any;
  files?: FileToUpload[];
  setFiles: (files: FileToUpload[]) => void;
}) {
  const onDrop = useCallback(
    (acceptedFiles: FileToUpload[]) => {
      acceptedFiles = acceptedFiles.filter(
        (file: FileToUpload) =>
          // no duplicate file names
          files.map((f) => f.name).indexOf(file.name) === -1
      );
      acceptedFiles = acceptedFiles.map((file: FileToUpload) => {
        file.uploadStatus = UPLOAD_STATUS.PENDING;
        return file;
      });
      setFiles([...files, ...acceptedFiles]);
    },
    [files, setFiles]
  );

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isFocused,
    inputRef,
  } = useDropzone({
    onDrop,
    accept: accept,
    validator: validator,
    multiple: true,
    noClick: true, // binding `open` to a specific element (below) and preventing a click on the root element
  });
  const inputProps = getInputProps();
  inputProps.accept = inputProps.accept?.replace(
    "application/octet-stream,",
    ""
  ); // including octet-stream allows all kinds of extensions e.g. .EXE

  return (
    <section>
      <div
        className={`drag-and-drop-container ${
          isDragAccept
            ? "drag-and-drop-accept"
            : isFocused
            ? "drag-and-drop-focused"
            : ""
        }`}
        {...getRootProps()}
      >
        <input {...inputProps} />
        <div className="drag-and-drop-icon"></div>
        {isDragActive ? (
          <p className="drag-and-drop-title">Drop the files here</p>
        ) : (
          <p className="drag-and-drop-title">
            Drop files here, or{" "}
            <span
              className="drag-and-drop-browse"
              onClick={() => {
                inputRef.current?.click(); // using `open()` doesn't seem to respect the `accept` attribute for <input />
              }}
            >
              browse your device
            </span>
          </p>
        )}
        {children}
      </div>
    </section>
  );
}
