import { NavigateFunction, useNavigate } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs as ReactTabs } from "react-tabs";
import "react-tabs/style/react-tabs.css";

import "./tabs.scss";

export default function Tabs({
  tabs,
  selectedIndex,
  onSelectWrapper = () => {},
}: {
  tabs: {
    name: string;
    component: (props: any) => JSX.Element;
    props?: any;
    count?: number;
  }[];
  selectedIndex: number | undefined;
  onSelectWrapper?: (params: {
    index: number;
    last: number;
    event: Event;
    navigate: NavigateFunction;
  }) => void;
}) {
  const navigate = useNavigate();

  return (
    <ReactTabs
      selectedIndex={selectedIndex}
      selectedTabClassName="react-tabs__tab--selected tabs-selected-tab"
      onSelect={(index: number, last: number, event: Event) =>
        onSelectWrapper({ index, last, event, navigate })
      }
    >
      {/* Tab names on top */}
      <TabList className="tabs-tab-list">
        {tabs.map((tab) => {
          let tabName = tab.name;
          tabName = tabName.replace(/([A-Z])/g, " $1"); // e.g. "RequestedItems" -> "Requested Items"
          if (typeof tab.count === "number") {
            tabName += ` (${tab.count})`;
          }
          return <Tab key={tab.name}>{tabName}</Tab>;
        })}
      </TabList>

      {/* Tab components (rendered upon selecting the above tab) */}
      {tabs.map((tab) => {
        const TabComponent = tab.component;
        return (
          <TabPanel key={tab.name}>
            <TabComponent {...tab.props} />
          </TabPanel>
        );
      })}
    </ReactTabs>
  );
}
