import { useState } from "react";
import { useLocation } from "react-router-dom";
import { createUserData, readUserData } from "components/api/userData";
import { useNavigate } from "react-router";
import { useAppDispatch } from "hooks";
import { getClaim } from "slices/user/identitySlice";
import { User } from "dto/user";
import { Identity } from "dto/identity";
import { Container, Row, Col } from "reactstrap";
import { Layout } from "../pageLayout/authLayout";
import { toast, Bounce } from "react-toastify";
import { appInfo } from "../../constants/termsLinks";
import {
	MatomoActions,
	MatomoEventCategories,
	setUserEmail,
	trackEvent,
  } from "matomo";
import { getTimezone } from "components/utils/datetime/datetime";

// Common Terms / Links;
const NEWMARK_NAME = appInfo.NEWMARK_NAME;
const NM_PRIVACY = appInfo.NM_PRIVACY;

export default function RegisterUserComponent() {
	const { state } = useLocation();
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const identity = state.identity as Identity;
	const [loading, setLoading] = useState(false);
	const REGISTRATION_ERROR = "Error during registration; please try again";
	const showToastError = (message: string) => { 
		toast.error(message, {
			position: "top-center",
			autoClose: 5000,
			closeOnClick: true,
			pauseOnHover: true,
			draggable: false,
			theme: "colored",
			transition: Bounce,
			}
		);
	}
	const onRegisterButtonClick = async function () {
		setLoading(true);

		try {
			await createUserData(identity, {
				firstName: firstName,
				lastName: lastName,
				email: state.email,
				id: getClaim(identity.identityToken, "oid"),
			} as User);
		} catch (e) {
			setLoading(false);
			showToastError(REGISTRATION_ERROR);
			// Not returning in case the user was created in previous attempt. Proceed.
		}

		let user;
		
		try {
			user = await readUserData(identity);
		} catch (e) {
			setLoading(false);
			showToastError(REGISTRATION_ERROR);
			return;
		}

		setLoading(false);
		dispatch({
			type: "identity/setTokens",
			payload: identity,
		});

		dispatch({
			type: "user/setUser",
			payload: user,
		});

		setUserEmail(user!.email);
		trackEvent(
			MatomoEventCategories.Login,
			MatomoActions.Login,
			getTimezone()
		);
		navigate("/");
	};

	return (
		<Container fluid className="m-0 p-0 auth__page-wrapper">
			<Layout pageTitle="Registration" userEmail={state.email} optionalText="*Mandatory Field">
				<Row>
					{/* Col: Main Content (OTP); */}
					<Col className="d-flex flex-column py-4 justify-content-center auth__main-form">
						{/* Input: First and Last Name; */}
						<Row>
							<Col className="px-4 py-0 mb-0">
								<div className="m-0 p-0 auth__main-name minmax">
									<div className="m-0 p-0 d-flex flex-column auth__main-name--first">
										<label
											id="labelFirstName" 
											htmlFor="inputFirstName" 
											className="pb-1 auth__main-label required"
										>
											First Name
										</label>
										<input
											id="inputFirstName"
											className="auth__main-input"
											type="text"
											placeholder="First Name"
											aria-labelledby="labelFirstName"
											required
											onChange={(e) => setFirstName(e.target.value)}
										></input>
									</div>
							
									<div className="m-0 p-0 d-flex flex-column auth__main-name--last">
										<label
											id="labelLastName" 
											htmlFor="inputLastName" 
											className="pb-1 auth__main-label required"
										>
											Last Name
										</label>
										<input
											id="inputLastName"
											className="auth__main-input custom-focus"
											type="text"
											placeholder="Last Name"
											aria-labelledby="labelLastName"
											required
											onChange={(e) => setLastName(e.target.value)}
										></input>
									</div>
								</div>
							</Col>
						</Row>

						{/* Input: Phone; */}
						<Row>
							<Col className="px-4 py-4 mb-0 d-flex flex-column">
								<label
									htmlFor="inputPhone" 
									className="pb-1 auth__main-label"         
								>
									Phone
								</label>
								<input
									id="inputPhone"
									className="auth__main-input"
									type="tel"
									aria-labelledby="labelPhone"
								></input>
							</Col>
						</Row>

						{/* Input: Data Collection; */}
						<Row>
							<Col className="px-4 mb-0 pt-2 pb-2 auth__main-option">
								<div className="position-relative d-grid gap-2 auth__main-checkbox-container">
									<input
										type="checkbox"
										id="chkAgreement"
										className="auth__main-checkbox"
										aria-labelledby="labelAgreement"
									/>
									<label
										id="labelAgreement"
										htmlFor="chkAgreement"
										className="auth__main-checkbox-label"
									>
										By submitting your data through this form, you confirm you are above the age of 18, you have read and understood the Privacy Policy*, and you agree to the collection, use and processing of your personal information by {NEWMARK_NAME} in accordance with said policy.
									</label>
								</div>
							</Col>
						</Row>

						{/* Privacy Policy Link; */}
						<Row>
							<Col className="px-4 mb-0 pt-0 pb-2 auth__main-option">
								{/* Separate link from label text for accessibility; */}
								<p className="auth__main-checkbox-text">*Read the {NEWMARK_NAME} <a href={NM_PRIVACY.url} className="auth__main-checkbox-text-link custom-focus">{NM_PRIVACY.ttl}</a></p>
							</Col>
						</Row>

						{/* Actions: Submit; */}
						<Row>
							<Col className="px-4 mb-0 py-0 d-flex">
								<button
									className="btn btn-cx-primary w-100 auth__main-btn"
									onClick={onRegisterButtonClick}
								>
									{/* Show spinner on button; */}
									{loading ? (
										<span className="auth__main-spinner" title="Please wait..."></span>
									) : (
										<span>Complete Registration</span>
									)}
								</button>
							</Col>
						</Row>
					</Col>
				</Row>
			</Layout>
		</Container>
	);
}