import { useEffect } from "react";
import axios from "axios";
import {
  MatomoActions,
  MatomoEventCategories,
  setUserEmail,
  trackEvent,
} from "matomo";

import { createUserData, readUserData } from "components/api/userData";
import { Identity } from "dto/identity";
import IDENTITY_PROVIDERS from "enums/identityProviders";
import { useAppDispatch, useAppSelector } from "hooks";
import { decodeIdentityToken, getClaim } from "slices/user/identitySlice";
import { getTimezone } from "components/utils/datetime/datetime";

import {
  selectCodeChallenge,
  selectIsLoggedIn,
  selectAuthorizationCodeUrl,
} from "slices/user/identitySlice";

function AzureADAuthHandler() {
  const dispatch = useAppDispatch();
  const isLoggedIn = useAppSelector(selectIsLoggedIn);
  let codeChallenge = useAppSelector(selectCodeChallenge);
  const existingAuthorizationCodeUrl = useAppSelector(
    selectAuthorizationCodeUrl
  );
  useEffect(() => {
    const redirectURI = `${process.env.REACT_APP_MSAL_REDIRECT_URI}`;

    const buildScopes = function (identityScopes: string) {
      // Leave this alone..if you swap it for
      // return identityScopes.split(",").join(" ") it will break the login flow
      const scopes = identityScopes.split(",");
      let scopeString = "";
      scopes.forEach((scope: string) => {
        scopeString += ` ${scope}`;
      });
      return scopeString;
    };

    function buildNonce(): string {
      return `${Math.floor(Math.random() * 10_000)}`;
    }

    function generateRandomSequence(length: number) {
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      let sequence = "";

      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        sequence += characters.charAt(randomIndex);
      }

      return sequence;
    }
    if (!codeChallenge) {
      const newCodeChallenge = generateRandomSequence(43);
      dispatch({
        type: "identity/setCodeChallenge",
        payload: newCodeChallenge,
      });
      codeChallenge = newCodeChallenge;
    }
    const authorizationCodeUrl = `${
      process.env.REACT_APP_MSAL_AUTHORITY
    }/oauth2/v2.0/authorize?client_id=${process.env
      .REACT_APP_MSAL_CLIENT_ID!}&response_type=code id_token&scope=${buildScopes(
      process.env.REACT_APP_MSAL_IDENTITY_SCOPES!
    )}&code_challenge=${codeChallenge}&code_challenge_method=plain&prompt=select_account&nonce=${buildNonce()}&redirect_uri=${redirectURI}`;
    if (!existingAuthorizationCodeUrl) {
      dispatch({
        type: "identity/setAuthorizationCodeUrl",
        payload: authorizationCodeUrl,
      });
    }
    function parseHashParameters(url: string): Record<string, string> {
      // Remove any leading '~/' if present in the URL
      const cleanedUrl = url.replace(/^~\//, "");

      // Split the URL at the '#' character
      const parts = cleanedUrl.split("#");

      // Check if there's a hash part
      if (parts.length === 2) {
        const hash = parts[1];

        // Split the hash string into individual key-value pairs
        const keyValuePairs = hash.split("&");

        // Extract query parameters into an object
        const paramsObject: Record<string, string> = {};
        keyValuePairs.forEach((pair) => {
          const [key, value] = pair.split("=");
          paramsObject[key] = value;
        });

        return paramsObject;
      }

      return {};
    }

    // user has already logged in, and is being redirected back to the app
    const windowLocation = window.location;
    const containsCode = windowLocation.href.includes("code=");
    const containsIdentityToken = window.location.href.includes("&id_token=");

    const isRedirectFromAzureAd = containsCode && containsIdentityToken;

    if (isLoggedIn) {
      return;
    }
    if (isRedirectFromAzureAd) {
      const params = parseHashParameters(windowLocation.href);

      const accessTokenUrl = `${process.env.REACT_APP_MSAL_AUTHORITY}/oauth2/v2.0/token`;
      const formData = new URLSearchParams();
      formData.append("client_id", process.env.REACT_APP_MSAL_CLIENT_ID!);
      formData.append(
        "scope",
        buildScopes(process.env.REACT_APP_MSAL_IDENTITY_SCOPES!)
      );
      formData.append("code", params["code"]!);
      formData.append("grant_type", "authorization_code");
      // since we used 'plain' in the challenge_method above, we send the same codeChallenge we created initially to the token url
      // if this fails, we've already cleaned up redux, so the next reload of the page will work fine
      formData.append("code_verifier", codeChallenge!);

      formData.append("redirect_uri", redirectURI!);

      const config = {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
      };
      let identity: Identity;
      axios
        .post(accessTokenUrl, formData, config)
        .then((response) => {
          const identityTokenExp = getClaim(response.data.id_token, "exp");
          const accessTokenExp = getClaim(response.data.access_token, "exp");
          const exp = identityTokenExp < accessTokenExp ? identityTokenExp : accessTokenExp;

          identity = {
            identityProvider: IDENTITY_PROVIDERS.AZURE_AD,
            identityToken: response.data.id_token,
            accessToken: response.data.access_token,
            refreshToken: response.data.refresh_token,
            exp: parseInt(exp) * 1000,
            obtained: Date.now(),
          } as Identity;
          return readUserData(identity)
            .then((user) => {
              dispatch({
                type: "user/setUser",
                payload: user,
              });
              dispatch({
                type: "identity/setTokens",
                payload: identity,
              });
              dispatch({
                type: "identity/setAuthorizationCodeUrl",
                payload: null,
              });
              dispatch({
                type: "identity/setCodeChallenge",
                payload: null,
              });

              setUserEmail(user!.email);
              trackEvent(
                MatomoEventCategories.Login,
                MatomoActions.Login,
                getTimezone()
              );
              // navigate("/");
            })
            .catch(async (error) => {
              // Error on readUserData() - try creating a new user
              const decodedToken = decodeIdentityToken(identity);
              await createUserData(identity, {
                firstName: decodedToken.firstName,
                lastName: decodedToken.lastName,
                email: decodedToken.email,
                id: decodedToken.id,
              });
              const user = await readUserData(identity);
              if (!user) {
                // on any error, redirect back to login, and start the flow over again
                window.location.href = window.location.origin;
              }
              dispatch({
                type: "user/setUser",
                payload: user,
              });
              dispatch({
                type: "identity/setTokens",
                payload: identity,
              });
              dispatch({
                type: "identity/setAuthorizationCodeUrl",
                payload: null,
              });
              dispatch({
                type: "identity/setCodeChallenge",
                payload: null,
              });

              setUserEmail(user!.email);
              trackEvent(
                MatomoEventCategories.Login,
                MatomoActions.Login,
                getTimezone()
              );
            });
        })
        .catch(async (error) => {
          // Error from Azure AD // TODO
          console.error(error);

          // on any error, redirect back to login, and start the flow over again
          window.location.href = window.location.origin;
        });
    }
  }, [codeChallenge, isLoggedIn, dispatch, existingAuthorizationCodeUrl]);
  return <> </>;
}

export default AzureADAuthHandler;
