// Slice for user data
// Slices are combined actions and reducers
// more at https://react-redux.js.org/tutorials/typescript-quick-start#define-slice-state-and-action-types

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "store";
import FolderData from "components/shared/types/folderData";

const initialState: FolderData[] = [];

export const folderHierarchySlice = createSlice({
    name: "folderHierarchy",
    initialState,
    reducers: {
        load(state, action: PayloadAction<FolderData[]>) {
            while (state.length) {
                state.pop()
            }
            state.push(...action.payload)
        },
        clearDataRoomSelected(state) {
            while (state.length) {
                state.pop()
            }
        }
    },
});
export const { load, clearDataRoomSelected } = folderHierarchySlice.actions;

export const selectFolderHierarchy = (state: RootState): FolderData[] =>
    state.folderHierarchy;

export default folderHierarchySlice.reducer;
