import { useEffect, useState } from "react";
import ReactModal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faSpinner } from "@fortawesome/free-solid-svg-icons";

import "./modal.scss";

export default function Modal({
  isOpen,
  confirmButtonText,
  confirmButtonProps,
  onConfirm,
  onClose,
  hideCloseButton,
  contentLabel,
  className,
  overlayClassName,
  ariaDescription,
  title,
  children,
}: {
  isOpen: boolean;
  confirmButtonText?: string;
  confirmButtonProps?: any;
  onConfirm: () => void;
  onClose: () => void;
  hideCloseButton?: boolean;
  contentLabel: string;
  className?: string;
  overlayClassName?: string;
  ariaDescription: string;
  title: string;
  children?: React.ReactNode;
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    ReactModal.setAppElement("#root");
  });

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      contentLabel={contentLabel}
      aria={{ labelledby: contentLabel, describedby: ariaDescription }}
      className={`modal-parent ${className ? className : ""}`}
      overlayClassName={`modal-overlay ${
        overlayClassName ? overlayClassName : ""
      }`}
    >
      <FontAwesomeIcon
        icon={faClose}
        size="2x"
        className="modal-close"
        onClick={onClose}
      />
      <label className="modal-label">{title}</label>
      {children}
      <div className="modal-button-parent">
        {!hideCloseButton && (
          <>
            <button className="modal-button modal-cancel" onClick={onClose}>
              Cancel
            </button>
            <div className="modal-button-separator">
              {/* Separate the two buttons */}
            </div>
          </>
        )}
        {isSubmitting ? (
          <FontAwesomeIcon icon={faSpinner} size="3x" spin />
        ) : (
          <button
            {...confirmButtonProps}
            className="modal-button modal-confirm"
            onClick={async () => {
              setIsSubmitting(true);
              await onConfirm();
              setIsSubmitting(false);
              onClose();
            }}
          >
            {confirmButtonText || "Confirm"}
          </button>
        )}      
      </div>
    </ReactModal>
  );
}
