import axios from "axios";
import { logoutAndClearRedux } from "components/utils/redux/logout";
import { Identity } from "dto/identity";
import IDENTITY_PROVIDERS from "enums/identityProviders";
import { useAppDispatch, useAppSelector } from "hooks";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { getClaim, selectIdentity } from "slices/user/identitySlice";

function needToRefresh(identity: Identity): boolean {
  switch (identity.identityProvider) {
    case IDENTITY_PROVIDERS.AZURE_AD:
    case IDENTITY_PROVIDERS.CONNEXUS:
      return identity.exp < (Date.now() + 1000 * 60); // 1 minute buffer
    default:
      return false;
  }
}
function exchangeRefreshToken(
  identity: Identity,
  dispatch: any,
  navigate: any
): void {
  if (identity.identityProvider === IDENTITY_PROVIDERS.AZURE_AD) {
    const refreshTokenUrl = `${process.env.REACT_APP_MSAL_AUTHORITY}/oauth2/v2.0/token`;
    const formData = new URLSearchParams();
    formData.append("client_id", process.env.REACT_APP_MSAL_CLIENT_ID!);
    formData.append(
      "scope",
      process.env.REACT_APP_MSAL_IDENTITY_SCOPES!.split(",").join(" ")
    );
    formData.append("refresh_token", identity.refreshToken);
    formData.append("grant_type", "refresh_token");

    const config = {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    };
    axios
      .post(refreshTokenUrl, formData, config)
      .then((response) => {
        const identityTokenExp = getClaim(response.data.id_token, "exp");
        const accessTokenExp = getClaim(response.data.access_token, "exp");
        const exp = identityTokenExp < accessTokenExp ? identityTokenExp : accessTokenExp;

        dispatch({
          type: "identity/setTokens",
          payload: {
            identityProvider: identity.identityProvider,
            identityToken: response.data.id_token,
            accessToken: response.data.access_token,
            refreshToken: response.data.refresh_token,
            exp: parseInt(exp) * 1000,
            obtained: Date.now(),
          },
        });
      })
      .catch((e) => {
        if (
          e.response.data.error_description?.includes(
            "new sign in request must be sent"
          )
        ) {
          logoutAndClearRedux(dispatch);
          navigate("/login");
        } else {
          console.error(e);
        }
      });
  } else {
    // identity provider
    const url = `${process.env.REACT_APP_IDENTITY_PROVIDER_APIM_GATEWAY_URL}/${process.env.REACT_APP_IDENTITY_PROVIDER_EXCHANGE_REFRESH_TOKEN}`;
    const headers = {
      "Content-Type": "application/json",
      refreshToken: identity.refreshToken,
    };
    axios
      .post(url, {}, { headers })
      .then(function (response) {
        const { headers } = response;
        const accessToken = headers.accessToken || headers.accesstoken;
        const identityToken = headers.identityToken || headers.identitytoken;
        const refreshToken = headers.refreshToken || headers.refreshtoken;

        const decodedAccessToken = JSON.parse(atob(accessToken!.split(".")[1]));

        dispatch({
          type: "identity/setTokens",
          payload: {
            identityProvider: identity.identityProvider,
            identityToken: identityToken,
            accessToken: accessToken,
            refreshToken: refreshToken,
            exp: decodedAccessToken.exp * 1000,
            obtained: Date.now(),
          } as Identity,
        });
      })
      .catch((e) => {
        if (e.response.status >= 400) {
          logoutAndClearRedux(dispatch);
          navigate("/login");
        } else {
          console.error(e);
        }
      });
  }
}

function exchangeRefreshTokenWrapper(
  identity: Identity,
  dispatch: any,
  navigate: any
) {
  const decodedAccessToken = JSON.parse(
    atob(identity.accessToken!.split(".")[1])
  );
  if (needToRefresh(identity) || decodedAccessToken.exp < Date.now() / 1000) {
    exchangeRefreshToken(identity, dispatch, navigate);
  }
}

function Hydrator() {
  const navigate = useNavigate();
  const identity = useAppSelector(selectIdentity);
  const dispatch = useAppDispatch();
  useEffect(() => {
    exchangeRefreshTokenWrapper(identity, dispatch, navigate);
    const intervalId = setInterval(() => {
      exchangeRefreshTokenWrapper(identity, dispatch, navigate);
    }, parseInt(process.env.REACT_APP_REFRESH_INTERVAL!));
    return () => clearInterval(intervalId);
  }, [identity, identity.exp, identity.obtained, dispatch, navigate]);
  return <> </>;
}

export default Hydrator;
